import Swal from "sweetalert2";
import { Redirect as BrowserRouter } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Configuration from "../../../settings/Configurations";
import Context from "../../../../store/context";
import Crypto from "../../../util/Crypto";
import RegistrarIntentoLogin from "../../../util/RegistrarIntentoLogin";
import ValidarData from "../../components/ValidarData";

export default function FormTVI() {
	const { state, actions } = useContext(Context);
	const [redirect, setRedirect] = useState(false);
	const [dataJson, setDataJson] = useState(null);

	useEffect(async () => {
		await SolicitarTicket(Crypto.Decrypt("data"));
	}, []);

	const ErrorMessage = (text) => {
		Swal.fire({
			icon: "error",
			html: text,
			showDenyButton: false,
			showCancelButton: false,
			confirmButtonText: "Ok",
			denyButtonText: `Don't save`
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.replace("/");
			}
		});
	}

	const SolicitarTicket = async (data) => {
		OnLoading(true);

		let raw = {
			afiliado: {
				nombre: data.name,
				rut_numero: data.run.substring(0, data.run.length - 1),
				dv: data.run.substring(data.run.length - 1),
				verificacion: {
					identificacion_proveedor: "VI08",
					url_verificacion:
						data.ObjetoGenerico.DatosPreviredTraspaso.UrlValidacionPrevired,
					tipo_verificacion: "1005",
					codigo_verificacion: data.tokentvi,
					url_usuario:
						data.ObjetoGenerico.DatosPreviredTraspaso.UsuarioValidacionPrevired,
					url_clave:
						data.ObjetoGenerico.DatosPreviredTraspaso.ClaveValidacionPrevired,
				},
			},
			codigo_institucion: "1034",
			requiere_segunda_verificacion: false,
		};

		await fetch(`${Configuration.API_URL}/api/Previred/Obtener/Ticket`, {
			method: "POST",
			headers: Configuration.JSON_HEADER,
			body: JSON.stringify(raw),
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.respuesta.codigo === 200) {
					await SolicitarToken(result.respuesta.ticket_tvi, raw, data);
				} else {
					OnLoading(false);
					ErrorMessage(result.respuesta.mensaje)
				}
			})
			.catch((error) => {
				OnLoading(false);
				ErrorMessage("Hemos detectado un problema. Por favor, intenta nuevamente más tarde." + error)
			});
	};

	const SolicitarToken = async (ticket, raw, data) => {
		await fetch(`${Configuration.API_URL}/api/Previred/Obtener/Token`, {
			method: "POST",
			headers: Configuration.JSON_HEADER,
			body: JSON.stringify({
				rut: data.run,
				codigo_institucion: "1034",
				ticket_tvi: ticket,
			}),
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.respuesta.codigo === 200) {
					await ObtieneCuentas(result.respuesta.token, raw, data.autenticacion);
				} else {
					var message = result.respuesta.mensaje;

					if (result.respuesta.errores !== null) {
						result.respuesta.errores.forEach((element) => {
							message = `<b>${element.error.codigo_error}</b> ${element.error.mensaje}<br>`;
						});
					}
					OnLoading(false);
					ErrorMessage(message);
				}
			})
			.catch((error) => {
				OnLoading(false);
				ErrorMessage("Hemos detectado un problema. Por favor, intenta nuevamente más tarde." + error)
			});
	};

	const ObtieneCuentas = async (token, data, autenticacion) => {
		RegistrarIntentoLogin(1, true);

		let raw = {
			token: token,
			codigo: "1034",
			rut: data.afiliado.rut_numero,
			rutDv: data.afiliado.dv,
			nombres: data.afiliado.nombre,
			apellidoPaterno: data.afiliado.apellido_paterno,
			apellidoMaterno: data.afiliado.apellido_materno,
			cuentas: [
				{ codigo: "CCICO" },
				{ codigo: "CCIAV" },
				{ codigo: "CAI" },
				{ codigo: "CAV" },
				{ codigo: "CCICV" },
				{ codigo: "CCIDC" },
				{ codigo: "APVC" },
			],
			otros: "",
			autenticacion: autenticacion
		};

		var body = JSON.stringify(raw);
		sessionStorage.setItem("jsonFondo", body);

		await fetch(`${Configuration.API_URL}/api/Previred`, {
			method: "POST",
			headers: Configuration.JSON_HEADER,
			body: body,
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.code === "0") {
					if (result.response.cuentas.length === 0) {
						OnLoading(false);
						ErrorMessage("Trabajador no registra cuentas activas");
					} else {
						result.response.token = raw.token;

						var poseeVoluntarias = false;

						result.response.cuentas.forEach((item, index) => {
							if (item.tipo === "CCIV" || item.tipo === "CCIDC" || item.tipo === "CAV" || item.tipo === "APVC") {
								poseeVoluntarias = true;
							}

							item.fondos.forEach((fondo, i) => {
								fondo.origen = fondo.nombre;
							});
						});

						/*if (poseeVoluntarias) {
							

							Swal.fire({
								icon: "info",
								html: `No es posible continuar con tu traspaso con ClaveÚnica, dado que posees productos voluntarios.<br><br>
								Te invitamos a continuar tu proceso autenticándote con tu <strong>clave de seguridad</strong>.`,
								confirmButtonText: "Volver",
								showDenyButton: false,
								denyButtonText: "No",
								allowOutsideClick: false,
								returnFocus: false,
							}).then((result) => {
								if (result.isConfirmed) {
									window.location.replace("/autenticacion");
								}
							});
						}
						else {*/
						setDataJson(result);
						setRedirect(true);
						OnLoading(false);
						//}
					}
				} else {
					OnLoading(false);
					ErrorMessage(result.message);
				}
			})
			.catch((error) => {
				OnLoading(false);
				ErrorMessage("Hemos detectado un problema. Por favor, intenta nuevamente más tarde. " + error)
			});
	};

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	return (
		<div>
			<ValidarData />
			{redirect ? (
				<BrowserRouter
					to={{ pathname: "/cuentasyfondo", state: { json: dataJson } }}
				/>
			) : null}
		</div>
	);
}
