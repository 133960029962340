import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import validator from "validator";

export default function Phone(props) {
	const [phone, setPhone] = useState("");
	const [errorM, setErrorM] = useState({ show: false, message: "" });

	const phoneChange = (event) => {
		event.preventDefault();
		if (event.target.value.length < 10) {
			if (validator.isNumeric(event.target.value, [{ no_symbols: false }])) {
				setPhone(event.target.value);
			} else if (event.target.value === "") {
				setPhone(event.target.value);
			}
		}
	};

	const phoneBlur = (event) => {
		event.preventDefault();
		if (event.target.value.length < 9) {
			setPhone("");
			props.setValidateForm({ ...props.validateForm, phone: true });
			setErrorM({ show: true, message: "Teléfono requiere 9 caracteres." });
		} else {
			setErrorM({ show: false, message: "" });

			props.setValidateForm({ ...props.validateForm, phone: false });
		}
	};

	return (
		<>
			<Form.Group>
				<label htmlFor="celular">
					<strong>Celular</strong>
				</label>
				<InputGroup>
					<InputGroup.Prepend>
						<InputGroup.Text className="preappend-form">
							<strong>+56</strong>
						</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control
						value={phone}
						maxLength={9}
						className={errorM.show ? "is-invalid" : ""}
						id="celular"
						placeholder="123456789"
						name="phone"
						type="text"
						onChange={phoneChange}
						onBlur={phoneBlur}
					/>
				</InputGroup>
				{errorM.show ? (
					<div style={{ padding: "5px" }} className="alert alert-danger">
						<span
							className="glyphicon glyphicon-exclamation-sign"
							aria-hidden="true"
						></span>
						<span className="sr-only">Error:</span>
						<span className="text-danger">{errorM.message}</span>
					</div>
				) : null}
			</Form.Group>
		</>
	);
}
