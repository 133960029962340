class Campo {
    constructor(nombre, etiqueta, valor) {
        this.nombre = nombre;
        this.etiqueta = etiqueta;
        this.valor = valor;
    }

    establecerValor = (valor) => this.valor = valor;
}

export default Campo;