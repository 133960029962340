import { useState } from "react";
import { Form } from "react-bootstrap";
import validator from "validator";

export default function Email(props) {
	const [mailValue, setMailValue] = useState("");
	const [errorM, setErrorM] = useState({ show: false, message: "" });

	const mailChange = (event) => {
		event.preventDefault();
		setMailValue(event.target.value);
	};

	const mailBlur = (event) => {
		event.preventDefault();

		if (validator.isEmail(event.target.value)) {
			setErrorM({ show: false, message: "" });
			setMailValue(event.target.value);
			props.setValidateForm({ ...props.validateForm, email: false });
		} else {
			setMailValue("");
			props.setValidateForm({ ...props.validateForm, email: true });
			setErrorM({
				show: true,
				message: "La dirección de correo tiene un formato incorrecto.",
			});
		}
	};

	return (
		<>
			<Form.Control
				value={mailValue}
				className={errorM.show ? "is-invalid" : ""}
				type="text"
				placeholder="correo@correo.cl"
				name="email"
				onBlur={mailBlur}
				onChange={mailChange}
			/>
			{errorM.show ? (
				<div style={{ padding: "5px" }} className="alert alert-danger">
					<span
						className="glyphicon glyphicon-exclamation-sign"
						aria-hidden="true"
					></span>
					<span className="sr-only">Error:</span>
					<span className="text-danger">{errorM.message}</span>
				</div>
			) : null}
		</>
	);
}
