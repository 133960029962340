import React, { useState, useEffect } from 'react';
import Cuenta from './Cuenta'

const Cuentas = ({ cuentas }) => {
    const [ctas, guardarCuentas] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            guardarCuentas(cuentas);
            setLoaded(true);
        }
    },
        []
    );

    if (loaded) {

        return (
            <div className="">
                <div className="" style={{ position: 'relative', float: 'left', width: '100%' }}>
                    {ctas.map(cuenta => (<Cuenta key={cuenta.tipo} cuenta={cuenta} />))}
                </div>
            </div>
        );
    } else
        return null
}

export default Cuentas;
