import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../util/img/genericas/close-circle.svg";

const PageError = () => {
	const numero = {
		color: "#7479D7",
	};

	return (
		<div className="container mt-2">
			<Card
				style={{
					textAlign: "center",
					padding: "30px",
					width: "760px",
					margin: "50px auto",
				}}
			>
				<img
					src={logo}
					style={{
						marginTop: "40px",
						margin: "auto",
						marginBottom: "20px",
					}}
				/>
				<p>
					<strong>¡Error! Inténtalo más tarde</strong>
				</p>
				<br />
				<p>
					Tuvimos un problema en nuestro sistema, no pudimos completar tu
					solicitud.
				</p>
				<br />
				<p>
					<p>
						<strong>
							Si tienes alguna duda puedes contactarnos a través de nuestros
							canales de atención, escribiendo en nuestras redes sociales
							Facebook o Twitter o llamando al Contact center desde teléfonos
							fijos al <strong style={numero}>600 828 7200</strong> y desde
							teléfonos móviles al <strong style={numero}>2 2828 7200</strong>
						</strong>
					</p>
				</p>
				<Link to="/">
					<Button id="B-TW-0010">Volver al inicio</Button>
				</Link>
			</Card>
		</div>
	);
};

export default PageError;
