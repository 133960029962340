
class Crypto {
	static Encrypt(name, value) {
		
		var j = JSON.stringify(value);		
		sessionStorage.setItem(name, j);
	}

	static Decrypt(name) {
		var sess = sessionStorage.getItem(name);		

		var par = JSON.parse(sess);

		return par;
	}
}
export default Crypto;
