import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import './cuestionario.css'

export default function PreguntasForm(props) {
    const [nextAsk, setNextAsk] = useState(props.nextAsk);
    const [arrayAsk, setArrayAsk] = useState(props.arrayAsk);

    useEffect(() => {
        setNextAsk(props.nextAsk);
        setArrayAsk(props.arrayAsk);
    }, [props])

    return (
        <div>
            <h5 className="mt-36">Pregunta {nextAsk} de 5</h5>

            <div className="progress  mt-1">
                <div className="progress-bar" role="progressbar" style={{ width: (nextAsk * 20) + "%" }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <h5 className="mt-37" style={{ marginTop: '20px', marginBottom: '20px' }}>{arrayAsk.detalle.desafio[nextAsk - 1].pregunta}</h5>
            <div className="content_preguntas"> {arrayAsk.detalle.desafio[nextAsk - 1].alternativas.map((alternativas, index) => (
                <div key={`default-radio-${index}-${nextAsk - 1}`} className="mb-3">

                    <Form.Check
                        required
                        type="radio"
                        value={alternativas.codigO_RESPUESTA.trim()}
                        name={`radio_${nextAsk - 1}`}
                        id={alternativas.codigO_RESPUESTA}
                        label={alternativas.respuesta}
                    />
                </div>
            ))}
            </div>
        </div>
    );
}