import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import RegistrarIntentoLogin from "../../util/RegistrarIntentoLogin";
import configuration from "../../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../../util/Crypto";

const padTime = (time) => (String(time).length === 1 ? `0${time}` : `${time}`);

const format = (time) => {
	const minutes = Math.floor(time / 60);
	const seconds = time % 60;
	return `${minutes}:${padTime(seconds)}`;
};

export default function Timer(props) {
	const [paused, setPaused] = useState(props.paused);
	const [errors, setErrors] = useState(null);
	const [counter, setCounter] = useState(
		parseInt(props.countdown.Response.TiempoRestante / 1000)
	);
	const [timeOut, setTimeOut] = useState(false);
	const [accion, setAccion] = useState(0);

	useEffect(async () => {
		var data = Crypto.Decrypt("data");

		let errorTimer = errors;
		let timer;

		if (counter > 0) {
			timer = setTimeout(
				() =>
					setCounter((c) => {
						if (!paused) return c - 1;
					}),
				1000
			);
		} else if (counter === 0) {
			timer = setTimeout(() => setCounter((c) => c - 1), 1000);

			RegistrarIntentoLogin(2, false, setErrors);
			setTimeOut(true);

			let raw = {
				Id: props.countdown.Response.Id,
				Token: data.solicitud,
				Operacion: 2,
			};
			await fetch(`${configuration.API_URL}/api/Timer`, {
				method: "PUT",
				headers: configuration.JSON_HEADER,
				body: JSON.stringify(raw),
			})
				.then((response) => response.json())
				.then((response) => {
					const leAction = props.countdown.Response.Intento === 1 ? 1 : 2;
					setAccion(leAction);
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: error.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});

					errorTimer = {
						error: "No se pudo actualizar el timer",
						detalle: error.message,
					};
					setErrors(errorTimer);
					setAccion(3);
				});
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [counter, timeOut, accion]);

	const acciones = [
		<div className="tag">
			<button type="button" className="btn btn-outline-dark">
				Tiempo restante: {format(counter)}
			</button>
		</div>,
		<Redirect
			to={{
				pathname: "/seguridadCuestionario",
				state: {
					data: {
						title: "¡Ups!, Se acabó el tiempo de respuesta",
						cardText:
							"Ya pasaron los dos minutos establecidos y cerramos tu sesión por tu seguridad. Puedes reintentar el traspaso nuevamente.",
						buttonTryAgain: true,
						buttonPassSec: false,
					},
				},
			}}
		/>,
		<Redirect
			to={{
				pathname: "/seguridadCuestionario",
				state: {
					data: {
						title: "¡Ups!, Se acabó el tiempo de respuesta",
						cardText:
							"Hay dos intentos fallidos. Por tu seguridad, este método de validación se bloqueó por 72 horas. Podrás intentar nuevamente después del plazo señalado o utilizar la opción de clave de seguridad.",
						buttonTryAgain: false,
						buttonPassSec: true,
					},
				},
			}}
		/>,
		<Redirect to={{ pathname: "/error", state: { data: errors } }} />,
	];

	return <div>{acciones[accion]}</div>;
}
