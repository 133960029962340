import './autenticacionui.css';
import React from 'react';
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { useState, useRef } from 'react';

function PreguntasTooltip() {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <>
            <img src="./imgs/pregunta.svg" alt="preguntas" ref={target} onClick={() => setShow(!show)} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} />
            <Overlay show={show} placement="top" containerPadding={20} target={target}>
                <Popover className="modelo-popover">
                    <Popover.Content className="tooltip-content">
                        <div>
                            <p className="color_w">Número de Documento</p>
                            <img className="img-fluid" src="./imgs/cedula_st.png" alt="Numero de Documento/Serie de Cédula de Identidad" />
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    );
}

export default PreguntasTooltip;