import React, { useState, useEffect } from "react";

const DatosDeContacto = ({ datos }) => {
    const [contacto, guardarContacto] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            guardarContacto(datos);
            setLoaded(true);
        }
    }, []);

    if (loaded) {
        return (
            <div className="container">
                <div className="" style={{ position: 'relative', float: 'left', padding: '0px', marginTop: '20px', width: '100%', }}>
                    <p className="pp_">Email </p>
                    <p className="pr_">{contacto.Email}</p>
                    <p className="pp_">Teléfono </p>
                    <p className="pr_">{contacto.Telefono}</p>
                    <p className="pp_"> Dirección Particular: </p>
                    <p className="pr_">{contacto.Direccion}</p>
                    <p className="pp_">Región</p>
                    <p className="pr_">{contacto.nombreRegion}</p>
                    <p className="pp_">Comuna </p>
                    <p className="pr_">{contacto.nombreComuna}</p>
                </div>
            </div>
        );
    } else
        return null;
}

export default DatosDeContacto;
