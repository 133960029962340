import React from 'react';
import Card from 'react-bootstrap/Card';
import IconoFondo from './IconoFondo';

export default function TarjetaCuenta({ cuenta }) {
    return (
        <Card className="border-success text-left mt-3">
            <Card.Body>
                <Card.Title>{cuenta.tipoDescripcion}</Card.Title>
                <Card.Subtitle className="mb-2">AFP de origen: {cuenta.origen}</Card.Subtitle>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-8 align-middle pl-0 pt-2">
                            <Card.Text>Saldo: ${cuenta.saldo}</Card.Text>
                        </div>
                        <div className="col-4 align-middle">
                            {cuenta.fondos.map((f, i) => <IconoFondo key={i} nombre={f.nombre} estado={1} />)}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}