
import React from 'react';
import { Button } from 'react-bootstrap';
import BotonesSegundaParte from './BotonesSegundaParte';

export default function Botones(props) {

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <Button variant="primary" onClick={() => window.location.replace('https://www.afpmodelo.cl/AFP/Acceso-mi-cuenta/Acceso-a-mi-Cuenta.aspx')} block>¿Quieres ir a tu sitio privado?</Button>
                </div>
            </div>
            <BotonesSegundaParte showO={props.showO} />
        </div>
    );
}