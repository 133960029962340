import React, { useState, useEffect } from 'react';
import ValidateFieldsUtil from '../../../util/validacion/ValidateFieldsUtil';

const DatosPersonales = ({ datos }) => {

    const [datosPersonales, setDatosPersonales] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const formatDecimal = (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const formatRut = (rut) => {
        const rutParts = rut.split("-");
        return `${formatDecimal(rutParts[0])}-${rutParts[1]}`;
    };
    const formatFecha = (f) => `${f.substring(6, 8)}-${f.substring(4, 6)}-${f.substring(0, 4)}`;

    useEffect(() => {
        if (!loaded) {
            setDatosPersonales(datos);
            setLoaded(true);
        }
    }, []);

    const formatNacionalidad = (cadena) => {
        const primerCaracter = ValidateFieldsUtil.Upper(cadena.charAt(0));
        const restoDeLaCadena = cadena.substring(1, cadena.length);
        return primerCaracter.concat(restoDeLaCadena);
    }

    if (loaded) {
        return (
            <>
                <div className="">
                    <div className="" style={{ position: 'relative', float: 'left', padding: '10px', marginTop: '10px', width: '100%', }}>
                        <p className="pp_">Nombres </p>
                        <p className="pr_"> {`${datosPersonales.Nombre} ${datosPersonales.apellidoPaterno} ${datosPersonales.apellidoMaterno}`} </p>
                        <p className="pp_">Rut </p>
                        <p className="pr_"> {formatRut(datosPersonales.Rut)} </p>
                        <p className="pp_">Fecha de nacimiento </p>
                        <p className="pr_"> {formatFecha(datosPersonales.FechaNacimiento)} </p>
                        <p className="pp_">Nacionalidad</p>
                        <p className="pr_"> {formatNacionalidad(datosPersonales.nombreNacionalidad).replace(/\./g, "")} </p>
                    </div>
                </div>
            </>
        );
    } else
        return null;
}

export default DatosPersonales;