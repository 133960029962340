import React, { useState, useEffect } from 'react';

const Disclaimer = () => {
    const [disclaimers, setDisclaimers] = useState([]);

    return (
        <>
            <div className="media-body text-muted mx-auto">
                Declaro haberme informado sobre la calidad de servicio de las AFP en la herramienta <a href="https://www.spensiones.cl/infoydec" style={{ color: "#6D6B6B", textDecoration: "underline" }}>lnfórmate y Decide.</a>
                <br />
                <br />
                Declaro conocer la <a href="https://www.afpmodelo.cl/AFP/Cambiate-a-modelo/Tabla_Rentabilidad.aspx" style={{ color: "#6D6B6B", textDecoration: "underline" }}>rentabilidad real y comisiones de los últimos 36 meses de todas la AFP.</a>
            </div>
        </>
    );
}

export default Disclaimer;