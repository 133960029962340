import configuration from "../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../util/Crypto";

const RegistrarPaso = async (idfase) => {
	var data = Crypto.Decrypt("data");

	if (
		data.solicitud !== null &&
		data.solicitud !== "" &&
		data.solicitud !== 0 &&
		data.solicitud !== undefined
	) {
		await fetch(`${configuration.API_URL}/api/Traspaso/${data.solicitud}`, {
			method: "GET",
			headers: configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response !== null) {
					if (response.Code === 0) {
						let raw = {
							IdFase: idfase,
							IdProspecto: response.Response.Id,
							Fecha: new Date(),
						};

						RegistrarPasoPost(raw);
					} else {
						data.solicitud = "";
						Crypto.Encrypt("data", data);

						Swal.fire({
							icon: "error",
							text: `No se pudo cargar la sesión ${response.Code}`,
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});
					}
				} else {
					Swal.fire({
						icon: "error",
						text: "No se pudo cargar la sesión Sesión Nula",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			})
			.catch((error) => {
				console.error(error);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});
	}
};
const RegistrarPasoPost = async (raw) => {
	await fetch(`${configuration.API_URL}/api/Fase`, {
		method: "POST",
		headers: configuration.JSON_HEADER,
		body: JSON.stringify(raw),
	}).catch((error) => {
		console.error(error);
		Swal.fire({
			icon: "error",
			text: error.message,
			confirmButtonText: "Ok",
			allowOutsideClick: false,
		});
	});
};

export default RegistrarPaso;
