import { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ConfirmarAsesor = (props) => {
    const [datosAsesor] = useState(props.location.state.datosAsesor);

    return (
        <>
            <div className="container" style={{ marginTop: '20px' }}>
                <div className="row">
                    <div className="contenedor_img22">
                        <img className="nina_error" src="./imgs/personaje-traspaso1.svg" alt="nina modelo" />
                    </div>
                </div>
                <div className="media_grey3">
                    <div className="media-body">
                        <Card className=" body_grey">
                            <Card.Body>
                                <Card.Title>{datosAsesor.nombre} ¡Ya tenemos tus datos!</Card.Title>
                                <div className="container mt-4">
                                    <div className="row">
                                        <div className="col-12 pl-0 pt-2">
                                            <Card.Text>
                                                Un asesor virtual te contactará a la brevedad para que puedas hacer el cambio a <a style={{ color: "#80BC00" }}> AFP Modelo </a>
                                            </Card.Text>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-12 align-middle">
                                            <Link to="/" className="pointer" style={{ textDecoration: "underline", color: "#4297DE" }}>Volver al inicio</Link>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmarAsesor;