import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import '../traspasoui.css'

export default function RecienTrabajoModal(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose} animation={false} className="text-center" backdrop="static">
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="modal-title">Recién empecé a tabajar</div>
                        <div className="modal-body_">
                            Si tu primer trabajo comenzó después del 01 de octubre del 2019, Estás afiliado en AFP Uno.
                            Por ende No puedes hacer un cambio hasta cumplir 2 años en tu primera AFP.
                        </div>
                        <div className="btn_card_">
                            <Button id="B-MOD-0001" variant="primary" onClick={props.handleClose} block>
                                Entiendo
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
