import { Card, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
require('../autenticacionui/autenticacionui.css')

export default function NoHayCuentas() {

    return (
        <div className="container">
            <div className="center-box">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <img className="img_cono" src="./imgs/icono_error404.svg" alt="o_no" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <Card className="box_mensaje mb-3 rounded-bottom border-bottom bg-white">

                            <Card.Body className="bg-white pl-5 pr-5 pt-1 pb-2">
                                <h3 className="d-flex justify-content-center mt-3">¡Aviso!</h3>
                                <Card.Text>No presentas <strong>cuentas </strong> para traspaso</Card.Text>
                                <Link to="/"><Button id="B-MOD-0003" variant="primary" className="mt-5">Intentar otra vez</Button></Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}