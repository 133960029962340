export default function Errors({ errors }) {

    const renderErrors = () => {
        if (errors.length > 0) {
            let items = errors.map((element, index) =>
                <div key={index} style={{ padding: '5px' }} className="alert alert-danger">
                    <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                    <span className="sr-only">Error:</span>
                    <span className="text-danger">{element}</span>
                </div>
            );

            return items;
        } else
            return null;
    };

    if (!(errors))
        errors = [];

    const errmsg = renderErrors();

    return (<>{errmsg}</>);
}