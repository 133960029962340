import React, { useEffect, useState } from 'react';
import '../datosPersonales.css'

const DatosPersonalesA = ({ datos }) => {
    const [fecha, setFecha] = useState('');
    const [preRender, setPreRender] = useState(false);

    useEffect(() => {
        setDate(datos.FechaNacimiento || '');
        setPreRender(true);
    }, [])

    const setDate = (date) => {
        let anio = date.substring(0, 4);
        let mes = date.substring(6, 4);
        let dia = date.substring(8, 6);
        setFecha(dia + '/' + mes + '/' + anio)
    }


    const formatRut = (rut) => {
        const newRut = rut.replace(/\./g, '').replace(/\-/g, '').trim().toLowerCase();
        const lastDigit = newRut.substr(-1, 1);
        const rutDigit = newRut.substr(0, newRut.length - 1)

        if (rutDigit.length === 7) {
            let anio = rutDigit.substring(0, 1);
            let mes = rutDigit.substring(1, 4);
            let dia = rutDigit.substring(4, 7);

            return anio + '.' + mes + '.' + dia + '-' + lastDigit
        } else {
            let format = '';
            for (let i = rutDigit.length; i > 0; i--) {
                const e = rutDigit.charAt(i - 1);
                format = e.concat(format);
                if (i % 3 === 0) {
                    format = '.'.concat(format);
                }
            }
            return format.concat('-').concat(lastDigit);
        }
    }

    if (preRender) {
        return (
            <>
                <div>
                    <div className="media_grey__">
                        <h5 className="mt-37_"><strong>Datos personales</strong></h5>
                        <p className="titulos_c">Nombre</p>
                        <p className="textos_c">{datos.Nombre} {datos.apellidoPaterno} {datos.apellidoMaterno}</p>
                        <p className="titulos_c">Rut</p>
                        <p className="textos_c">{formatRut(datos.Rut)}</p>
                        <p className="titulos_c">Fecha de nacimiento</p>
                        <p className="textos_c">{fecha}</p>                        
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}

export default DatosPersonalesA