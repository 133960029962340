import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import configuration from "../../settings/Configurations";
import Crypto from "../../util/Crypto";
import like from "../../util/img/binvenida/frame.svg";
import corazon from "../../util/img/binvenida/corazon.svg";
import ValidarData from "../components/ValidarData";

const Bienvenida = (props) => {
	useEffect(async () => {
		var data = Crypto.Decrypt("data");

		await fetch(`${configuration.API_URL}/api/log/final/${data.run}/${data.autenticacion}`, {
			method: "POST",
			headers: configuration.JSON_HEADER,
		}).catch((error) => {
			console.error(error);
		});

	}, []);

	const onclickComprobante = (e) => {
		e.preventDefault();

		const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
			const byteCharacters = atob(b64Data);
			const byteArrays = [];

			for (
				let offset = 0;
				offset < byteCharacters.length;
				offset += sliceSize
			) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const blob = new Blob(byteArrays, { type: contentType });
			return blob;
		};

		const blob = b64toBlob(
			props.location.state.data.response.data,
			"data:application/pdf"
		);
		const blobUrl = URL.createObjectURL(blob);
		let a = document.createElement("a");
		a.style = "display: none";
		document.body.appendChild(a);
		a.href = blobUrl;
		a.download = "comprobante.pdf";
		a.click();
		window.URL.revokeObjectURL(blobUrl);
	};

	return (
		<>
			<ValidarData />
			<div className="bg_bg">
				<div className="img_final">
					<img src={like} alt="bienvenida" />
				</div>
				<div className="container_final" style={{ marginTop: "15px" }}>
					<Card className="media_white">
						<h2>¡Bienvenid@!</h2>
						<div className="corazon_final">
							<img src={corazon} alt="corazon" />
						</div>
						<p className="mt-2">
							Tu cambio a
							<font>
								<strong> AFP Modelo</strong>
							</font>{" "}
							está en curso.
							<br /> Te enviamos el detalle de esta solicitud a tu correo, con
							información importante.
						</p>
						<p className="mt-2">
							<font style={{ color: "#0B7AC7" }}>
								<strong>¿Cuánto demora el cambio?</strong>
							</font>
						</p>
						<p className="mt-2">
							El cambio de AFP no es inmediato.
							<font style={{ color: "#0B7AC7" }}>
								<strong>
									{" "}
									Se completará la segunda semana del mes siguiente.
								</strong>
							</font>{" "}
							Estos plazos están normados por la Superintendencia de Pensiones.
							Después de esta fecha, podrás solicitar tu Clave Web para que
							utilices por completo las plataformas digitales de
							<font>
								<strong> AFP Modelo</strong>
							</font>
							.
						</p>
						<p>
							<font style={{ color: "#0B7AC7" }}>
								<strong>
									Recuerda que si necesitas tu certificado de cotizaciones,
									deberás solicitarlo en tu antigua AFP.
								</strong>
							</font>
						</p>
						<div className="big_buttom mt-2">
							<Button
								id="B-TW-0011"
								className="btn-primary"
								onClick={onclickComprobante}
							>
								Descargar el Comprobante
							</Button>
							<p className="mt-2">Si tienes dudas, contáctanos</p>
						</div>
						<div className="redes_ mt-2 mb-2">
							<a
								href="https://www.facebook.com/afpmodelo"
								target="_blank"
								rel="noreferrer"
							>
								<img src={`./imgs/fb_.svg`} alt="facebook" />
							</a>
							<a
								href="https://twitter.com/AFPModelo"
								target="_blank"
								rel="noreferrer"
							>
								<img src={`./imgs/tw_.svg`} alt="twitter" />
							</a>
							<a
								href="https://www.instagram.com/afpmodelocl"
								target="_blank"
								rel="noreferrer"
							>
								<img src={`./imgs/ig_.svg`} alt="instagram" />
							</a>
						</div>
					</Card>
					<div className="space__"></div>
				</div>
			</div>
		</>
	);
};

export default Bienvenida;
