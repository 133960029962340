import { useContext, useEffect, useState, useRef } from "react";
import { Button, Form, Col, InputGroup, Alert } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import validator from "validator";
import "./datosPersonales.css";
import DatosPersonalesA from "./datosPersonales/DatosPersonales";
import Context from "../../../store/context";
import CabeceraPasos from "../components/CabeceraPasos";
import RegistrarPaso from "../../util/RegistrarPaso";
import configuration from "../../settings/Configurations";
import Swal from "sweetalert2";
import ValidateFieldsUtil from "../../util/validacion/ValidateFieldsUtil";
import IdleTimerContainer from "../../util/IdleTimer/Container";
import Crypto from "../../util/Crypto";
import sendRequest from "../../util/call/sendRequest";
import ValidarData from "../components/ValidarData";

const DatosPersonales = (props) => {

	const { state, actions } = useContext(Context);
	const ContactSection = useRef(null);

	const [redirect, setRedirect] = useState(false);

	const [validarExisteEmpleador, setValidarExiteEmpleador] = useState(false);
	const [estadoEmpleador, setEstadoEmpleador] = useState(1);

	const [confirmData, setConfirmData] = useState({ ...props.location.state.json });
	const [datosPersonalesInit] = useState({ ...props.location.state.json.response.datosPersonales });
	const [pasoRegistrado, setPasoRegistrado] = useState(false);
	const [jsonBack, setJsonBack] = useState({});

	useEffect(async () => {
		OnLoading(true);

		if (!pasoRegistrado) {
			RegistrarPaso(4);
			setPasoRegistrado(true);
		}

		await fetch(`${configuration.API_URL}/api/nacionalidad`, {
			method: "GET",
			headers: configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then((response) => {
				setNacionalidades(response.response);
			})
			.catch((error) => {
				console.error(error);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});

		await fetch(`${configuration.API_URL}/api/tipo`, {
			method: "GET",
			headers: configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then((response) => {
				setTipoEmpleado(response.response);
			})
			.catch((error) => {
				console.error(error);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});

		let data = { ...props.location.state.json.response.datosPersonales };
		onChangeAfiliado(data.TipoEmpleado);

		OnLoading(false);
	}, []);

	const [nacionalidades, setNacionalidades] = useState([]);
	const [tipoEmpleado, setTipoEmpleado] = useState([]);

	const [afiliado] = useState(props.location.state.json.response.datosPersonales);
	const [datos, setDatos] = useState(
		{
			Rut: afiliado.Rut,
			nombres: afiliado.Nombre,
			apellidoPaterno: afiliado.apellidoPaterno,
			apellidoMaterno: afiliado.apellidoMaterno,
			FechaNacimiento: afiliado.FechaNacimiento,
			Email: afiliado.Email,
			Telefono: afiliado.Telefono,
			Direccion: afiliado.Direccion,
			nacionalidad: "CL",
			nombreNacionalidad: "Chileno",
			region: 0,
			nombreRegion: "",
			comuna: "",
			nombreComuna: "",
			Sexo: afiliado.Sexo
		}
	);

	const [empleadores, setEmpleadores] = useState([
		{
			id: Date.now() + Math.random(),
			razonSocialempleador: datosPersonalesInit.Empleadores.length > 0 ? datosPersonalesInit.Empleadores[0].RazonSocial : "",
			rutempleador: datosPersonalesInit.Empleadores.length > 0 ? datosPersonalesInit.Empleadores[0].Rut : datosPersonalesInit.TipoEmpleado === 2 ? datosPersonalesInit.Rut : "",
			emailNotifiacionempleador: datosPersonalesInit.Empleadores.length > 0 ? datosPersonalesInit.Empleadores[0].Email : "",
			telefonoempleador: datosPersonalesInit.Empleadores.length > 0 ? datosPersonalesInit.Empleadores[0].Telefono : "",
			direccionempleador: datosPersonalesInit.Empleadores.length > 0 ? datosPersonalesInit.Empleadores[0].Direccion : "",
			cuentaCorrelativoComuna: datosPersonalesInit.Empleadores.length > 0 ? datosPersonalesInit.Empleadores[0].cuentaCorrelativoComuna : "",
			regionempleador: "0",
			nombreRegion: "",
			comunaempleador: "0",
			nombreComuna: ""
		},
	]);

	const [regionesAfiliado, setRegionesAfiliado] = useState([]);
	const [comunasAfiliado, setComunasAfiliado] = useState([]);

	const [regionesEmpleador, setRegionesEmpleador] = useState([]);
	const [comunasEmpleador, setComunasEmpleador] = useState([]);

	const phoneIsInvalid = (telefono) => {
		if (telefono === undefined) {
			return true;
		}
		if (telefono === null) {
			return true;
		}
		if (telefono === "") {
			return true;
		}
		if (telefono === 0) {
			return true;
		}
		if (telefono === "0") {
			return true;
		}
		if (telefono.length < 9) {
			return true;
		}
		return false;
	};

	useEffect(async () => {
		var result = await sendRequest({
			url: "region",
			method: "GET",
			body: null
		});
		setRegionesAfiliado(result.response);
		setRegionesEmpleador(result.response);
	}, []);

	useEffect(async () => {
		var result = await sendRequest({
			url: "nacionalidad",
			method: "GET",
			body: null
		});
		setNacionalidades(result.response);
	}, []);

	useEffect(async () => {
		var result = await sendRequest({
			url: "tipo",
			method: "GET",
			body: null
		});
		setTipoEmpleado(result.response);
	}, []);

	useEffect(() => {
		const getRegionComuna = async () => {
			if (afiliado?.Comuna !== undefined && afiliado?.Comuna !== "") {
				var respuesta = await sendRequest({
					url: `Comuna/GetRegionByComuna/${afiliado?.Comuna}`,
					method: "GET",
					body: null
				});
				onchangeRegionAfiliado(respuesta.response.IdRegion, false);
			}
		};
		getRegionComuna();
	}, [regionesAfiliado]);

	const onchangeRegionAfiliado = async (ID, changeComuna) => {
		setComunasAfiliado([]);

		var result = await sendRequest({
			url: `Region/${ID}`,
			method: "GET",
			body: null
		});

		if (changeComuna) {
			setDatos({ ...datos, region: ID, nombreRegion: result.response.Nombre, comuna: "0", nombreComuna: "" });
		} else {
			setDatos({ ...datos, region: ID, nombreRegion: result.response.Nombre, comuna: afiliado.Comuna });
		}

		if (ID !== "0") {
			setComunasAfiliado(result.response.Comunas);
		}
	}

	const onchangeComunaAfiliado = (e) => {
		let index = e.nativeEvent.target.selectedIndex;
		let nombreComuna = e.nativeEvent.target[index].text;

		setDatos({ ...datos, comuna: e.target.value, nombreComuna: nombreComuna });
	};

	const FormatNacionalidad = (cadena) => {
		const primerCaracter = ValidateFieldsUtil.Upper(cadena.charAt(0));
		const restoDeLaCadena = cadena.substring(1, cadena.length);
		return primerCaracter.concat(restoDeLaCadena);
	};

	const onchangeRegionEmpleador = async (e, Index) => {

		var ID = e.target.value;
		var text = e.target.options[e.target.selectedIndex].text;

		setComunasEmpleador([]);

		var result = await sendRequest({
			url: `Region/${ID}`,
			method: "GET",
			body: null
		});

		const nuevosEmpleadores = [...empleadores];
		nuevosEmpleadores[Index].regionempleador = ID;
		nuevosEmpleadores[Index].nombreRegion = text;
		nuevosEmpleadores[Index].comunaempleador = "0";
		nuevosEmpleadores[Index].nombreComuna = "";

		setEmpleadores(nuevosEmpleadores);

		if (ID !== "0") {
			setComunasEmpleador(result.response.Comunas)
		}
	}

	const handleChangeInput = (event, index) => {

		var name = event.target.name;
		var value = event.target.value;

		const old = [...empleadores];

		if (name === "telefonoempleador") {
			const re = /^[0-9\b]+$/;
			if (value === "" || re.test(value)) {
				old[index][name] = value;
			}
		} else if (name === "rutempleador") {
			if (value.length < 10) {
				value = value.replace(".", "").replace("-", "");
				const re = /^[0-9\bkK]+$/;
				if (value === "" || re.test(value)) {
					old[index][name] = value;
				}
			} else if (value.length < 12) {
				old[index][name] = value.replace(".", "").replace(".", "").replace("-", "");
			} else if (value.length < 13) {
				old[index][name] = value;
			}
		} else if (name === "comunaempleador") {
			let indexs = event.nativeEvent.target.selectedIndex;
			let nombreComuna = event.nativeEvent.target[indexs].text;

			old[index].nombreComuna = nombreComuna;
			old[index][name] = value;
		} else {
			old[index][name] = value;
		}

		setEmpleadores(old);
	};

	const onchangeNacionalidad = (codigo) => {
		let nombreNacionalidad = "";

		nacionalidades.forEach((element) => {
			if (element.Codigo === codigo) {
				nombreNacionalidad = element.Nombre;
			}
		});

		setDatos({ ...datos, nombreNacionalidad: nombreNacionalidad, nacionalidad: codigo });
	};

	const isInvalidEmail = (index) => {
		var email = empleadores[index].emailNotifiacionempleador;

		if (
			email === null ||
			email === undefined ||
			!validator.isEmail(email) ||
			email === "" ||
			email === 0
		) {
			return true;
		} else {
			return false;
		}
	};

	const isInvalidDireccionArray = () => {
		if (validarExisteEmpleador) {
			for (var i = 0; i <= empleadores.length - 1; i++) {
				var direccion = empleadores[i].direccionempleador;
				if (
					direccion === null ||
					direccion === undefined ||
					direccion === "" ||
					direccion === 0
				) {
					return true;
				}
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let continuar = false;

		if (phoneIsInvalid(datos.Telefono)) {
			await Swal.fire({
				icon: "warning",
				text: "Su número de teléfono contacto personal se encuentra incorrecto, ¿Desea modificarlo?",
				confirmButtonText: "Si",
				showDenyButton: true,
				denyButtonText: "No",
				allowOutsideClick: false,
				returnFocus: false,
			}).then((result) => {
				if (result.isConfirmed) {
					window.scrollTo({
						top: ContactSection.current.offsetTop - 5,
						behavior: "smooth",
					});
				} else if (result.isDenied) {
					continuar = true;
				}
			});
		} else {
			continuar = true;
		}

		if (!continuar) {
			return false;
		}

		if (ValidarEmpleadoresDuplicados()) {
			Swal.fire({
				icon: "warning",
				text: "No se puede asociar el mismo Rut a más de un empleador",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
			return false;
		}

		if (isInvalidDireccionArray()) {
			await Swal.fire({
				icon: "warning",
				text: "¿Desconoce la dirección del empleador?",
				confirmButtonText: "Si",
				showDenyButton: true,
				denyButtonText: "No",
				allowOutsideClick: false,
				returnFocus: false,
			}).then((result) => {
				if (result.isConfirmed) {
					continuar = true;
				} else if (result.isDenied) {
					continuar = false;
				}
			});
		}

		if (continuar) {
			OnInput();
		}
	};

	const OnInput = async () => {
		if (!validarExisteEmpleador) {
			setEmpleadores([]);
		} else {
			empleadores.forEach((element) => {
				if (
					element.direccionempleador === "" ||
					element.direccionempleador === undefined ||
					element.direccionempleador === null
				) {
					element.direccionempleador = "Dirección no ingresada";
				}
			});
		}

		let datosPersonalesTemp = {
			cuentaCorrelativoProspecto: datos.cuentaCorrelativoProspecto,
			Nombre: datos.nombres,
			nombreNacionalidad: datos.nombreNacionalidad,
			apellidoPaterno: datos.apellidoPaterno,
			apellidoMaterno: datos.apellidoMaterno,
			Rut: datos.Rut,
			FechaNacimiento: datos.FechaNacimiento,
			nacionalidad: datos.nacionalidad,
			Email: datos.Email,
			Telefono: datos.Telefono,
			Direccion: datos.Direccion,
			nombreComuna: datos.nombreComuna,
			nombreRegion: datos.nombreRegion,
			Cartola: false,
			Sexo: datos.Sexo,
			TipoEmpleado: estadoEmpleador,
			Empleadores: empleadores,
			region: parseInt(datos.region),
			comuna: datos.comuna,
		};

		let confirmDataTemp = { ...confirmData };
		confirmDataTemp.response.datosPersonales = datosPersonalesTemp;
		setConfirmData(confirmDataTemp);

		let raw = {
			response: {
				cuentasEditadas: [...confirmDataTemp.response.cuentasEditadas],
				datosPersonales: datosPersonalesTemp,
				solicitud: { ...props.location.state.json.response.solicitud },
				ticketId: { ...props.location.state.json.response.ticketId },
				token: { ...props.location.state.json.response.token },
			},
		};

		setJsonBack(raw);
		var data = Crypto.Decrypt("data");

		await fetch(`${configuration.API_URL}/api/log/datos/${datosPersonalesInit.Rut}/${data.autenticacion}`,
			{
				method: "POST",
				body: JSON.stringify(raw),
				headers: { "Content-Type": "text/plain" },
			}
		).catch((error) => {
			console.error(error);
			Swal.fire({
				icon: "error",
				text: error.message,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		});

		setRedirect(true);
	};

	const nuevoEmpleador = () => {

		if (empleadores.length < 4) {
			setEmpleadores([
				...empleadores,
				{
					id: Date.now() + Math.random(),
					razonSocialempleador: "",
					rutempleador: "",
					emailNotifiacionempleador: "",
					telefonoempleador: "",
					direccionempleador: "",
					comunaempleador: "",
					regionempleador: "",
				},
			]);
		}
	};

	const handleRemoveFields = (id) => {
		const values = [...empleadores];
		values.splice(
			values.findIndex((value) => value.id === id),
			1
		);
		setEmpleadores(values);
	};

	function ValidarEmpleadoresDuplicados(element, index) {
		let resultToReturn = false;
		for (let i = 0; i < empleadores.length; i++) {
			// nested for loop
			for (let j = 0; j < empleadores.length; j++) {
				// prevents the element from comparing with itself
				if (i !== j) {
					// check if elements' values are equal
					if (empleadores[i].rutempleador === empleadores[j].rutempleador) {
						// duplicate element present
						resultToReturn = true;
						// terminate inner loop
						break;
					}
				}
			}
			// terminate outer loop
			if (resultToReturn) {
				break;
			}
		}

		if (resultToReturn) {
			return true;
		} else {
			return false;
		}
	}

	const formatValidateRut = (rutString) => {

		if (rutString === undefined) {
			return "";
		}

		let rutparts = rutString.split("-");
		let rut = rutparts[0].replace(/\./g, "");
		let h = "";
		let dv = "";

		if (rutparts.length > 1) {
			h = "-";
			dv = rutparts[1];
		} else {
			if (rut.length > 2) {
				h = "-";
				dv = rut[rut.length - 1];
				rut = rut.substring(0, rut.length - 1);
			}
		}

		if (rut.length > 8) rut = rut.substring(0, 8);
		let T = rut;
		var M = 0,
			S = 1;
		for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
		//aqui formeatea el rut
		if (dv === (S ? S - 1 : "k").toString()) {
			rut = rut.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

			return rut + h + dv;
		} else if (dv === (S ? S - 1 : "K").toString()) {
			rut = rut.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

			return rut + h + dv;
		} else {
			return "";
		}
	};

	const onChangeAfiliado = async (Id) => {

		var result = await sendRequest({
			url: `tipo/${Id}`,
			method: "GET",
			body: null
		});

		setEstadoEmpleador(Id);

		setValidarExiteEmpleador(result.response?.ExisteEmpleador);
	};

	const validaData = (campo) => {
		return campo !== null && campo !== "" && campo !== undefined && campo.length > 0;
	}

	const validateEmployer = (employer) => {
		const hasRazonSocial = validaData(employer.razonSocialempleador);
		const hasRut = validaData(employer.rutempleador);
		const hasEmail = validaData(employer.emailNotifiacionempleador);
		const hasTelefono = validaData(employer.telefonoempleador);
		const hasOnlyNumbers = hasTelefono && !isNaN(employer.telefonoempleador);
		const hasDireccion = validaData(employer.direccionempleador);
		const hasRegion = validaData(employer.regionempleador) && employer.regionempleador > 0;
		const hasComuna = validaData(employer.comunaempleador) && employer.comunaempleador !== "0";

		return (
			hasRazonSocial &&
			hasRut &&
			hasEmail &&
			hasTelefono &&
			hasOnlyNumbers &&
			hasDireccion &&
			hasRegion &&
			hasComuna
		);
	};

	const validateAllEmployers = () => {
		const allValid = empleadores.every((employer) => validateEmployer(employer));
		return allValid;
	};

	const [disabled, setDisabled] = useState(true);

	useEffect(() => {

		const hasNombres = datos.nombres && datos.nombres.length > 0;
		const hasEmail = datos.Email && datos.Email.length > 0;
		const hasTelefono = datos.Telefono && datos.Telefono.length === 9;
		const hasDireccion = datos.Direccion && datos.Direccion.length > 0;
		const hasRegion = datos.region != 0;
		const hasComuna = datos.comuna && datos.comuna.length > 0;

		var enabledAfiliado = hasNombres && hasEmail && hasTelefono && hasDireccion && hasRegion && hasComuna;

		var enabledEmpleadores = validateAllEmployers();

		var enabled = true;

		if (validarExisteEmpleador) {
			enabled = enabledAfiliado && enabledEmpleadores;
		}
		else {
			enabled = enabledAfiliado;
		}

		setDisabled(!enabled);
	}, [datos, empleadores, validarExisteEmpleador]);

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	if (redirect) {
		return (
			<Redirect
				to={{
					pathname: "/confirmartraspaso",
					state: { confirmData: confirmData, jsonBack: jsonBack },
				}}
			/>
		);
	}

	return (
		<>
			<ValidarData />
			<IdleTimerContainer isLoggedIn={true} />;
			<CabeceraPasos paso="2" />
			<div className="container_fondos">
				<Form onSubmit={handleSubmit}>
					<DatosPersonalesA datos={afiliado} />
					<div className="media_grey" ref={ContactSection}>
						<h5 className="mt-37_">
							<strong>Datos de contacto {disabled}</strong>
						</h5>

						<Form.Label>Email</Form.Label>
						<Form.Control
							name="Email"
							type="text"
							placeholder="Email"
							value={datos.Email}
							isInvalid={!validator.isEmail(datos.Email)}
							onChange={(e) => {
								setDatos({ ...datos, Email: e.target.value });
							}}
						/>

						<Form.Label>Teléfono</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className="preappend-form">
									<strong>+56</strong>
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="Telefono"
								maxLength="9"
								type="tel"
								placeholder="Teléfono"
								value={datos.Telefono}
								isInvalid={phoneIsInvalid(datos.Telefono)}
								onChange={(e) => {
									const re = /^[0-9\b]+$/;
									const value = e.target.value;
									if (value === "" || re.test(value)) {
										setDatos({ ...datos, Telefono: value });
									}
								}}
							/>
						</InputGroup>

						<Form.Label>Dirección particular</Form.Label>
						<Form.Control
							name="Direccion"
							type="text"
							placeholder="Dirección particular"
							value={datos.Direccion}
							isInvalid={datos.Direccion === ""}
							onChange={(e) => {
								const re = /^[-'A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+$/g;
								const value = e.target.value;
								if (value === "" || re.test(value)) {
									setDatos({ ...datos, Direccion: value });
								}
							}}
						/>
						<Form.Label>Región</Form.Label>
						<Form.Control
							name="regionpersona"
							as="select"
							value={datos.region}
							isInvalid={datos.region === "0"}
							onChange={(e) => onchangeRegionAfiliado(e.target.value, true)}
						>
							<option key="0" value="0">
								-- Seleccione una Región --
							</option>
							{regionesAfiliado.map((item) => (
								<option key={item.Id} value={item.Id}>
									{item.Nombre}
								</option>
							))}
						</Form.Control>

						<Form.Label>Comuna</Form.Label>
						<Form.Control
							name="comunapersona"
							as="select"
							value={datos.comuna}
							isInvalid={datos.comuna === "" || datos.comuna == "0"}
							onChange={(e) => onchangeComunaAfiliado(e)}
						>
							<option key="0" value="0">
								--Seleccione una Comuna --
							</option>
							{comunasAfiliado.map((item) => (
								<option key={item.Id} value={item.CutComuna}>
									{item.Nombre}
								</option>
							))}
						</Form.Control>

						<Form.Label>Nacionalidad</Form.Label>
						<Form.Control
							name="Nacionalidad"
							isInvalid={datos.nacionalidad === ""}
							as="select"
							value={datos.nacionalidad}
							onChange={(e) => onchangeNacionalidad(e.target.value)}
						>
							<option key="0" value="0">
								--Seleccione una Nacionalidad --
							</option>
							{nacionalidades.map((item) => (
								<option key={item.Id} value={item.Codigo}>
									{FormatNacionalidad(item.Nombre).replace(/\./g, "")}
								</option>
							))}
						</Form.Control>
						<br />
						<div className="text-muted mx-auto">
							<div className="form-check">
								<input
									id="defaultCheck1"
									className="form-check-input"
									type="checkbox"
									value={datos.Cartola}
									defaultChecked={true}
									onChange={(e) => {
										setDatos({ ...datos, Cartola: e.target.checked });
									}}
								/>
								<label
									className="form-check-label"
									htmlFor="defaultCheck1"
									style={{
										fontFamily: "Poppins, sans-serif",
										fontWeight: "300px",
									}}
								>
									Deseo recibir mi cartola cuatrimestral por correo
								</label>
								<br />
								<small>
									Si desmarcas esta opción, te enviaremos tu cartola por carta a
									tu dirección.
								</small>
							</div>
						</div>
					</div>

					<div className="media_grey">
						<h5 className="mt-37_">
							<strong>Antecedentes laborales</strong>
						</h5>

						<Form.Label>Tipo de Afiliado</Form.Label>
						<Form.Control
							name="tipoAfiliado"
							isInvalid={estadoEmpleador === 0}
							as="select"
							value={estadoEmpleador}
							onChange={(e) => {
								onChangeAfiliado(e.target.value);
							}}
						>
							<option key="0" value="0">
								-- Seleccione un Tipo de Afiliado --
							</option>
							{tipoEmpleado.map((item) => (
								<option key={item.Id} value={item.Previred}>
									{item.Detalle}
								</option>
							))}
						</Form.Control>

						<Col key="col1">
							{!validarExisteEmpleador ? (
								true
							) : (
								<>
									{empleadores.map((empleador, index) => (
										<>
											<div>
												<div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
													<h5 className="mt-37"> Datos empleador</h5>
													{index >= 1 ? (
														<button
															style={{
																border: "none",
																textAlign: "center",
																color: "#aaa",
																borderRadius: "50%",
																width: "30px",
																height: "30px",
															}}
															onClick={() => handleRemoveFields(empleador.id)}
															type="button"
														>
															x
														</button>
													) : (
														""
													)}
												</div>
												<Form.Label>Razón social</Form.Label>
												<Form.Control
													type="text"
													name="razonSocialempleador"
													placeholder="Razon social"
													value={empleadores[index].razonSocialempleador}
													isInvalid={empleadores[index].razonSocialempleador === ""}
													onChange={(e) => {
														const re = /^[-'A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+$/g;

														var value = e.target.value;
														if (value === "" || re.test(value)) {
															handleChangeInput(e, index);
														}
													}}
												/>

												<Form.Label>Rut</Form.Label>
												<Form.Control
													type="text"
													name={"rutempleador"}
													placeholder="Rut"
													value={empleadores[index].rutempleador}
													isInvalid={empleadores[index].rutempleador === ""}
													onBlur={(e) => {
														var isValid = formatValidateRut(empleadores[index].rutempleador);
														const old = [...empleadores];
														old[index]["rutempleador"] = isValid;
														setEmpleadores(old);
													}}
													onChange={(e) => {
														handleChangeInput(e, index);
													}}
												/>

												<Form.Label>Email a notificar</Form.Label>
												<Form.Control
													type="text"
													name="emailNotifiacionempleador"
													placeholder="Email a notificar"
													value={empleadores[index].emailNotifiacionempleador}
													isInvalid={isInvalidEmail(index)}
													onChange={(e) => {
														handleChangeInput(e, index);
													}}
												/>

												<Form.Label>Teléfono</Form.Label>
												<InputGroup>
													<InputGroup.Prepend>
														<InputGroup.Text className="preappend-form">
															<strong>+569</strong>
														</InputGroup.Text>
													</InputGroup.Prepend>
													<Form.Control
														type="text"
														name="telefonoempleador"
														placeholder="Teléfono"
														maxLength="8"
														value={empleadores[index].telefonoempleador}
														isInvalid={empleadores[index].telefonoempleador !== null && empleadores[index].telefonoempleador !== undefined && empleadores[index].telefonoempleador.length < 8}
														onChange={(e) => {
															handleChangeInput(e, index);
														}}
													/>
												</InputGroup>
												<Form.Label>Dirección</Form.Label>
												<Form.Control
													type="text"
													name="direccionempleador"
													placeholder="Dirección"
													value={empleadores[index].direccionempleador}
													isInvalid={empleadores[index].direccionempleador === ""}
													onChange={(e) => {
														var value = e.target.value;
														const re = /^[-'A-Za-zÀ-ÖØ-öø-ÿ0-9\s]+$/g;

														if (value === "" || re.test(value)) {
															handleChangeInput(e, index);
														}
													}}
												/>
												<Form.Label>Región</Form.Label>
												<Form.Control
													as="select"
													name={`regionempleador`}
													isInvalid={empleadores[index].regionempleador === "0"}
													onChange={(e) => {
														onchangeRegionEmpleador(e, index);
														handleChangeInput(e, index);
													}}
												>
													<option key="0" value="0">
														-- Seleccione una Región --
													</option>
													{regionesEmpleador.map((opcion) => (
														<option key={opcion.Id} value={opcion.Id}>
															{opcion.Nombre}
														</option>
													))}
												</Form.Control>
												<Form.Label>Comuna</Form.Label>
												<Form.Control
													as="select"
													name={`comunaempleador`}
													value={empleadores[index].comunaempleador}
													isInvalid={empleadores[index].comunaempleador === "0"}
													onChange={(e) => {
														handleChangeInput(e, index);
													}}
												>
													<option key="0" value="0">
														-- Seleccionar Comuna --
													</option>
													{comunasEmpleador.map((opcion) => (
														<option key={opcion.Id} value={opcion.CutComuna}>
															{opcion.Nombre}
														</option>
													))}
												</Form.Control>
											</div>
										</>
									))}
								</>
							)}
						</Col>
						{parseInt(estadoEmpleador) !== 7 && empleadores.length < 4 ? (
							<Button
								className="link"
								size="sm"
								variant="light"
								onClick={nuevoEmpleador}
							>
								+ Agregar empleador
							</Button>
						) : null}
					</div>
					<div className="btn_card_">
						<Button
							id="B-TW-0008"
							variant="primary"
							disabled={disabled}
							type="submit"
							block
							className="cambiarme"
						>
							Continuar
						</Button>
					</div>
					<div className="btn_card">
						<Link
							to={{
								pathname: "/cuentasyfondo",
								state: {
									json: JSON.parse(localStorage.getItem("intis")).location.state
										.json,
								},
							}}
							className="link"
						>
							Volver
						</Link>
					</div>
				</Form>
			</div>
		</>
	);
};

export default DatosPersonales;
