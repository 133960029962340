import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import Swal from "sweetalert2";
import Timer from "../img/timer.png";

function Container(props) {
	var isLoggedIn = useState(props.isLoggedIn);
	const idleTimerRef = useRef(null);

	const onIdle = () => {
		if (isLoggedIn) {
			isLoggedIn = false;
			Swal.fire({
				title: "¡Ups!",
				html: `<img src=${Timer} height="60" /></br></br><p>Estuviste mucho tiempo ináctivo. Intenta nuevamente tu trámite.</p>`,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.replace("/");
				}
			});
		}
	};

	//5 minutos
	return <IdleTimer ref={idleTimerRef} timeout={300000} onIdle={onIdle} />;
}

export default Container;
