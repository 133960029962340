import "../traspasoui.css";
import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Phone from "./inputs/Phone";
import Rut from "./inputs/Rut";
import Email from "./inputs/Email";
import Name from "./inputs/Name";
import Context from "../../../../store/context";
import configuration from "../../../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../../../util/Crypto";

export default function Formulario() {
	const { state, actions } = useContext(Context);
	const [formValues, setFormValues] = useState({});
	const [redirect, setRedirect] = useState(false);
	const [validateForm, setValidateForm] = useState({
		rut: true,
		name: true,
		phone: true,
		email: true,
	});

	const handleSubmit = async (event) => {
		event.preventDefault();

		actions({ type: "setState", payload: { ...state, loading: true } });

		let raw = {
			Rut: formValues.rut.trim().toLowerCase().replace(/\./g, ""),
			Names: formValues.name.trim(),
			Phone: `+56${formValues.phone.trim()}`,
			Email: formValues.email.trim(),
		};

		await fetch(`${configuration.API_URL}/api/Traspaso`, {
			method: "POST",
			headers: configuration.JSON_HEADER,
			body: JSON.stringify(raw),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.Code === 0) {
					var data = Crypto.Decrypt("data");
					data.run = formValues.rut;
					data.email = formValues.email;
					
					var parts = formValues.name.split(" ");
					
					data.name = parts[0];
					data.fatherName = parts[parts.length-2];
					data.motherName = parts[parts.length-1];

					data.phone = formValues.phone;
					data.solicitud = response.Response.Token;

					Crypto.Encrypt("data", data);

					actions({ type: "setState", payload: { ...state, loading: false } });
					setRedirect(true);
				}
				actions({ type: "setState", payload: { ...state, loading: false } });
			})
			.catch((error) => {
				actions({ type: "setState", payload: { ...state, loading: false } });

				console.error(error);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});
	};

	const handleChange = (event) => {
		event.preventDefault();

		let arrayForm = { ...formValues };

		if (event.target.name === "rut") {
			arrayForm.rut = event.target.value;
		}
		switch (event.target.name) {
			case "rut":
				arrayForm.rut = event.target.value;
				break;
			case "email":
				arrayForm.email = event.target.value;
				break;
			case "name":
				arrayForm.name = event.target.value;
				break;
			case "phone":
				arrayForm.phone = event.target.value;
				break;
			default:
				break;
		}

		setFormValues(arrayForm);
	};

	if (!redirect) {
		return (
			<>
				<Form onSubmit={handleSubmit} onChange={handleChange}>
					<div className="form_">
						<Form.Group>
							<Form.Label>
								<strong>RUT</strong>
							</Form.Label>
							<Rut
								validateForm={validateForm}
								setValidateForm={setValidateForm}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>
								<strong>Nombre</strong>
							</Form.Label>
							<Name
								validateForm={validateForm}
								setValidateForm={setValidateForm}
							/>
						</Form.Group>
						<Phone
							validateForm={validateForm}
							setValidateForm={setValidateForm}
						/>
						<Form.Group>
							<Form.Label>
								<strong>Correo electrónico</strong>
							</Form.Label>
							<Email
								validateForm={validateForm}
								setValidateForm={setValidateForm}
							/>
						</Form.Group>
						<Form.Group>
							<div className="button_">
								<Button id="B-TW-0001" variant="primary" type="submit" block className="cambiarme" disabled={validateForm.rut || validateForm.name || validateForm.phone || validateForm.email} >Continuar</Button>
							</div>
						</Form.Group>
					</div>
				</Form>
			</>
		);
	} else {
		return (
			<Redirect
				to={{
					pathname: "/autenticacion",
					state: { data: JSON.stringify(Object.assign({}, formValues)) },
				}}
			/>
		);
	}
}
