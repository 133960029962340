
import React from 'react';
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { useState, useRef } from 'react';

function Tooltips({ texto }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <>
            <img src="./imgs/pregunta.svg" alt="preguntas" style={{ marginLeft: 10 }} ref={target} onClick={() => setShow(!show)} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} />
            <Overlay show={show} placement="top" containerPadding={20} target={target}>
                <Popover className="modelo-popover">
                    <Popover.Content className="tooltip-content">
                        <div>
                            <p style={{ color: 'white' }}>{texto}</p>
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    );
}

export default Tooltips;