class Genericas {

    static campoVacio(campos) {
        let campo = campos[0];

        if (!campo) return true;
        else return typeof campo.valor === 'undefined' || campo.valor === null || campo.valor === '';
    }

    static esNumerico(campos) {
        let campo = campos[0];
        if (!campo) return false;
        return (isNaN(campo.valor));
    }

    static esEmail(campos) {
        let campo = campos[0];
        if (!campo) return false;
        return !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(campo.valor));
    }

    static validar(regla, campos, valoresReferencia) {
        let resultado = false;
        switch (regla) {
            case 'vacio': resultado = this.campoVacio(campos); break;
            case 'numerico': resultado = this.esNumerico(campos); break;
            case 'email': resultado = this.esEmail(campos); break;
            default: resultado = false; break;
        }

        return !resultado;
    }
}

export default Genericas;