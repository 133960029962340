import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import useGlobalState from "./store/useGlobalState";
import Context from "./store/context";

const Index = () => {
	const store = useGlobalState();
	return (
		<React.StrictMode>
			<Context.Provider value={store}>
				<App />
			</Context.Provider>
		</React.StrictMode>
	);
};

ReactDOM.render(<Index />, document.getElementById("root"));

reportWebVitals();
