import "../cotizacion.css";
import { useContext, useEffect, useState } from "react";
import {
	Button,
	Card,
	Form,
	FormControl,
	InputGroup,
	Modal,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import CabeceraPasos from "../../components/CabeceraPasos";
import SabiasQueFondo from "./SabiasQueFondo";
import LimiteFondos from "./LimiteFondos";
import Switch from "@material-ui/core/Switch";
import Tooltips from "./Tooltip";
import RegistrarPaso from "../../../util/RegistrarPaso";
import Context from "../../../../store/context";
import $ from "jquery";
import Swal from "sweetalert2";
import configuration from "../../../settings/Configurations";
import ValidateFieldsUtil from "../../../util/validacion/ValidateFieldsUtil";
import Crypto from "../../../util/Crypto";
import IdleTimerContainer from "../../../util/IdleTimer/Container";
import sendRequest from "../../../util/call/sendRequest";
import ValidarData from "../../components/ValidarData";

export default function FormCotizacion(props) {
	const afiliado = props.location.state.json.response.afiliado;

	const [checkA, setCheckA] = useState(3);
	const [checkB, setCheckB] = useState(3);
	const { state, actions } = useContext(Context);
	const [hayCuentasElegidas, setHayCuentasElegidas] = useState(true);
	const [pasoRegistrado, setPasoRegistrado] = useState(false);
	const [responseFinal, setResponseFinal] = useState(null);
	const [validateResponseFinal, setValidateResponseFinal] = useState(false);
	const cuentasObligatorias = ["CCICO", "CAI", "CCIAV"];
	const cuentasVoluntarias = ["CCIDC", "CCICV", "CAV", "APVC"];
	const fondosDisponibles = ["A", "B", "C", "D", "E"];
	const permiteDistribucionObligatorias = true;
	const fnac = afiliado.fechaNacimiento ? afiliado.fechaNacimiento : "19100926";
	const dpattern = /(\d{4})(\d{2})(\d{2})/;
	const fnacd = new Date(fnac.replace(dpattern, "$1-$2-$3"));
	const edad = Math.abs(
		Math.round(
			(new Date().getTime() - fnacd.getTime()) / 1000 / (60 * 60 * 24) / 365.25
		)
	);
	const sexo = afiliado.genero;
	const nombreafiliado = afiliado.nombres
		.toLowerCase()
		.split(" ")
		.map((n) => ValidateFieldsUtil.Upper(n[0]) + n.slice(1))
		.join(" ");

	const fondoElegible = (f) => {
		const limits = { M: [56, 66, 99, 999, 999], F: [51, 61, 99, 999, 999] };
		const limarr = limits[sexo];
		const inf = fondosDisponibles.indexOf(f);
		const lim = limarr[inf];
		return edad < lim;
	};

	let elegibles = { A: true, B: true, C: true, D: true, E: true };
	for (const [key, value] of Object.entries(elegibles))
		elegibles[key] = fondoElegible(key);

	const ctasObligatorias = props.location.state.json.response.cuentas
		.filter((c) => cuentasObligatorias.includes(c.tipo))
		.sort((a, b) => {
			if (
				cuentasObligatorias.indexOf(a.tipo) >
				cuentasObligatorias.indexOf(b.tipo)
			)
				return 1;
			if (
				cuentasObligatorias.indexOf(a.tipo) <
				cuentasObligatorias.indexOf(b.tipo)
			)
				return -1;
			return 0;
		});

	const ctasVoluntarias = props.location.state.json.response.cuentas.filter(
		(c) => !cuentasObligatorias.includes(c.tipo)
	);

	props.location.state.json.response.cuentas =
		ctasObligatorias.concat(ctasVoluntarias);
	const volIndex = ctasObligatorias.length;
	const ctasLen = props.location.state.json.response.cuentas.length;

	let modificadas = new Array(ctasLen).fill(false);
	let distribuciones = [];
	let distribcionFondos = [];
	let fondosRecaudadores = [];
	let ctasSel = [];
	let tipoTraspaso = [];
	let porcTraspaso = [];

	const sumarSaldos = (acumulador, a) => acumulador + a.saldo;

	for (let cd = 0; cd < ctasLen; cd++) {
		const ff = props.location.state.json.response.cuentas[cd].fondos;
		let fondosSaldos = ff.map((f) => ({
			fondo: f.nombre,
			saldo: parseInt(f.saldo),
		}));

		const ini = ff.length > 1 ? 10 : 100;
		const fin = ff.length > 1 ? 90 : 0;

		distribuciones.push({ inicio: ini, resto: fin, original: true });
		distribcionFondos.push({
			saldoTotal: fondosSaldos.reduce(sumarSaldos, 0),
			distribucion: fondosSaldos,
		});

		const primeroSel = ff.length < 2;
		fondosRecaudadores.push({ recaudadorA: primeroSel, recaudadorB: false });
		ctasSel.push(false);
		tipoTraspaso.push("total");
		porcTraspaso.push(10);
	}

	const [ctasModificadas, setCtasModificadas] = useState(modificadas);
	const [ctasDist, setCtasDist] = useState(distribuciones);
	const [ctasDistFondos, setCtasDistFondos] = useState(distribcionFondos);
	const [ctasRecaudadores, setCtasRecaudadores] = useState(fondosRecaudadores);
	const [ctasSeleccionadas, setCuentasSeleccionadas] = useState(ctasSel);
	const [cuentasRechazo] = useState(
		props.location.state.json.response.cuentasRechazo
	);
	const [json] = useState(props.location.state.json);
	const [response, setResponse] = useState({});
	const [render, setRender] = useState(false);
	const [show, setShow] = useState(false);
	const [arrayFondosImg, setArrayFondosImg] = useState([]);
	const [habilitarGuardado, setHabilitarGuardado] = useState(false);
	const [fondosInicial, setFondosInicial] = useState([]);
	const [radioVal, setRadioVal] = useState("1");
	const [indexModal, setIndexModal] = useState(0);
	const [cuentaModal, setCuentaModal] = useState({});
	const [cuentasModal, setCuentasModal] = useState([]);
	const verSeleccionados = (objt) =>
		objt.a || objt.b || objt.c || objt.d || objt.e;

	const [traspaso, setTraspaso] = useState({
		valorA: "10",
		tipo: "total",
	});
	const [regimenesGlobal, setRegimenesGlobal] = useState([]);

	const [mandatoGlobal, setMandatoGlobal] = useState([]);

	const handleClose = () => setShow(false);

	const formatDecimal = (val) =>
		val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

	const getTipoCuenta = (tipo) => {
		let r = "";
		switch (tipo) {
			case "CCICO":
				r = "Cuenta Cotización Obligatoria";
				break;
			case "CCICV":
				r = "Cuenta Capitalización Individual Cotizaciones Voluntarias";
				break;
			case "CCIDC":
				r = "Cuenta Capitalización Individual Depósitos convenidos";
				break;
			case "CAV":
				r = "Cuenta de Ahorro Voluntario";
				break;
			case "CAI":
				r = "Cuenta de Ahorro de Indemnización";
				break;
			case "CCIAV":
				r = "Cuenta Capitalización Individual de Afiliado Voluntario";
				break;
			case "APVC":
				r = "Ahorro previsional voluntario colectivo";
				break;
			case "APV":
				r = "APV";
				break;
			case "CTA2":
				r = "Cuenta 2 ";
				break;
			default:
				r = "[NO CARGADO]";
		}
		return r;
	};

	const envolverDiv = () => {
		if (render) {
			let contenedor = "<div class='test' style='margin-top:20px'></div>";
			$(".OBLIGATORIA").wrapAll(contenedor);

			let contenedor2 = "<div class='test2' style='margin-top:20px'></div>";
			$(".VOLUNTARIA").wrapAll(contenedor2);
		} else {
			setRender(true);
		}
		localStorage.setItem("intis", JSON.stringify({ ...props }));
	};

	const mostrarErrores = async (arr) => {
		const steps = [];

		for (var i = 1; i <= arr.length; i++) {
			steps.push(i.toString());
		}

		const swalQueueStep = Swal.mixin({
			confirmButtonText: "Siguiente",
			cancelButtonText: "Anterior",
			progressSteps: steps,
			reverseButtons: true,
		});

		const values = [];
		let currentStep;

		for (currentStep = 0; currentStep < steps.length;) {
			var text = `La ${arr[currentStep].nombreTipo} perteneciente a ${arr[currentStep].origen} presenta el siguiente problema: ${arr[currentStep].causalRechazo}`;

			if (arr[currentStep].causalRechazoCodigo === 15) {
				text = `La cuenta ${arr[currentStep].nombreTipo} que intentas traspasar desde AFP ${arr[currentStep].origen} se encuentra bloqueada, dado que presentas una medida cautelar vigente por concepto de la Ley de Pensión de Alimentos (ley 21.484)`;
			}

			const result = await swalQueueStep.fire({
				title: "No es posible concretar tu solicitud",
				showCancelButton: currentStep > 0,
				html: text,
				currentProgressStep: currentStep,
			});

			if (result.value) {
				values[currentStep] = result.value;
				currentStep++;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				currentStep--;
			} else {
				break;
			}
		}
	};

	useEffect(async () => {
		OnLoading(true);
		var data = Crypto.Decrypt("data");

		if (data.email == null || data.email == "") {
			data.email = afiliado.email;
		}

		let raw = {
			Rut: afiliado.dni,
			Names: `${afiliado.nombres} ${afiliado.apellidoPaterno} ${afiliado.apellidoMaterno}`,
			Phone: afiliado.telefonoCelular,
			Email: data.email,
		};

		await fetch(`${configuration.API_URL}/api/Traspaso`, {
			method: "POST",
			headers: configuration.JSON_HEADER,
			body: JSON.stringify(raw),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.Code === 0) {
					data.run = raw.Rut;
					data.email = raw.Email;
					data.name = raw.Names;
					data.phone = raw.Phone;
					data.authenticated = true;
					Crypto.Encrypt("data", data);
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});

		if (!pasoRegistrado) {
			RegistrarPaso(3);
			setPasoRegistrado(true);
		}

		await fetch(`${configuration.API_URL}/api/Region`, {
			method: "GET",
			headers: configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then((response) => {
				localStorage.setItem(
					"locaciones",
					JSON.stringify(response.response)
				);
			})
			.catch((error) => {
				console.error(error);
				Swal.fire({
					icon: "error",
					text: error,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});

		let fondosArray = [];
		let cuentasArray = [];
		json.response.cuentas.forEach((cuenta, i) => {
			let arrayTemp = [];

			cuenta.fondos.forEach((fondo, findex) => {
				arrayTemp[findex] = { ...fondo };
			});
			fondosArray[i] = arrayTemp;
			cuentasArray[i] = { ...cuenta };
		});
		setCuentasModal(cuentasArray);
		setFondosInicial(fondosArray);
		setResponse({ ...json });
		envolverDiv();

		if (render == true) {
			if (cuentasRechazo.length > 0) {
				await mostrarErrores(cuentasRechazo);
			}
		}

		OnLoading(false);
	}, []);

	const getSelectedArray = (modificado, inicialArray, index, parentIndex) => {
		const finalArray = modificado
			? response.response.cuentas[parentIndex].fondos[index]
			: inicialArray;
		return {
			fondoA: finalArray.nombre === "A",
			fondoB: finalArray.nombre === "B",
			fondoC: finalArray.nombre === "C",
			fondoD: finalArray.nombre === "D",
			fondoE: finalArray.nombre === "E",
		};
	};

	const preseleccionar = (index) => {
		let myorg0 = getSelectedArray(
			ctasModificadas[index],
			fondosInicial[index][0],
			0,
			index
		);
		let final = [myorg0];

		if (
			fondosInicial[index].length > 1 ||
			(index >= volIndex && response.response.cuentas[index].fondos.length > 1)
		) {
			if (
				!ctasModificadas[index] ||
				(ctasModificadas[index] &&
					response.response.cuentas[index].fondos.length > 1)
			)
				final.push(
					getSelectedArray(
						ctasModificadas[index],
						fondosInicial[index][1],
						1,
						index
					)
				);
		}

		return final;
	};

	const handleShow = (i) => {
		setIndexModal(i);

		let tempTraspaso = { ...traspaso };
		tempTraspaso.tipo =
			response.response.cuentas[i].tipoTraspaso === "parcial"
				? "parcial"
				: "total";
		tempTraspaso.valorA = response.response.cuentas[i].porcentajeTraspaso
			? response.response.cuentas[i].porcentajeTraspaso
			: 10;
		setTraspaso(tempTraspaso);

		setCuentaModal({ ...response.response.cuentas[i] });
		const fondosdist = radioVal;
		let arrayFondos = [];
		fondosInicial.forEach((f, i) => {
			f.forEach((fondotmp, fi) => {
				arrayFondos[fi] = fondotmp;
			});
		});

		setRadioVal(response.response.cuentas[i].fondos.length === 1 ? "1" : "2");
		let ifarr = preseleccionar(i);
		if (ifarr.length === 1 && radioVal === "2") {
			ifarr.push({ a: false, b: false, c: false, d: false, e: false });
		}
		setArrayFondosImg(ifarr);
		setHabilitarGuardado(false);
		let recaudaArr = ctasRecaudadores;
		const algunseleccionado = tieneAlgunFondoSeleccionado(
			ifarr,
			null,
			fondosdist
		);
		const seleccionValida = fondosSeleccionadosEnRegla(
			ifarr,
			0,
			fondosdist,
			true
		);
		const rangosSeleccionado =
			mostrarDistribucion(indexModal) && fondosdist === "2"
				? validarInicioResto()
				: true;
		const recaudadorSeleccionado =
			fondosdist === "2"
				? recaudaArr[indexModal].recaudadorA === true ||
				recaudaArr[indexModal].recaudadorB === true
				: true;
		setHabilitarGuardado(
			algunseleccionado &&
			rangosSeleccionado &&
			recaudadorSeleccionado &&
			seleccionValida
		);
		setShow(true);

		validarFondos(true);
	};

	const getNombreFondo = (arrayImg) => {
		let nomFondo = "";
		if (arrayImg.fondoA) nomFondo = "A";
		else if (arrayImg.fondoB) nomFondo = "B";
		else if (arrayImg.fondoC) nomFondo = "C";
		else if (arrayImg.fondoD) nomFondo = "D";
		else if (arrayImg.fondoE) nomFondo = "E";
		return nomFondo;
	};

	const formModalFondosGuardar = (event) => {
		event.preventDefault();
		setTraspaso({ tipo: "total" });

		let cuentaBefore = { ...response.response.cuentas[indexModal] };

		let temp = [];

		if (radioVal === "1") {
			cuentaBefore.fondos.forEach(function (item, i) {
				let fondo = {
					origen: item.origen,
					nombre: getNombreFondo(arrayFondosImg[0]),
					saldo: response.response.cuentas[indexModal].saldo,
					fondoRecaudador: true,
					porcentaje: 100,
					valorPorcentaje: ctasDistFondos[indexModal].saldoTotal,
					regimenes: item.regimenes,
				};

				temp.push(fondo);
			});

			response.response.cuentas[indexModal].fondos = temp;
		}

		if (radioVal === "2") {
			let fondos = cuentaBefore.fondos;

			for (var index = 0; index < 2; index++) {
				let origen = fondos[0].origen;
				let regimenes = fondos[0].regimenes;

				if (fondos.length > 1) {
					origen = fondos[index].origen;
					regimenes = fondos[index].regimenes;
				}

				var recaudador = ctasRecaudadores[indexModal].recaudadorA;
				var porcentaje = parseInt(ctasDist[indexModal].inicio);

				if (index == 1) {
					recaudador = ctasRecaudadores[indexModal].recaudadorB;
					porcentaje = parseInt(ctasDist[indexModal].resto);
				}

				const saldo = response.response.cuentas[indexModal].saldo;
				let valorPorcentaje = (saldo * porcentaje) / 100;

				let fondo = {
					origen: origen,
					nombre: getNombreFondo(arrayFondosImg[index]),
					saldo: saldo,
					porcentaje: porcentaje,
					valorPorcentaje: valorPorcentaje, //ctasDistFondos[indexModal].distribucion[index].saldo,
					regimenes: regimenes,
					fondoRecaudador: recaudador,
				};

				temp.push(fondo);
			}

			response.response.cuentas[indexModal].fondos = temp;
			let ctasMod = ctasModificadas;
			ctasMod[indexModal] = true;
			setCtasModificadas(ctasMod);
		}

		response.response.cuentas[indexModal].tipoTraspaso = traspaso.tipo;
		response.response.cuentas[indexModal].porcentajeTraspaso = 100;

		if (traspaso.tipo !== "total") {
			response.response.cuentas[indexModal].porcentajeTraspaso = parseInt(
				traspaso.valorA
			);
		}

		validarFondos(true);

		setShow(false);
	};

	const radiosClick = (event) => {
		setFondosInicial(fondosInicial);
		setRadioVal(event.target.value);

		const fondosdist = event.target.value;
		let distros = ctasDist;
		let predistribucion = ctasDistFondos;
		let recaudaArr = ctasRecaudadores;

		if (
			predistribucion[indexModal].distribucion.length < 2 &&
			fondosdist === "2"
		) {
			recaudaArr[indexModal].recaudadorA = false;
			recaudaArr[indexModal].recaudadorB = false;
			distros[indexModal].inicio = 10;
			distros[indexModal].resto = 90;
			distros[indexModal].original = true;

			predistribucion[indexModal].distribucion.push({ fondo: "x", saldo: 0 });
			setCtasDistFondos(predistribucion);
			setCtasDist(distros);

			let imgsfond = arrayFondosImg;
			imgsfond.push({ a: false, b: false, c: false, d: false, e: false });
			setArrayFondosImg(imgsfond);
		} else {
			const aSel =
				fondosdist === "2" ? recaudaArr[indexModal].recaudadorA : true;
			recaudaArr[indexModal].recaudadorA = aSel;
			recaudaArr[indexModal].recaudadorB = !aSel;
		}

		setCtasRecaudadores(recaudaArr);

		if (fondosdist === "2") {
			const algunseleccionado = tieneAlgunFondoSeleccionado(
				null,
				null,
				fondosdist
			);
			const seleccionValida = fondosSeleccionadosEnRegla(
				null,
				null,
				fondosdist,
				false
			);
			const rangosSeleccionado =
				mostrarDistribucion(indexModal) && fondosdist === "2"
					? validarInicioResto()
					: true;
			const recaudadorSeleccionado =
				fondosdist === "2"
					? recaudaArr[indexModal].recaudadorA === true ||
					recaudaArr[indexModal].recaudadorB === true
					: true;
			setHabilitarGuardado(
				algunseleccionado &&
				rangosSeleccionado &&
				recaudadorSeleccionado &&
				seleccionValida
			);
		}
	};

	const getElFondoSeleccionado = (arreglo) => {
		let fondo = "X";

		fondosDisponibles.forEach((f) => {
			if (arreglo.hasOwnProperty(`fondo${f}`)) {
				if (arreglo[`fondo${f}`]) {
					fondo = f;
				}
			}
		});

		return fondo;
	};

	const tieneFondoSeleccionado = (fondo, arreglo) => {
		return arreglo.hasOwnProperty(`fondo${fondo}`)
			? arreglo[`fondo${fondo}`]
			: false;
	};

	const manejarValorObjetos = (arr) => {
		let objt = { a: false, b: false, c: false, d: false, e: false };

		if (arr) {
			fondosDisponibles.forEach((f) => {
				if (arr.hasOwnProperty(`fondo${f}`)) {
					objt[f.toLowerCase()] = arr[`fondo${f}`];
				}
			});
		}

		return objt;
	};

	const seleccionadosElegibles = (objt) => {
		if (!elegibles.A && objt.a) return false;

		if (!elegibles.B && objt.b) return false;

		if (!elegibles.C && objt.c) return false;

		if (!elegibles.D && objt.d) return false;

		if (!elegibles.D && objt.e) return false;

		return true;
	};

	const getFondoCambiado = (defaultIndex, index, primaryArray, force) =>
		typeof primaryArray !== "undefined" && primaryArray !== null
			? force
				? primaryArray[defaultIndex]
				: primaryArray[index]
			: null;

	const fondosSeleccionadosEnRegla = (array0, index, fondosdist, force) => {
		index = typeof index !== "undefined" && index !== null ? index : 2;
		const changed = getFondoCambiado(0, index, array0, force);
		const obj1 = manejarValorObjetos(index === 0 ? changed : arrayFondosImg[0]);
		const result1 = seleccionadosElegibles(obj1);

		if (fondosdist === "1") {
			return result1;
		} else {
			const changed2 = getFondoCambiado(1, index, array0, force);
			const obj2 = manejarValorObjetos(
				index === 1 ? changed2 : arrayFondosImg[1]
			);
			const result2 = seleccionadosElegibles(obj2);
			return result1 && result2;
		}
	};

	const tieneAlgunFondoSeleccionado = (array0, index, fondosdist) => {
		index = typeof index !== "undefined" && index !== null ? index : 2;
		const changed =
			typeof array0 !== "undefined" && array0 !== null ? array0[index] : null;
		const obj1 = manejarValorObjetos(index === 0 ? changed : arrayFondosImg[0]);
		const result1 = verSeleccionados(obj1);

		if (fondosdist === "1") {
			return result1;
		} else {
			const obj2 = manejarValorObjetos(
				index === 1 ? changed : arrayFondosImg[1]
			);
			const result2 = verSeleccionados(obj2);
			return result1 && result2;
		}
	};

	const validarInicioResto = () => {
		const ini =
			typeof ctasDist[indexModal].inicio !== "undefined"
				? ctasDist[indexModal].inicio
				: 0;
		const res =
			typeof ctasDist[indexModal].resto !== "undefined"
				? ctasDist[indexModal].resto
				: 0;
		return ini > 0 && ini < 100 && res > 0 && res < 100;
	};

	const redondear = (num) => {
		const up = Math.ceil(num);
		const down = Math.floor(num);
		const mid = num - down;

		return mid >= 0.5 ? up : down;
	};

	const cambiarFondo = (event, fondo, index) => {
		event.preventDefault();
		const fondosdist = radioVal;
		let otroArreglo = index === 0 ? arrayFondosImg[1] : arrayFondosImg[0];

		if (!otroArreglo) {
			otroArreglo = {
				fondoA: false,
				fondoB: false,
				fondoC: false,
				fondoD: false,
				fondoE: false,
			};
		}

		const seleccionado =
			fondosdist === "2" ? tieneFondoSeleccionado(fondo, otroArreglo) : false;
		const elegible = indexModal >= volIndex ? true : fondoElegible(fondo);
		const fondosObj = {
			fondoA: false,
			fondoB: false,
			fondoC: false,
			fondoD: false,
			fondoE: false,
		};
		fondosObj[`fondo${fondo}`] = !seleccionado && elegible;
		let fondosArray = [...arrayFondosImg];
		fondosArray[index] = fondosObj;
		setArrayFondosImg(fondosArray);
		let recaudaArr = ctasRecaudadores;
		const algunseleccionado = tieneAlgunFondoSeleccionado(
			fondosArray,
			index,
			fondosdist
		);
		const seleccionValida =
			indexModal >= volIndex
				? true
				: fondosSeleccionadosEnRegla(fondosArray, index, fondosdist, true);
		const rangosSeleccionado =
			mostrarDistribucion(indexModal) && fondosdist === "2"
				? validarInicioResto()
				: true;
		const recaudadorSeleccionado =
			fondosdist === "2"
				? recaudaArr[indexModal].recaudadorA === true ||
				recaudaArr[indexModal].recaudadorB === true
				: true;
		setHabilitarGuardado(
			algunseleccionado &&
			rangosSeleccionado &&
			recaudadorSeleccionado &&
			seleccionValida
		);
	};

	const porcentaje = (e, step) => {
		const fondosdist = radioVal;

		let ctasDistr = { ...ctasDist };
		if (step === 1) {
			ctasDistr[indexModal].inicio =
				e.target.value === "0" ? 1 : e.target.value;
			ctasDistr[indexModal].resto =
				ctasDistr[indexModal].inicio === ""
					? 99 - ctasDistr[indexModal].inicio
					: 100 - ctasDistr[indexModal].inicio;
			ctasDistr[indexModal].original = false;
		} else {
			ctasDistr[indexModal].resto = e.target.value === "0" ? 1 : e.target.value;
			ctasDistr[indexModal].inicio =
				ctasDistr[indexModal].resto === ""
					? 99 - ctasDistr[indexModal].resto
					: 100 - ctasDistr[indexModal].resto;
			ctasDistr[indexModal].original = false;
		}

		setCtasDist(ctasDistr);

		let distrSalidos = ctasDistFondos;
		distrSalidos[indexModal].distribucion[0].saldo = redondear(
			(parseInt(e.target.value) * distrSalidos[indexModal].saldoTotal) / 100
		);

		if (fondosdist === "2") {
			if (!distrSalidos[indexModal].distribucion.length < 2) {
				const fondosArray = [...arrayFondosImg];
				const fondosObj =
					fondosArray.length < 2
						? {
							fondoA: false,
							fondoB: false,
							fondoC: false,
							fondoD: false,
							fondoE: false,
						}
						: fondosArray[1];

				//fondosArray[indexModal] = fondosObj;
				const fondoSel = getElFondoSeleccionado(fondosObj);

				distrSalidos[indexModal].distribucion[1] = {
					fondo: fondoSel,
					saldo: 0,
				};
			}

			distrSalidos[indexModal].distribucion[1].saldo = redondear(
				(ctasDistr[indexModal].resto * distrSalidos[indexModal].saldoTotal) /
				100
			);
		}
		setCtasDistFondos(distrSalidos);

		let recaudaArr = ctasRecaudadores;

		const algunseleccionado = tieneAlgunFondoSeleccionado(
			null,
			null,
			fondosdist
		);
		const seleccionValida = fondosSeleccionadosEnRegla(
			null,
			null,
			fondosdist,
			false
		);
		const recaudadorSeleccionado =
			fondosdist === "2"
				? recaudaArr[indexModal].recaudadorA === true ||
				recaudaArr[indexModal].recaudadorB === true
				: true;
		const rangosSeleccionado =
			mostrarDistribucion(indexModal) && fondosdist === "2"
				? validarInicioResto()
				: true;
		setHabilitarGuardado(
			algunseleccionado &&
			rangosSeleccionado &&
			recaudadorSeleccionado &&
			seleccionValida
		);

		return (e.target.value * distrSalidos[indexModal].saldoTotal) / 100;
	};

	const fondoRecaudador = (e, data, rec) => {
		setCheckA(rec);
		setCheckB(rec);

		let recaudaArr = ctasRecaudadores;
		const selA = rec === 0;

		recaudaArr[indexModal].recaudadorA = selA;
		recaudaArr[indexModal].recaudadorB = !selA;

		setCtasRecaudadores(recaudaArr);

		e.target.checked =
			rec === 0
				? recaudaArr[indexModal].recaudadorA
				: recaudaArr[indexModal].recaudadorB;

		const fondosdist = radioVal;
		const algunseleccionado = tieneAlgunFondoSeleccionado(
			null,
			null,
			fondosdist
		);
		const seleccionValida = fondosSeleccionadosEnRegla(
			null,
			null,
			fondosdist,
			false
		);
		const recaudadorSeleccionado =
			fondosdist === "2"
				? recaudaArr[indexModal].recaudadorA === true ||
				recaudaArr[indexModal].recaudadorB === true
				: true;

		const rangosSeleccionado =
			mostrarDistribucion(indexModal) && fondosdist === "2"
				? validarInicioResto()
				: true;
		setHabilitarGuardado(
			algunseleccionado &&
			rangosSeleccionado &&
			recaudadorSeleccionado &&
			seleccionValida
		);
	};

	const validarBotonCuentasSeleccionadas = () => {
		const selected = getCuentasSeleccionadas();
		setHayCuentasElegidas(selected.some((s) => s));
	};

	const seleccionarCuentas = (evt, obligatorias) => {
		const checked = evt.currentTarget.checked;

		if (obligatorias) {
			for (let i = 0; i < volIndex; i++) {
				let chk = document.getElementsByName(`form[${i}][cuenta_sel]`)[0];
				chk.checked = checked;
			}
		} else {
			const checked = evt.currentTarget.checked;
			for (let i = volIndex; i < ctasLen; i++) {
				let chk = document.getElementsByName(`form[${i}][cuenta_sel]`)[0];
				chk.checked = checked;
			}
		}

		validarBotonCuentasSeleccionadas();

		validarFondos(true);
	};

	const getCuentasSeleccionadas = () => {
		const element = document.getElementsByName("form[cuentas_obligatorias]")[0];
		let ctasTempSel = ctasSeleccionadas;

		for (let i = 0; i < volIndex; i++) {
			ctasTempSel[i] = element.checked;
		}

		for (let i = volIndex; i < ctasLen; i++) {
			const el = document.getElementsByName(`form[${i}][cuenta_sel]`)[0];
			ctasTempSel[i] = el.checked;
		}

		setCuentasSeleccionadas(ctasTempSel);

		return ctasTempSel;
	};

	const renderHeaderSeccion = (index) => {
		if (index === 0 && ctasObligatorias.length > 0) {
			return (
				<div className="container_fondos">
					<div className="pp">
						<div className="mt-388 d-flex">
							<span>
								<Form.Check
									defaultChecked={true}
									className="checks"
									type="checkbox"
									name={"form[cuentas_obligatorias]"}
									onClick={(evt) => seleccionarCuentas(evt, true)}
								/>
							</span>
							<span>Cuentas Obligatorias</span>
						</div>
					</div>
				</div>
			);
		}
		if (ctasVoluntarias.length > 0 && index === volIndex) {
			return (
				<div className="container_fondos">
					<div className="pp">
						<div className="mt-388 d-flex">
							<span>
								<Form.Check
									defaultChecked={true}
									type="checkbox"
									name={`form[cuentas_voluntarias]`}
									onClick={(evt) => seleccionarCuentas(evt, false)}
								/>
							</span>
							<span>Cuentas Voluntarias</span>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};

	const marcarGrupoCuentas = (nombre, selected, different) => {
		let parent = document.getElementsByName(nombre)[0];
		parent.indeterminate = different;
		if (!different) parent.checked = selected;
	};

	const selectCuentaMutua = (evt, index, tipo) => {
		const selected = evt.nativeEvent.target.checked;

		if (index < volIndex) {
			evt.nativeEvent.target.checked = !selected;
			return;
		}

		if (cuentasVoluntarias.includes(tipo)) {
			response.response.cuentas.forEach((c, i) => {
				if (i !== index && cuentasVoluntarias.includes(c.tipo)) {
					let e = document.getElementsByName(`form[${i}][cuenta_sel]`)[0];
				}
			});
		}

		if (index < volIndex) {
			let different = false;
			for (let i = 0; i < volIndex; i++) {
				if (i !== index) {
					let e = document.getElementsByName(`form[${i}][cuenta_sel]`)[0];
					different = selected !== e.checked;
					if (different) break;
				}
			}

			marcarGrupoCuentas("form[cuentas_obligatorias]", selected, different);
		}

		if (index >= volIndex) {
			let different = false;
			for (let i = volIndex; i < ctasLen; i++) {
				if (i !== index) {
					let e = document.getElementsByName(`form[${i}][cuenta_sel]`)[0];
					different = selected !== e.checked;
					if (different) break;
				}
			}

			marcarGrupoCuentas("form[cuentas_voluntarias]", selected, different);
		}

		validarBotonCuentasSeleccionadas();

		validarFondos(true);
	};

	const obtenerDistribucionInicial = (i) => {
		let fds = fondosInicial[i];
		const total = fds.reduce(
			(a, b) => parseInt(a) + (parseInt(b["saldo"]) || 0),
			0
		);
		return fds.map((f) => {
			return {
				nombre: f.nombre,
				saldo: f.saldo,
				porct: (parseInt(f.saldo) / total) * 100,
			};
		});
	};

	const mostrarDistribucion = (i) => {
		if (permiteDistribucionObligatorias) return true;
		else return !cuentasObligatorias.includes(cuentasModal[i].tipo);
	};

	const getUrlImagenFondo = (fondo, seleccionado, habilitado, indexCuenta) => {
		const suffix = seleccionado
			? "-color"
			: indexCuenta >= volIndex
				? ""
				: habilitado
					? ""
					: "-des";
		return `./imgs/${fondo.toLowerCase()}${suffix}.png`;
	};

	const repairFondo = (fondo) => {
		if (arrayFondosImg[1]) return arrayFondosImg[1][`fondo${fondo}`];
		else return false;
	};

	const enviarDatos = async () => {
		OnLoading(true);

		response.response.cuentas.forEach((cuenta, icuenta) => {
			if (typeof cuenta.porcentajeTraspaso === "undefined") {
				cuenta.tipoTraspaso = "total";
				cuenta.porcentajeTraspaso = 100;
			}

			if (typeof mandatoGlobal[icuenta] !== "undefined") {
				cuenta.suscribeMandato = mandatoGlobal[icuenta].validarMandato;
			} else {
				cuenta.suscribeMandato = false;
			}

			cuenta.saldoRegimen =
				typeof regimenesGlobal[icuenta] !== "undefined"
					? regimenesGlobal[icuenta].saldo
					: 0;

			cuenta.fondos.forEach((fondo) => {
				if (typeof fondo.porcentaje === "undefined") {
					fondo.porcentaje = 100;
				}

				if (typeof fondo.valorPorcentaje === "undefined")
					fondo.valorPorcentaje = fondo.saldo;

				if (typeof fondo.fondoRecaudador === "undefined")
					fondo.fondoRecaudador = false;

				if (
					typeof regimenesGlobal[icuenta] !== "undefined" &&
					cuenta.suscribeMandato
				) {
					fondo.regimenes.forEach((regimen) => {
						if (regimenesGlobal[icuenta].mandato) {
							regimen.valorCuota = regimenesGlobal[icuenta].saldo;
						}
					});
				}
			});
		});

		const seleccionadas = getCuentasSeleccionadas();
		let cuentasEdit = [];

		for (let i = 0; i < ctasLen; i++) {
			if (seleccionadas[i] === true) {
				cuentasEdit.push(props.location.state.json.response.cuentas[i]);
			}
		}

		let afiliadoTemp = response.response.afiliado;

		let empleadoresTemp = response.response.empleadores;
		let arrayEmpleadores = [];
		if (empleadoresTemp != null && empleadoresTemp.length > 0) {
			empleadoresTemp.forEach((empleado, i) => {
				let empl = {
					cuentaCorrelativoComuna: 1,
					Direccion: empleado.direccion,
					RazonSocial: empleado.razonSocial,
					Rut: empleado.rutNumero + "-" + empleado.rutDv,
					Telefono: empleado.telefonoCelular,
					Email: empleado.email,
				};
				arrayEmpleadores.push(empl);
			});
		}

		let datosPersonaalesTemp = {
			cuentaCorrelativoProspecto: 0,
			Nombre: afiliadoTemp.nombres,
			apellidoPaterno: afiliadoTemp.apellidoPaterno,
			apellidoMaterno: afiliadoTemp.apellidoMaterno,
			Rut: afiliadoTemp.rutNumero + "-" + afiliadoTemp.rutDv,
			FechaNacimiento: afiliadoTemp.fechaNacimiento,
			nacionalidad: "CL",
			Email: afiliadoTemp.email,
			Telefono: afiliadoTemp.telefonoCelular,
			Direccion: afiliadoTemp.direccion,
			Comuna: afiliadoTemp.codigoUnicoTerritorial,
			Cartola: false,
			Sexo: afiliadoTemp.genero,
			TipoEmpleado: afiliadoTemp.tipo,
			Empleadores: arrayEmpleadores,
		};

		var data = Crypto.Decrypt("data");

		let responseTemp = {
			response: {
				solicitud: data.solicitud,
				token: response.response.token,
				ticketId: response.response.ticket,
				cuentasEditadas: cuentasEdit,
				datosPersonales: datosPersonaalesTemp,
			},
		};
		setResponseFinal(responseTemp);

		let databack = JSON.parse(localStorage.getItem("intis"));
		databack.location.state.json.response.cuentas = cuentasEdit;
		localStorage.setItem("intis", JSON.stringify({ ...databack }));

		var rut = props.location.state.json.response.rut;
		await fetch(`${configuration.API_URL}/api/log/cuentas/${rut}/${data.autenticacion}`,
			{
				method: "POST",
				headers: { "Content-Type": "text/plain" },
				body: JSON.stringify(responseTemp),
			}
		).catch((error) => {
			console.error(error);
		});

		setValidateResponseFinal(true);
	};

	const [cuentacheck, setcuentadoscheck] = useState({
		apvCheck: false,
		cuentaDosCheck: false,
	});

	const onChangeValue = (event, nameValue, index) => {
		let Monto;
		let val;

		if (event.target.name === "saldo") {
			const inival = event.target.value.replace(/[^0-9\.]+/g, "");
			if (inival !== "") {
				val = parseInt(inival.replace(/[^0-9\.]+/g, ""));
				Monto = val;
			} else {
				Monto = null;
				val = "";
			}
			event.target.value = val;
		}

		let regimenObjTemp = regimenesGlobal[index];
		regimenObjTemp.saldo = event.target.value;
	};

	const onChangeValueCTA2 = (event, cta, index) => {
		const inival = event.target.value.replace(/[^0-9\.]+/g, "");
		event.target.value =
			inival !== "" ? parseInt(inival.replace(/[^0-9\.]+/g, "")) : "";
	};

	const checkCuenta = (cuenta, index) => {
		mandatoGlobal[index] = { validarMandato: cuentacheck.apvCheck };
		if (cuenta === "APV") {
			if (cuentacheck.apvCheck) {
				setcuentadoscheck({
					...cuentacheck,
					index: index,
					apvCheck: false,
				});

				regimenesGlobal[index] = { mandato: false, saldo: 0 };
			} else {
				setcuentadoscheck({
					...cuentacheck,
					index: index,
					apvCheck: true,
				});
				regimenesGlobal[index] = { mandato: true, saldo: 0 };
			}
		} else {
			if (cuentacheck.cuentaDosCheck) {
				setcuentadoscheck({
					...cuentacheck,
					index: index,
					cuentaDosCheck: false,
				});
				regimenesGlobal[index] = { mandato: false, saldo: 0 };
			} else {
				setcuentadoscheck({
					...cuentacheck,
					index: index,
					cuentaDosCheck: true,
				});
				regimenesGlobal[index] = { mandato: true, saldo: 0 };
				response.response.cuentas[index].suscribeMandato = true;
			}
		}
	};

	const [fondoValido, setfondoValido] = useState(false);

	useEffect(() => {
		var response = validarFondos(false);

		if (!response.elegible) {
			Swal.fire({
				icon: "warning",
				text: response.mensaje,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	}, []);

	const validarFondos = (first) => {
		let cuentas = [];
		if (first) {
			const seleccionadas = getCuentasSeleccionadas();
			for (let i = 0; i < ctasLen; i++) {
				if (seleccionadas[i] === true) {
					cuentas.push(props.location.state.json.response.cuentas[i]);
				}
			}
		} else {
			cuentas = props.location.state.json.response.cuentas;
		}

		var response = {};
		response.elegible = true;

		cuentas.forEach((cuenta) => {
			cuenta.fondos.forEach((fondo) => {
				var fondoelegible = fondoElegible(fondo.nombre);
				if (!fondoelegible) {
					if (fondo.nombre === "A") {
						response.mensaje =
							"Según la normativa vigente, por tu seguridad no cumples con los requisitos de edad para seleccionar un fondo riesgoso. Elige entre los fondos B, C, D y E";
					} else if (fondo.nombre === "B") {
						response.mensaje =
							"Según la normativa vigente, por tu seguridad no cumples con los requisitos de edad para seleccionar un fondo riesgoso. Elige entre los fondos C, D y E";
					}
					response.elegible = fondoelegible;
					setfondoValido(fondoelegible);
				}
			});
		});
		setfondoValido(response.elegible);

		return response;
	};

	/*useEffect(async () => {
		var data = Crypto.Decrypt("data");		

		var result = await sendRequest({
			url: `saldos/${afiliado.dni}`,
			method: "GET",
			body: null
		});

	}, []);*/

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	if (render) {
		if (validateResponseFinal)
			return (
				<Redirect
					to={{
						pathname: "/misdatos",
						state: {
							json: responseFinal,
							jsonBack: JSON.parse(localStorage.getItem("intis")).location.state
								.json,
						},
					}}
				/>
			);

		return (
			<div>
				<ValidarData />
				<CabeceraPasos paso="1" />
				<Form>
					<div>
						{response.response.cuentas.map((cuenta, indexc) => (
							<div
								className={
									cuenta.tipo === "CCICO" ||
										cuenta.tipo === "CAI" ||
										cuenta.tipo === "CCIAV"
										? "OBLIGATORIA"
										: "VOLUNTARIA"
								}
							>
								{renderHeaderSeccion(indexc)}

								<div key={indexc}>
									<div
										className="container_fondos"
										style={{ marginTop: "15px" }}
									>
										<Card className="media_grey_c" style={{ padding: "15px" }}>
											<input
												type="hidden"
												value={indexc}
												name={`form[cuentas][${indexc}][id]`}
											/>
											<div>
												<div className="d-flex justify-content-start">
													<Form.Check
														defaultChecked={true}
														type={
															cuenta.tipo === "CCICO" ||
																cuenta.tipo === "CAI" ||
																cuenta.tipo === "CCIAV"
																? "hidden"
																: "checkbox"
														}
														name={`form[${indexc}][cuenta_sel]`}
														onClick={(evt) =>
															selectCuentaMutua(evt, indexc, cuenta.tipo)
														}
													/>
													{volIndex <= indexc ? (
														<h5 className="mt-388">
															{getTipoCuenta(cuenta.tipo)}
														</h5>
													) : (
														<h5
															className="mt-388"
															style={{ marginLeft: "-9px" }}
														>
															{getTipoCuenta(cuenta.tipo)}
														</h5>
													)}
												</div>

												<h6 className="mt-366">{cuenta.Descripcion}</h6>
												<div className="grayy_liness">
													<h6 className="mt-366_">
														<strong>Origen : {cuenta.origen}</strong>
														<input
															type="hidden"
															value={cuenta.origen}
															name={`form[${indexc}][origen]`}
														/>
														<br />
													</h6>
													<div className="cuentas_">
														<div className="info__">
															Fondo origen{" "}
															<Tooltips
																texto={`Fondos de origen: Es el fondo en donde se invierten tus ahorros en tu AFP actual.`}
															/>
															<p style={{ fontSize: "13px" }}>Saldo total : </p>
															<strong> ${formatDecimal(cuenta.saldo)}</strong>
														</div>

														<div className="info_22">
															{fondosInicial[indexc].map((fondo, indexf) => (
																<div key={indexf}>
																	<img
																		src={`./imgs/${fondo.origen}.png`}
																		width="39"
																		height="39"
																		alt=""
																	/>
																</div>
															))}
															{/* <img src="./imgs/A-fondo.png"  alt=""/> <img src="./imgs/B-fondo.png" alt=""/>  */}
														</div>
													</div>
												</div>
												<div className="line_">
													<div className="line_content">
														<img src="./imgs/line_d.svg" alt="" />
													</div>
													<div className="line_content2">
														<img src="./imgs/lineh.svg" alt="" />
													</div>
												</div>
												<div className="green_lines">
													<h6 className="mt-366_">
														<strong>Destino : AFP Modelo</strong>
													</h6>
													<br />
													<div className="cuentas_22">
														<div className="info_">
															<p>
																Fondo de destino{" "}
																<Tooltips
																	texto={`Fondos de destino: Es el fondo en donde quedarán tus ahorros en AFP Modelo`}
																/>
															</p>
															{cuenta.porcentajeTraspaso > 0 ? (
																indexc >= volIndex &&
																	cuenta.tipo !== "CCICO" &&
																	cuenta.tipo !== "CAI" &&
																	cuenta.tipo !== "CCIAV" ? (
																	<p style={{ fontSize: "13px" }}>
																		Traspaso parcial:{cuenta.porcentajeTraspaso}
																		%
																	</p>
																) : null
															) : null}
														</div>

														<div className="info_2">
															{cuenta.fondos.map((fondo, indexf) => (
																<div key={indexf}>
																	<img
																		src={`./imgs/${fondo.nombre}-color.png`}
																		width="39"
																		height="39"
																		alt=""
																	/>
																	<input
																		type="hidden"
																		value={fondo.nombre}
																		name={`form_fondos_${indexf}`}
																	/>
																</div>
															))}
															{/* <img src="./imgs/a.svg" width="30" height="30" alt=""/> <img src="./imgs/b.svg" width="30" height="30" alt=""/>  */}
														</div>
													</div>
													<div className="container text-center">
														<Button
															id={`B-TW-0006-${indexc + 1}`}
															onClick={() => handleShow(indexc)}
															className="shadoww link-bnt"
															variant="link"
															style={{
																background: "white",
																borderRadius: "25px",
																border: "2px solid #0B7AC7",
																color: "#0B7AC7",
																boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
															}}
														>
															Editar fondo de destino
														</Button>
														<Modal
															show={show && indexModal === indexc}
															onHide={handleClose}
															animation={false}
															className="text-center"
															backdrop="static"
														>
															<Modal.Body>
																<div className="container">
																	<div className="row">
																		<div>
																			<div className="container">
																				<div className="row">
																					<div className="col-lg-11 col-md-10 col-sm-11 col-10">
																						<h5 className="mt-38">
																							{getTipoCuenta(
																								cuentasModal[indexModal].tipo
																							)}
																						</h5>
																					</div>
																					<div className="col-lg-1 col-md-2 col-sm-1 col-2">
																						<button
																							type="button"
																							className="close"
																							onClick={handleClose}
																						>
																							<span aria-hidden="true">×</span>
																							<span className="sr-only">
																								Close alert
																							</span>
																						</button>
																					</div>
																				</div>
																			</div>
																			<br />
																			{cuenta.tipo !== "CCICO" &&
																				cuenta.tipo !== "CAI" &&
																				cuenta.tipo !== "CCIAV" ? (
																				<>
																					<div
																						style={{
																							width: "50%",
																							textAlign: "left",
																						}}
																					>
																						<strong>Tipo de Traspaso</strong>
																						<br />
																						<br />
																						<Form.Control
																							as="select"
																							value={cuentaModal.tipoTraspaso}
																							onChange={(e) =>
																								setTraspaso({
																									...traspaso,
																									tipo: e.target.value,
																								})
																							}
																						>
																							<option value="total">
																								{" "}
																								Traspaso Total
																							</option>
																							<option value="parcial">
																								Traspaso Parcial
																							</option>
																						</Form.Control>
																					</div>
																					<br />
																					{traspaso.tipo !== "total" ? (
																						<>
																							<p>
																								Elige el porcentaje que quieres
																								traspasar a las cuentas de AFP
																								Modelo
																							</p>
																							<br />

																							<FormControl
																								type="number"
																								style={{ appearance: "none" }}
																								name="valorA"
																								value={traspaso.valorA}
																								onBlur={(e) => {
																									if (e.target.value === "") {
																										e.target.value = 1;
																									}
																								}}
																								onChange={(e) => {
																									const re = /^[0-9\b]+$/;
																									if (
																										(e.target.value === "" ||
																											re.test(
																												e.target.value
																											)) &&
																										e.target.value.length < 3
																									) {
																										setTraspaso({
																											...traspaso,
																											valorA:
																												e.target.value === "0"
																													? 1
																													: e.target.value,
																										});
																									}
																								}}
																							/>
																							<hr />
																							<br />
																						</>
																					) : null}
																				</>
																			) : null}
																			{fondosInicial[indexModal].length > 1 ? (
																				<p
																					style={{
																						fontSize: "14px",
																						textAlign: "left",
																					}}
																				>
																					Elige el fondo y el porcentaje de tu
																					cuenta, donde deseas que se
																					administren tus ahorros después del
																					cambio.
																				</p>
																			) : (
																				<p
																					style={{
																						fontSize: "14px",
																						textAlign: "left",
																					}}
																				>
																					Elige el fondo donde deseas que se
																					administren tus ahorros después del
																					cambio.
																				</p>
																			)}
																			<hr class="my-4" />
																			<strong>
																				Saldo total $
																				{formatDecimal(
																					cuentasModal[indexModal].saldo
																				)}
																			</strong>
																			<br />
																		</div>
																		{(() => {
																			if (indexModal === indexc)
																				obtenerDistribucionInicial(indexModal);

																			if (
																				fondosInicial[indexModal].length > 1 ||
																				indexc >= volIndex
																			) {
																				return (
																					<div className="col-12">
																						<div
																							style={{
																								display: "flex",
																								justifyContent: "space-between",
																							}}
																						>
																							<div className="fondo_uno">
																								<input
																									type="radio"
																									value="1"
																									defaultChecked={
																										radioVal === "1"
																									}
																									name="fondo"
																									onChange={(event) =>
																										radiosClick(event)
																									}
																								/>{" "}
																								Un fondo
																							</div>
																							<div
																								className="fondo_uno"
																								style={{ paddingLeft: "11px" }}
																							>
																								<input
																									type="radio"
																									value="2"
																									defaultChecked={
																										radioVal === "2"
																									}
																									name="fondo"
																									onChange={(event) =>
																										radiosClick(event)
																									}
																								/>{" "}
																								Dos fondos
																							</div>
																						</div>
																					</div>
																				);
																			} else return null;
																		})()}
																		{(() => {
																			if (arrayFondosImg.length) {
																				return (
																					<div
																						className="modal-content"
																						style={{ border: "0px" }}
																					>
																						<div
																							style={{
																								display: "flex",
																								justifyContent: "space-around",
																							}}
																						>
																							<span
																								className={`fondo_ ${indexc >= volIndex
																									? "pointer"
																									: elegibles.A
																										? "pointer"
																										: ""
																									}`}
																								onClick={(event) =>
																									cambiarFondo(event, "A", 0)
																								}
																							>
																								<img
																									src={getUrlImagenFondo(
																										"A",
																										arrayFondosImg[0].fondoA,
																										elegibles.A,
																										indexc
																									)}
																									width="39"
																									height="39"
																									alt=""
																								/>
																								<br />
																								<span className="estado">
																									Más riesgoso
																								</span>
																							</span>
																							<span
																								className={`fondo_ ${indexc >= volIndex
																									? "pointer"
																									: elegibles.B
																										? "pointer"
																										: ""
																									}`}
																								onClick={(event) =>
																									cambiarFondo(event, "B", 0)
																								}
																							>
																								<img
																									src={getUrlImagenFondo(
																										"B",
																										arrayFondosImg[0].fondoB,
																										elegibles.B,
																										indexc
																									)}
																									width="39"
																									height="39"
																									alt=""
																								/>
																								<br />
																								<span className="estado">
																									Riesgoso
																								</span>
																							</span>
																							<span
																								className={`fondo_ ${indexc >= volIndex
																									? "pointer"
																									: elegibles.C
																										? "pointer"
																										: ""
																									}`}
																								onClick={(event) =>
																									cambiarFondo(event, "C", 0)
																								}
																							>
																								<img
																									src={getUrlImagenFondo(
																										"C",
																										arrayFondosImg[0].fondoC,
																										elegibles.C,
																										indexc
																									)}
																									width="39"
																									height="39"
																									alt=""
																								/>
																								<br />
																								<span className="estado">
																									Intermedio
																								</span>
																							</span>
																							<span
																								className={`fondo_ ${indexc >= volIndex
																									? "pointer"
																									: elegibles.D
																										? "pointer"
																										: ""
																									}`}
																								onClick={(event) =>
																									cambiarFondo(event, "D", 0)
																								}
																							>
																								<img
																									src={getUrlImagenFondo(
																										"D",
																										arrayFondosImg[0].fondoD,
																										elegibles.D,
																										indexc
																									)}
																									width="39"
																									height="39"
																									alt=""
																								/>
																								<br />
																								<span className="estado">
																									Conservador
																								</span>
																							</span>
																							<span
																								className={`fondo_ ${indexc >= volIndex
																									? "pointer"
																									: elegibles.E
																										? "pointer"
																										: ""
																									}`}
																								onClick={(event) =>
																									cambiarFondo(event, "E", 0)
																								}
																							>
																								<img
																									src={getUrlImagenFondo(
																										"E",
																										arrayFondosImg[0].fondoE,
																										elegibles.E,
																										indexc
																									)}
																									width="39"
																									height="39"
																									alt=""
																								/>
																								<br />
																								<span className="estado">
																									Más conservador
																								</span>
																							</span>
																						</div>
																						{radioVal === "2" ? (
																							<div>
																								<div>
																									{(() => {
																										if (
																											mostrarDistribucion(
																												indexModal
																											)
																										) {
																											return (
																												<InputGroup
																													style={{
																														padding: "5px",
																														width: "105px",
																														borderRadius: "5px",
																														color: "#0B7AC7",
																														marginLeft: "7px",
																													}}
																													className="fondo_uno"
																												>
																													<FormControl
																														type="number"
																														//defaultValue={ctasDist[indexModal].inicio}
																														value={
																															ctasDist[
																																indexModal
																															].inicio
																														}
																														aria-label="Recipient's username"
																														aria-describedby="basic-addon2"
																														onBlur={(e) => {
																															if (
																																e.target
																																	.value === ""
																															) {
																																e.target.value = 1;
																															}
																														}}
																														onChange={(e) => {
																															const re =
																																/^[0-9\b]+$/;
																															if (
																																(e.target
																																	.value ===
																																	"" ||
																																	re.test(
																																		e.target
																																			.value
																																	)) &&
																																e.target.value
																																	.length < 3
																															) {
																																porcentaje(
																																	e,
																																	1
																																);
																															}
																														}}
																													/>
																													<InputGroup.Append>
																														<InputGroup.Text id="basic-addon2">
																															%
																														</InputGroup.Text>
																													</InputGroup.Append>
																												</InputGroup>
																											);
																										}
																										return <div></div>;
																									})()}
																									<div>
																										<div className="fondo_dos">
																											<input
																												type="radio"
																												onChange={(event) =>
																													fondoRecaudador(
																														event,
																														fondosInicial[
																														indexModal
																														],
																														0
																													)
																												}
																												name="recaudador"
																												defaultChecked={
																													ctasRecaudadores[
																														indexModal
																													].recaudadorA
																												}
																											/>
																											<label>
																												<strong>
																													{" "}
																													Fondo recaudador
																												</strong>{" "}
																												<Tooltips
																													texto={`Fondo recaudador: Es el fondo de destino donde llegarán las siguientes cotizaciones.`}
																												/>
																											</label>
																										</div>
																									</div>
																								</div>
																								{ }
																								<div
																									style={{
																										position: "relative",
																										float: "left",
																										width: "100%",
																									}}
																								>
																									<span
																										className={`fondo_ ${indexc >= volIndex
																											? "pointer"
																											: elegibles.A
																												? "pointer"
																												: ""
																											}`}
																										onClick={(event) =>
																											cambiarFondo(
																												event,
																												"A",
																												1
																											)
																										}
																									>
																										<img
																											src={getUrlImagenFondo(
																												"A",
																												repairFondo("A"),
																												elegibles.A,
																												indexc
																											)}
																											width="39"
																											height="39"
																											alt=""
																										/>
																										<br />
																										<span className="estado">
																											Más riesgoso
																										</span>
																									</span>
																									<span
																										className={`fondo_ ${indexc >= volIndex
																											? "pointer"
																											: elegibles.B
																												? "pointer"
																												: ""
																											}`}
																										onClick={(event) =>
																											cambiarFondo(
																												event,
																												"B",
																												1
																											)
																										}
																									>
																										<img
																											src={getUrlImagenFondo(
																												"B",
																												repairFondo("B"),
																												elegibles.B,
																												indexc
																											)}
																											width="39"
																											height="39"
																											alt=""
																										/>
																										<br />
																										<span className="estado">
																											Riesgoso
																										</span>
																									</span>
																									<span
																										className={`fondo_ ${indexc >= volIndex
																											? "pointer"
																											: elegibles.C
																												? "pointer"
																												: ""
																											}`}
																										onClick={(event) =>
																											cambiarFondo(
																												event,
																												"C",
																												1
																											)
																										}
																									>
																										<img
																											src={getUrlImagenFondo(
																												"C",
																												repairFondo("C"),
																												elegibles.C,
																												indexc
																											)}
																											width="39"
																											height="39"
																											alt=""
																										/>
																										<br />
																										<span className="estado">
																											Intermedio
																										</span>
																									</span>
																									<span
																										className={`fondo_ ${indexc >= volIndex
																											? "pointer"
																											: elegibles.D
																												? "pointer"
																												: ""
																											}`}
																										onClick={(event) =>
																											cambiarFondo(
																												event,
																												"D",
																												1
																											)
																										}
																									>
																										<img
																											src={getUrlImagenFondo(
																												"D",
																												repairFondo("D"),
																												elegibles.D,
																												indexc
																											)}
																											width="39"
																											height="39"
																											alt=""
																										/>
																										<br />
																										<span className="estado">
																											Conservador
																										</span>
																									</span>
																									<span
																										className={`fondo_ ${indexc >= volIndex
																											? "pointer"
																											: elegibles.E
																												? "pointer"
																												: ""
																											}`}
																										onClick={(event) =>
																											cambiarFondo(
																												event,
																												"E",
																												1
																											)
																										}
																									>
																										<img
																											src={getUrlImagenFondo(
																												"E",
																												repairFondo("E"),
																												elegibles.E,
																												indexc
																											)}
																											width="39"
																											height="39"
																											alt=""
																										/>
																										<br />
																										<span className="estado">
																											Más conservador
																										</span>
																									</span>
																								</div>
																								<div className="flex">
																									{(() => {
																										if (
																											mostrarDistribucion(
																												indexModal
																											)
																										) {
																											return (
																												<InputGroup
																													style={{
																														padding: "5px",
																														width: "105px",
																														borderRadius: "5px",
																														color: "#0B7AC7",
																													}}
																													className="fondo_uno"
																												>
																													<FormControl
																														type="number"
																														value={
																															ctasDist[
																																indexModal
																															].resto
																														}
																														aria-label="Recipient's username"
																														aria-describedby="basic-addon2"
																														onBlur={(e) => {
																															if (
																																e.target
																																	.value === ""
																															) {
																																e.target.value = 1;
																															}
																														}}
																														onChange={(e) => {
																															const re =
																																/^[0-9\b]+$/;
																															if (
																																(e.target
																																	.value ===
																																	"" ||
																																	re.test(
																																		e.target
																																			.value
																																	)) &&
																																e.target.value
																																	.length < 3
																															) {
																																porcentaje(
																																	e,
																																	2
																																);
																															}
																														}}
																													/>
																													<InputGroup.Append>
																														<InputGroup.Text id="basic-addon2">
																															%
																														</InputGroup.Text>
																													</InputGroup.Append>
																												</InputGroup>
																											);
																										}
																										return <div></div>;
																									})()}
																									<div className="fondo_uno">
																										<input
																											type="radio"
																											onChange={(event) =>
																												fondoRecaudador(
																													event,
																													fondosInicial[
																													indexModal
																													],
																													1
																												)
																											}
																											name="recaudador"
																											defaultChecked={
																												ctasRecaudadores[
																													indexModal
																												].recaudadorB
																											}
																										/>
																										<label>
																											<strong>
																												{" "}
																												Fondo recaudador
																											</strong>
																										</label>
																									</div>
																								</div>
																							</div>
																						) : null}
																					</div>
																				);
																			}
																		})()}
																		<LimiteFondos
																			nombre={nombreafiliado}
																			fondos={elegibles}
																		/>
																		{!habilitarGuardado ? (
																			<div className="container_fondos">
																				<div
																					className="media m-3 p-3 rounded-lg"
																					style={{
																						backgroundColor:
																							"rgba(243, 243, 243, 0.5)",
																					}}
																				>
																					<img
																						src="./imgs/danger.svg"
																						className="mr-3"
																						alt=""
																					/>
																					<div className="media-body text-muted">
																						<p>
																							{radioVal !== "2"
																								? "Selecciona un fondo recaudador"
																								: checkB === 3 || checkA === 3
																									? "Selecciona los dos fondos de destino y fondo recaudador"
																									: "Selecciona los dos fondos de destino"}
																						</p>
																					</div>
																				</div>
																			</div>
																		) : null}
																		<div
																			style={{
																				display: "flex",
																				flexDirection: "row",
																				justifyContent: "space-between",
																				alignItems: "baseline",
																			}}
																			className="col-12"
																		>
																			<div className="container">
																				<div className="row">
																					<div className="col-12 d-flex justify-content-center">
																						<Button
																							style={{
																								background: "#82388B",
																								borderRadius: "25px",
																								border: "0",
																							}}
																							variant="primary"
																							disabled={!habilitarGuardado}
																							onClick={(event) =>
																								formModalFondosGuardar(event)
																							}
																							block
																						>
																							Guardar
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</Modal.Body>
														</Modal>
													</div>
												</div>
											</div>
											{cuenta.tipo === "APV" ? (
												<div>
													<div className="container pt-3 pb-3 pl-0">
														<div className="row">
															<div className="col-10 col-md-10">
																<p className="titulos__">
																	Quiero suscribir un mandato de descuento para
																	mi Cuenta Voluntaria con mi empleador
																</p>
															</div>
															<div className="col-2 col-md-2 box_box">
																<Switch
																	name="checkedA"
																	inputProps={{
																		"aria-label": "primary checkbox",
																	}}
																	onChange={(e) =>
																		checkCuenta(cuenta.tipo, indexc)
																	}
																/>
															</div>
														</div>
													</div>
													{cuentacheck.apvCheck ? (
														<div>
															<div className="container pl-0 ml-0">
																<div className="row">
																	<div className="col-12 col-md-6">
																		<p className="titulos__">
																			<strong>
																				Monto de descuento mensual($)
																			</strong>
																		</p>
																	</div>
																	<div className="col-12 col-md-6_">
																		<input
																			name="saldo"
																			style={{
																				borderColor: "#ccc",
																				borderRadius: ".25rem",
																				padding: ".375rem .75rem",
																				border: "1px solid #ccc",
																				width: "300px",
																			}}
																			placeholder="Ingrese el monto en pesos: $10.000"
																			onChange={(event) =>
																				onChangeValue(
																					event,
																					cuenta.tipo,
																					indexc
																				)
																			}
																			type="text"
																		/>
																	</div>
																</div>
															</div>
														</div>
													) : (
														""
													)}
												</div>
											) : (
												""
											)}
											{cuenta.tipo === "CTA2" ? (
												<div className="d-flex justify-content-between">
													<div className="container pt-3 pb-3 pl-0">
														<div className="row">
															<div className="col-10">
																<p className="titulos__">
																	Quiero suscribir un mandato de descuento para
																	mi Cuenta Voluntaria con mi empleador
																</p>
															</div>
															<div className="col-2 box_box">
																<Switch
																	name="checkedA"
																	inputProps={{
																		"aria-label": "primary checkbox",
																	}}
																	onChange={(e) =>
																		checkCuenta(cuenta.tipo, indexc)
																	}
																/>
															</div>
														</div>
														{cuentacheck.cuentaDosCheck ? (
															<div className="container pl-0 ml-0 mt-3">
																<div className="row">
																	<div
																		className="col-12 col-md-6"
																		style={{ fontSize: 16 }}
																	>
																		<p className="titulos__">
																			<strong>
																				Monto de descuento mensual($)
																			</strong>
																		</p>
																	</div>
																	<div className="col-12 col-md-6_">
																		<input
																			name="monto"
																			style={{
																				borderColor: "#ccc",
																				borderRadius: ".25rem",
																				padding: ".375rem .75rem",
																				border: "1px solid #ccc",
																				width: "300px",
																			}}
																			placeholder="Ingrese el monto en pesos: $10.000"
																			onChange={(e) =>
																				onChangeValueCTA2(
																					e,
																					cuenta.tipo,
																					indexc
																				)
																			}
																			type="text"
																		/>
																	</div>
																</div>
															</div>
														) : (
															""
														)}
													</div>
												</div>
											) : (
												""
											)}
										</Card>
									</div>
								</div>
							</div>
						))}
					</div>
				</Form>
				{volIndex !== response.response.cuentas.length ? (
					<div className="container_fondos">
						<div
							className="media m-3 p-3 rounded-lg"
							style={{ backgroundColor: "rgba(243, 243, 243, 0.5)" }}
						>
							<img src="./imgs/danger.svg" className="mr-3" alt="" />
							<div className="media-body text-muted">
								<p>
									Los mandatos de descuento de ahorro voluntario con tu
									empleador serán revocados automáticamente, podrás suscribir
									uno nuevo en la sección empleadores.
								</p>
							</div>
						</div>
					</div>
				) : null}
				<div className="container_fondos">
					<div className="btn_card_ dd">
						<Button
							id="B-TW-0007"
							onClick={enviarDatos}
							className="btn primary"
							block
							disabled={!hayCuentasElegidas || !fondoValido}
						>
							Continuar
						</Button>
						<SabiasQueFondo />
						<IdleTimerContainer isLoggedIn={true} />
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
}
