
import React, { useEffect, useState } from 'react';

export default function Title(props) {
    const [title, setTitle] = useState(props.title);

    useEffect(() => {
        setTitle(props.title);
    }, [props]);

    return (
        <div className="row">
            <div className="col-12">
                <strong>{title}</strong>
            </div>
        </div>
    );
}
