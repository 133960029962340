import ValorReferencia from './ValorReferencia';
import Campo from './Campo';
import Genericas from './Genericas';

class Regla {

    constructor(id, nombre, mensaje, funcValidar, exito, fracaso) {
        this.id = id;
        this.nombre = nombre;
        this.mensaje = mensaje;
        this.valida = false;
        this.campos = [];
        this.valoresReferencia = [];
        this.funcValidar = funcValidar;
        this.abortar = []; // devuelve verdadero si una de las reglas 
        this.exito = exito;
        this.fracaso = fracaso;
        this.validacion = null;
    }

    abortarValidacion(forzar) {
        if (forzar) return false;
        if (this.abortar) {
            if (this.abortar.length > 0)
                return (this.abortar.some(a => !a.valida));
            return false;
        }
        return false;
    }

    validar(forzar) {
        this.valida = false

        if (this.abortarValidacion(forzar))
            this.valida = true;
        else {
            if (this.funcValidar)
                this.valida = typeof this.funcValidar === 'string' ? Genericas.validar(this.funcValidar, this.campos) : this.funcValidar(this);
            else
                this.valida = false;
        }
    }

    despues = () => {
        if (this.exito && this.valida) this.exito(this);
        if (this.fracaso && !this.valida) this.fracaso(this);
    }

    agregarReglaAborto = (regla) => this.abortar.push(regla);

    agregarCampo = (nombre, etiqueta, valor) => this.campos.push(new Campo(nombre, etiqueta, valor));

    agregarValorDeReferencia = (nombre, etiqueta, valor) => this.valoresReferencia.push(new ValorReferencia(nombre, etiqueta, valor));

    buscarCampo = (nombre) => this.campos.find(c => c.nombre === nombre);

    buscarValorReferencia = (nombre) => this.valoresReferencia.find(c => c.nombre === nombre);
}

export default Regla