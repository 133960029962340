import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import configuration from "../../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../../util/Crypto";

const AsesorModal = (props) => {
	const [confirmAsesor, setConfirmAsesor] = useState(false);
	const [show, setShow] = useState(false);
	const [datosAsesor, setDatosAsesor] = useState({});

	const handleClose2 = async () => {
		var data = Crypto.Decrypt("data");

		if (data.solicitud !== null) {
			await fetch(`${configuration.API_URL}/api/Traspaso/${data.solicitud}`, {
				method: "GET",
				headers: configuration.JSON_HEADER,
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.Code === 0) {
						const idProspecto = response.Response.Id;
						var formValues = Crypto.Decrypt("data");

						let raw = {
							rut: formValues.run,
							nombre: formValues.name,
							email: formValues.email,
							telefono: formValues.phone,
							idProspecto: idProspecto,
						};

						fetch(`${configuration.API_URL}/api/asistencia`, {
							method: "POST",
							headers: configuration.JSON_HEADER,
							body: JSON.stringify(raw),
						})
							.then((response) => response.json())
							.then((response) => {
								if (response.Code === 0) {
									setDatosAsesor(raw);
									setConfirmAsesor(true);
								} else {
									Swal.fire({
										icon: "error",
										text: "No se pudo registrar el avance de fase",
										confirmButtonText: "Ok",
										allowOutsideClick: false,
									});
								}
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									text: error.message,
									confirmButtonText: "Ok",
									allowOutsideClick: false,
								});
							});
					}
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: error.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				});
		}
	};

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	if (confirmAsesor)
		return (
			<Redirect
				to={{ pathname: "/asesor", state: { datosAsesor: datosAsesor } }}
			/>
		);

	return (
		<>
			<Button
				className="btn btn-secondary mb-3 mt-2 btn-block pointer"
				variant="outline-primary"
				onClick={handleShow}
				block
			>
				Necesito ayuda de un Asesor Virtual
			</Button>
			<Modal
				show={show}
				onHide={handleClose}
				animation={false}
				className="text-center"
				backdrop="static"
			>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="col-lg-11 col-md-10 col-sm-11 col-10">
								<h5 className="mt-38">
									<strong>Necesito ayuda de un Asesor Virtual</strong>
								</h5>
							</div>
							<div className="col-lg-1 col-md-2 col-sm-1 col-2">
								<button type="button" className="close" onClick={handleClose}>
									<span aria-hidden="true">×</span>
									<span className="sr-only">Close alert</span>
								</button>
							</div>
							<div className="modal-body_">
								<p style={{ color: "#5B5B5B" }}>
									Si necesitas ayuda para cambiarte a
									<a style={{ color: "#80BC00" }}> AFP Modelo </a>haz clic en
									confirmar y recibiremos tus datos de contacto.
								</p>
							</div>
							<div className="btn_card_">
								<Button variant="primary" onClick={handleClose2} block>
									Confirmar
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AsesorModal;
