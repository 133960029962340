import { useState } from "react";
import { Form } from "react-bootstrap";

export default function Name(props) {
	const [name, setName] = useState("");
	const [errorM, setErrorM] = useState({ show: false, message: "" });

	const nameChange = (event) => {
		event.preventDefault();
		const re = /^[-'A-Za-zÀ-ÖØ-öø-ÿ\s\.]+$/g;
		if (event.target.value === "" || re.test(event.target.value)) {
			setName(event.target.value);
		}
	};

	const nameBlur = (event) => {
		event.preventDefault();

		const regexNombre = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]+\s+[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]+\s+[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]+$/;

		var texto = event.target.value.trim();

		if (texto.length < 3 || texto.trim() === "") {
			setName("");
			props.setValidateForm({ ...props.validateForm, name: true });
			setErrorM({ show: true, message: "Nombre mínimo de 3 caracteres." });
		} else if (texto.trim().length > 30) {
			props.setValidateForm({ ...props.validateForm, name: true });
			setErrorM({ show: true, message: "Nombre máximo de 30 caracteres." });
		} else if (!regexNombre.test(texto)) {
			setName("");
			props.setValidateForm({ ...props.validateForm, name: true });
			setErrorM({ show: true, message: "El formato no es válido. Debe incluir nombre, apellido paterno y apellido materno." });
		}
		else {
			props.setValidateForm({ ...props.validateForm, name: false });
			setErrorM({ show: false, message: "" });
		}
	};

	return (
		<>
			<Form.Control
				value={name}
				className={errorM.show ? "is-invalid" : ""}
				type="text"
				placeholder="Tu nombre"
				name="name"
				onBlur={nameBlur}
				onChange={nameChange}
			/>
			{errorM.show ? (
				<div style={{ padding: "5px" }} className="alert alert-danger">
					<span
						className="glyphicon glyphicon-exclamation-sign"
						aria-hidden="true"
					></span>
					<span className="sr-only">Error:</span>
					<span className="text-danger">{errorM.message}</span>
				</div>
			) : null}
		</>
	);
}
