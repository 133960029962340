import { useState, useEffect } from "react";
import FichaConfirmaEmpleador from "./FichaConfirmaEmpleador";
import configuration from "../../../settings/Configurations";
import Swal from "sweetalert2";

const AntecedentesLaborales = ({ datos }) => {
    const tipoEmpleadoIndx = datos.TipoEmpleado - 1;
    const tipoEmpleadoArr = ["Dependiente antiguo", "Dependiente nuevo", "Independiente", "Pensionado cotizante", "Pensionado no cotizante", "Afiliado Voluntario", "Cesante"];
    const [validarExisteEmpleador, setValidarExiteEmpleador] = useState(false);
    const [datosEmpleado, setDatosEmpleado] = useState([]);

    useEffect(() => {
        setDatosEmpleado(datos);
        onChangeAfiliado(datos.TipoEmpleado);
    }, []);

    const onChangeAfiliado = async (Id) => {
        await fetch(`${configuration.API_URL}/api/tipo/${Id}`, {
            method: "GET",
            headers: configuration.JSON_HEADER
        }).then(response => response.json())
            .then(response => {
                setValidarExiteEmpleador(response.response.ExisteEmpleador);
            }).catch(error => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    text: error.message,
                    confirmButtonText: "Ok",
                    allowOutsideClick: false
                });
            });
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <strong><p className="pp_">Tipo de Empleado</p></strong>
                        <strong><p className="pr_">{tipoEmpleadoArr[tipoEmpleadoIndx]}</p></strong>
                    </div>
                </div>
            </div>
            {validarExisteEmpleador ?
                datosEmpleado.Empleadores.map((e, i) => <FichaConfirmaEmpleador key={i} e={e} />)
                : null
            }
        </>
    );
}

export default AntecedentesLaborales;
