import "./App.css";
import React, { useContext } from "react";
import Main from "./components/main/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import Context from "./store/context";
import Loading from "./components/pages/components/Loading";

export const userContext = React.createContext();

function App() {
	const { state } = useContext(Context);

	return (
		<div id="app-container">
			{state.loading ? <Loading /> : ""}
			<Main></Main>
		</div>
	);
}

export default App;
