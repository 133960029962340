import "./traspasoui.css";
import { useState, useEffect } from "react";
import RecienTrabajoModal from "./components/RecienTrabajoModal";
import { Button } from "react-bootstrap";
import Formulario from "./components/Formulario";
import disclaimer from "../../util/helper/disclaimer";
import Crypto from "../../util/Crypto";

export default function Traspaso(props) {

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => (window.location.replace("https://nueva.afpmodelo.cl/incorporaciones"));

	useEffect(() => {
		sessionStorage.setItem("intentos", 0);
		sessionStorage.clear();

		Crypto.Encrypt("data", new Object());
	}, []);

	return (
		<div className="cont-traspaso">
			<div className="row contenido">
				<div className="container_traspaso_sections">
					<div className="col-12">
						<h2 className="mt-3 text-black">¡Cámbiate a AFP Modelo!</h2>
					</div>
				</div>
			</div>
			<div className="row contenido">
				<div className="container_traspaso_sections">
					<div className="section">
						<div className="container_form">
							<div className="media-body text-sub-black">
								<span>
									Haz tu solicitud de <strong> cambio online fácil y rápido.</strong>
								</span>
							</div>
							<div className="media-body-m">
								<img
									src="./imgs/index/movil.svg"
									className="picture mobile"
									alt="movil"
								/>
							</div>
							<Formulario />
							<div className="container desktop">
								<div className="row">
									<div className="col-12 pl-0">
										<p className="text_small_ desktop">
											Te sugerimos que tengas los datos de tu empleador y cédula
											de identidad a mano para hacer este cambio mucho más
											rápido.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="section">
						<div className="container_picture">
							<img
								src="./imgs/index/desktop.svg"
								className="img_desktop"
								style={{ display: "none" }}
								alt="desktop"
							/>
						</div>
					</div>
					<div className="section">
						<div className="container_reasons">
							<h5 className="text-gray">
								¿Por qué cambiarme a AFP Modelo?
								<img src="./imgs/face.svg" className="ml-3" alt="" />
							</h5>
							<div className="media">
								<div className="media-body text-mut">
									<img
										src="./imgs/aunmenta.svg"
										className="mr-3 desktop"
										alt=""
									/>
									<img
										src="./imgs/aunmenta.svg"
										className="mr-3 mobile"
										alt=""
									/>

									<span>
										<strong className="d-block">
											{disclaimer.one.title}
										</strong>
										{disclaimer.one.detail}
									</span>
								</div>
							</div>
							<div className="media">
								<img src="./imgs/estre.svg" className="mr-3 desktop" alt="" />
								<div className="media-body text-mut">
									<img src="./imgs/estre.svg" className="mr-3 mobile" alt="" />
									<span>
										<strong className="d-block">
											{disclaimer.two.title}
										</strong>
										{disclaimer.two.detail}
									</span>
								</div>
							</div>
							<div className="media">
								<img src="./imgs/cora.svg" className="mr-3 desktop" alt="" />
								<div className="media-body text-mut">
									<img src="./imgs/cora.svg" className="mr-3 mobile" alt="" />
									<span>
										<strong className="d-block">
											{disclaimer.three.title}
										</strong>{" "}
										{disclaimer.three.detail}
									</span>
								</div>
							</div>
							<div className="row" style={{ display: "none" }}>
								<Button
									id="B-TW-0002"
									className="btn btn-secondary mx-auto"
									style={{ padding: "15px", paddingBottom: "15px !important" }}
									variant="outline-primary"
									onClick={handleShow}
								>
									Recién empecé a trabajar
								</Button>
							</div>
							<RecienTrabajoModal handleClose={handleClose} show={show} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}