import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Context from "../../../../../store/context";
import Crypto from "../../../../util/Crypto";
import sendRequest from "../../../../util/call/sendRequest";
import ValidarData from "../../../components/ValidarData";

export default function ParteAFPModeloModal(prop) {
	const { state, actions } = useContext(Context);
	const [redirect, setRedirect] = useState(false);
	const [disabled, setdisabled] = useState(false);

	const agregarOtraCuenta = async () => {
		await OnLoading(true);

		const decrypted = Crypto.Decrypt("data");

		var data = Object();
		data.rut = decrypted.run;

		var result = await sendRequest({
			url: "previsional/data",
			method: "POST",
			body: JSON.stringify(data)
		});

		if (result.codigo === 0) {
			decrypted.run = decrypted.run;
			decrypted.name = `${result.response.nombre}`;
			decrypted.fatherName = `${result.response.apPaterno}`;
			decrypted.motherName = `${result.response.apMaterno}`;
			decrypted.phone = result.response.celular;
			decrypted.email = result.response.email;

			Crypto.Encrypt("data", decrypted);
		}

		setRedirect(true);

		await OnLoading(false);
	};

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
		setdisabled(load);
	};

	return (
		<>
			{redirect ? (
				<Redirect
					to={{
						pathname: "/autenticacion",
					}}
				/>
			) : null}
			<ValidarData />
			<div className="container text-center">
				<div className="row">
					<div className="contenedor_img22">
						<strong className="custom">¡Tú ya eres parte de AFP Modelo!</strong>
						<br />
						<img
							className="nina_error"
							src="./imgs/incorrecta.svg"
							alt="nina modelo"
						/>
					</div>
				</div>
				<div className="row">
					<div className="card-body">
						<Button
							variant="primary"
							id="B-TW-0003"
							onClick={() =>
								window.location.replace(
									"https://www.afpmodelo.cl/AFP/Acceso-mi-cuenta/Acceso-a-mi-Cuenta.aspx"
								)
							}
							block
						>
							¿Quieres ir a tu sitio privado?
						</Button>
					</div>
				</div>
				<div className="row">
					<div className="col-12 d-flex justify-content-center">o</div>
				</div>
				<div className="row">
					<div className="btn_btn_btn">
						<Button
							variant="outline-primary"
							id="B-TW-0004"
							onClick={agregarOtraCuenta}
							block
							disabled={disabled}
							className="btn_22 style={{paddingLeft:'44px', paddingRight:'44px', height:49px'}}"
						>
							¿Quieres agregar una cuenta?
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
