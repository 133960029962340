import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import ValidateFieldsUtil from "../../../../util/validacion/ValidateFieldsUtil";
import Context from "../../../../../store/context";
import configuration from "../../../../settings/Configurations";
import Inputmask from "inputmask";
import Swal from "sweetalert2";
import Crypto from "../../../../util/Crypto";

export default function Rut(props) {
	const { state, actions } = useContext(Context);
	const [validate, setValidate] = useState(false);
	const [rut, setRut] = useState("");
	const [rutField, setRutField] = useState("");
	const [errorM, setErrorM] = useState({ show: false, message: "" });

	const rutChange = (event) => {
		event.preventDefault();
		setRutField(event.target.value);
		var selector = document.getElementById("RUTMASK");

		Inputmask({
			mask: "(9(.999){2}-K)|(99(.999){2}-K)",
			autoUnmask: true,
			keepStatic: true,
			showMaskOnFocus: true,
			showMaskOnHover: true,
			definitions: {
				K: {
					validator: "[0-9|Kk]",
					casing: "upper",
				},
			},
		}).mask(selector);
	};

	const formatValidateRut = (rutString) => {
		let rutparts = rutString.split("-");
		let rut = rutparts[0].replace(/\./g, "");
		let h = "";
		let dv = "";

		if (rutparts.length > 1) {
			h = "-";
			dv = rutparts[1];
		} else {
			if (rut.length > 2) {
				h = "-";
				dv = rut[rut.length - 1];
				rut = rut.substring(0, rut.length - 1);
			}
		}

		if (rut.length > 8) rut = rut.substring(0, 8);
		let T = rut;
		var M = 0,
			S = 1;
		for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;

		if (dv === (S ? S - 1 : "k").toString()) {
			rut = rut.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			return rut + h + dv;
		} else if (dv === (S ? S - 1 : "K").toString()) {
			rut = rut.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			return rut + h + dv;
		} else {
			return "";
		}
	};

	const rutBlur = async (event) => {
		event.preventDefault();

		if (event.target.value !== null && event.target.value !== "") {
			var value = event.target.value.padStart(9, "0");

			actions({ type: "setState", payload: { ...state, loading: true } });
			let rutString = formatValidateRut(value);

			if (rutString !== "") {
				setRutField(rutString);
				setErrorM({ show: false, message: "" });
				props.setValidateForm({ ...props.validateForm, rut: false });

				let rut = ValidateFieldsUtil.eliminaPuntosYGuion(
					ValidateFieldsUtil.Upper(event.target.value)
				);

				await fetch(`${configuration.API_URL}/api/Previsional/Validar`, {
					method: "POST",
					headers: configuration.JSON_HEADER,
					body: JSON.stringify({
						Rut: rut,
					}),
				})
					.then((response) => response.json())
					.then((response) => {
						OnLoading(false);
						setRut(rut);

						var data = Object();
						data.run = rut;

						Crypto.Encrypt("data", data);

						setValidate(response);
					})
					.catch((error) => {
						console.error(error);
						Swal.fire({
							icon: "error",
							text: error.message,
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});
						OnLoading(false);
					});
			} else {
				setRutField(event.target.value);
				setErrorM({ show: true, message: "El RUT ingresado es incorrecto." });
				props.setValidateForm({ ...props.validateForm, rut: true });
				OnLoading(false);
			}
		} else {
			setErrorM({ show: true, message: "Ingrese el RUT, por favor." });
			OnLoading(false);
		}
	};

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	return (
		<>
			{validate ? (
				<Redirect to={{ pathname: "/yaeresinfo", state: { rut: rut } }} />
			) : null}
			<Form.Control
				id="RUTMASK"
				value={rutField}
				className={errorM.show ? "is-invalid" : ""}
				type="text"
				maxLength={12}
				placeholder="99.999.999-9"
				name="rut"
				onBlur={rutBlur}
				onChange={(e) => {
					rutChange(e);
				}}
			/>
			{errorM.show ? (
				<div style={{ padding: "5px" }} className="alert alert-danger">
					<span
						className="glyphicon glyphicon-exclamation-sign"
						aria-hidden="true"
					></span>
					<span className="sr-only">Error:</span>
					<span className="text-danger">{errorM.message}</span>
				</div>
			) : null}
		</>
	);
}
