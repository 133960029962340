import { Button, Form } from "react-bootstrap";
import { useState, useContext } from "react";
import Swal from "sweetalert2";
import Configuration from "../../../settings/Configurations";
import Context from "../../../../store/context";
import Tooltip from "../autenticacionui/PreguntasTooltip";
import Crypto from "../../../util/Crypto";
import { isMobile, isBrowser } from 'react-device-detect';

export default function FormClaveUnica() {
	const { state, actions } = useContext(Context);
	const [serial, setSerialNumber] = useState("");

	const DocumentChange = (event) => {
		event.preventDefault();

		const re1try = /^\b[aA]$/;
		const re2try = /^\b[aA][0-9]{1,9}$/;
		const reOnlyNumbers = /^\b[0-9]{1,9}$/;
		if (re1try.test(event.target.value) || event.target.value === "") {
			setSerialNumber(event.target.value);
		} else if (
			event.target.value.length > 1 &&
			re2try.test(event.target.value)
		) {
			setSerialNumber(event.target.value);
		} else if (reOnlyNumbers.test(event.target.value)) {
			setSerialNumber(event.target.value);
		}
	};

	const AutenticateCI = async () => {
		if (Configuration.ACTIVE_FAO) {
			window.location.replace("/fao");
		} else {
			if (serial !== "") {
				await OnLoading(true);

				var data = Crypto.Decrypt("data");
				data.autenticacion = "CU";
				data.device = isMobile ? "Mobile" : isBrowser ? "Browser" : "Unknown";

				Crypto.Encrypt("data", data);

				await fetch(`${Configuration.API_URL}/api/ecert/Authenticate/CI/${data.run}`, {
					method: "POST",
					headers: Configuration.JSON_HEADER,
				})
					.then((response) => response.json())
					.then(async (result) => {
						if (result.Exito) {
							await ValidateCI(data, result.ObjetoGenerico);
						} else {
							Swal.fire({
								icon: "error",
								text: result.Descripcion,
								confirmButtonText: "Ok",
								allowOutsideClick: false,
							});
							OnLoading(false);
						}
					})
					.catch((error) => {
						Swal.fire({
							icon: "error",
							text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});
						OnLoading(false);
					});
			} else {
				Swal.fire({
					icon: "error",
					text: "Por favor, ingrese su número de serie.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			}
		}
	};

	const ValidateCI = async (data, token) => {
		await fetch(`${Configuration.API_URL}/api/ecert/validate/ci`, {
			method: "POST",
			body: JSON.stringify({
				Datos: {
					Run: data.run,
					NumeroSerie: serial,
				},
				Token: token,
			}),
			headers: Configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.Exito) {
					await AuthenticateCU(data);
				} else {
					var dataCliente = result.ObjetoGenerico.DatosCliente;

					var valida = dataCliente.EstadoRespuesta !== "Consulta exitosa";
					var bloqueada = dataCliente.IndicadorBloqueo !== "NOBLOQUEADO";
					var vencida = dataCliente.Vigencia !== "S";

					if (valida) {
						Swal.fire({
							title: "Número de serie incorrecto",
							icon: "error",
							text: "El número de serie que ingresaste no corresponde al de tu cédula de identidad. Inténtalo nuevamente.",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});
					} else if (bloqueada) {
						Swal.fire({
							icon: "error",
							text: "Tu cédula está bloqueada.",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						}).then((result) => {
							if (result.isConfirmed) {
								Swal.fire({
									icon: "info",
									html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
								});
							}
						});
					} else if (vencida) {
						Swal.fire({
							icon: "error",
							text: "Tu cédula está vencida.",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						}).then((result) => {
							if (result.isConfirmed) {
								Swal.fire({
									icon: "info",
									html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
								});
							}
						});
					}

					OnLoading(false);
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
				OnLoading(false);
			});
	};

	const AuthenticateCU = async (data) => {
		await fetch(`${Configuration.API_URL}/api/ecert/authenticate/${data.run}`, {
			method: "POST",
			headers: Configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.Exito) {
					data.token = result.ObjetoGenerico.TokenUser.TokenUser;
					Crypto.Encrypt("data", data);

					await SolicitudCU(data);
				} else {
					Swal.fire({
						icon: "error",
						text: result.Descripcion,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
					OnLoading(false);
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
				OnLoading(false);
			});
	};

	const SolicitudCU = async (data) => {
		await fetch(`${Configuration.API_URL}/api/ecert/validate/cu`, {
			method: "POST",
			headers: Configuration.JSON_HEADER,
			body: JSON.stringify({
				Run: data.run,
				UrlCallback: `${window.location.origin}/validate`,
				Nombres: data.name,
				ApellidoPaterno: data.fatherName,
				ApellidoMaterno: data.motherName,
				Correo: data.email,
				Token: data.token,
				NumeroSerie: serial
			}),
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.Exito) {
					OnLoading(false);

					var resultado = result.ObjetoGenerico.Resultado;

					data.identificador = resultado.Identificador;
					data.tokentvi = resultado.IdentificadorPrevired;

					Crypto.Encrypt("data", data);

					let timerInterval;
					Swal.fire({
						icon: "success",
						title: "ClaveÚnica",
						html: '<ul>Se han envado los datos de manera exitosa.</ul><ul>Se redireccionará automáticamente al sitio. <b class="d-none"></b></ul>',
						timer: 5000,
						timerProgressBar: true,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
							const b = Swal.getHtmlContainer().querySelector("b");
							timerInterval = setInterval(() => {
								b.textContent = Swal.getTimerLeft();
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((timerResult) => {
						if (timerResult.dismiss === Swal.DismissReason.timer) {
							window.location.replace(resultado.UrlClaveUnica);
						}
					});
				} else {
					const steps = [];
					var arr = result.ObjetoGenerico.ErroresValidacion;
					for (var i = 1; i <= arr.length; i++) {
						steps.push(i.toString());
					}

					const swalQueueStep = Swal.mixin({
						confirmButtonText: "Siguiente",
						cancelButtonText: "Anterior",
						progressSteps: steps,
						reverseButtons: true,
					});

					const values = [];
					let currentStep;

					OnLoading(false);

					for (currentStep = 0; currentStep < steps.length;) {
						var text = `${arr[currentStep]}`;

						const resultq = await swalQueueStep.fire({
							title: result.Descripcion,
							showCancelButton: currentStep > 0,
							html: text,
							currentProgressStep: currentStep,
						});

						if (resultq.value) {
							values[currentStep] = resultq.value;
							currentStep++;
						} else if (resultq.dismiss === Swal.DismissReason.cancel) {
							currentStep--;
						} else {
							break;
						}
					}
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
				OnLoading(false);
			});
	};

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	return (
		<div>
			<Form>
				{!Configuration.ACTIVE_FAO ? (
					<Form.Group>
						<div className="text-center">
							<h6 className="sub_title">
								Ingresa el N° de serie de tu Cédula de Identidad <Tooltip />
							</h6>
							<Form.Control
								value={serial}
								type="text"
								className=""
								placeholder="Ingresa número de serie"
								name="numdoc"
								onChange={DocumentChange}
							/>
						</div>
					</Form.Group>
				) : null}
				<Form.Group className="align-items-center">
					<div className="btn_card_a">
						<Button
							variant="primary"
							type="button"
							onClick={AutenticateCI}
							block
						>
							Cambiarme a AFP Modelo
						</Button>
					</div>
				</Form.Group>
			</Form>
		</div>
	);
}
