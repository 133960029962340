import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Header from "../pages/components/header/Header";
import Footer from "../pages/components/footer/Footer";
import Traspaso from "../pages/traspaso/Traspaso";
import Autenticacion from "../pages/autenticacion/Autenticacion";
import Cuestionario from "../pages/cuestionario/Cuestionario";
import RedirectGenerico from "../pages/traspaso/components/redirects/RedirectGenerico";
import PreguntasSeguridad from "../pages/cuestionario/PreguntasSeguridad";
import FormCotizacion from "../pages/cotizacion/components/FormCotizacion";
import DatosPersonales from "../pages/datos_personales/DatosPersonales";
import ScrollToTop from "../util/ScrollToTop";
import ParteAFPModeloModal from "../pages/traspaso/components/redirects/ParteAFPModeloModal";
import NoHayCuentas from "../pages/autenticacion/components/NoHayCuentas";
import Bienvenida from "../pages/bienvenida/Bienvenida";
import ConfirmarTraspaso from "../pages/confirmarTraspaso/ConfirmarTraspaso";
import PageNotFound from "../pages/global/PageNotFound";
import PageError from "../pages/global/PageError";
import ConfirmarAsesor from "../pages/cuestionario/ConfirmarAsesor";
import FormValidate from "../pages/autenticacion/components/FormValidate";
import FormTVI from "../pages/autenticacion/components/FormTVI";
import FormClaveUnicaFAO from "../pages/autenticacion/components/FormClaveUnicaFAO";

class Main extends React.Component {
	render() {
		return (
			<div id="main-container">
				<BrowserRouter>
					<Header />
					<ScrollToTop />
					<main>
						<Switch>
							<Route
								path="/"
								exact
								render={(props) => <Traspaso {...props} />}
							/>
							<Route
								path="/misdatos"
								exact
								render={(props) => <DatosPersonales {...props} />}
							/>
							<Route
								path="/cuestionario"
								exact
								render={(props) => <Cuestionario {...props} />}
							/>
							<Route
								path="/autenticacion"
								exact
								render={(props) => <Autenticacion {...props} />}
							/>
							<Route
								path="/info"
								exact
								render={(props) => <RedirectGenerico {...props} />}
							/>
							<Route
								path="/seguridadCuestionario"
								exact
								render={(props) => <PreguntasSeguridad {...props} />}
							/>
							<Route
								path="/cuentasyfondo"
								exact
								render={(props) => <FormCotizacion {...props} />}
							/>
							<Route
								path="/yaeresinfo"
								exact
								render={(props) => <ParteAFPModeloModal {...props} />}
							/>
							<Route
								path="/nohaycuentas"
								exact
								render={(props) => <NoHayCuentas {...props} />}
							/>
							<Route
								path="/bienvenida"
								exact
								render={(props) => <Bienvenida {...props} />}
							/>
							<Route
								path="/confirmartraspaso"
								exact
								render={(props) => <ConfirmarTraspaso {...props} />}
							/>
							<Route
								path="/error"
								exact
								render={(props) => <PageError {...props} />}
							/>
							<Route
								path="/asesor"
								exact
								render={(props) => <ConfirmarAsesor {...props} />}
							/>
							<Route
								path="/validate"
								exact
								render={(props) => <FormValidate {...props} />}
							/>
							<Route
								path="/tvi"
								exact
								render={(props) => <FormTVI {...props} />}
							/>
							<Route
								path="/fao"
								exact
								render={(props) => <FormClaveUnicaFAO {...props} />}
							/>
							<Route
								path="*"
								exact
								render={(props) => <PageNotFound {...props} />}
							/>
						</Switch>
					</main>
					<Footer />
				</BrowserRouter>
			</div>
		);
	}
}

export default Main;
