class Validacion {
    constructor(mensaje, exito, fracaso, invocador) {
        this.valida = false;
        this.mensaje = mensaje;
        this.detalles = [];
        this.reglas = [];
        this.exito = exito;
        this.fracaso = fracaso;
        this.invocador = invocador;
    }

    agregarRegla(regla) {
        regla.validacion = this;
        this.reglas.push(regla);
    }

    buscarRegla = (nombre) => this.reglas.find(r => r.nombre === nombre);

    buscarReglaPorId = (id) => this.reglas.find(r => r.id === id);

    validar() {
        this.valida = false;
        this.detalles = [];
        this.reglas.forEach(r => r.validar(false));
        this.valida = this.reglas.every(r => r.valida);
        if (!this.valida) this.detalles = this.reglas.filter(r => !r.valida).map(i => i.mensaje);
    }

    validacionUnica(regla) {
        let r = this.buscarRegla(regla);
        let result = false;
        if (r) {
            r.validar(true);
            r.despues();
            result = r.valida;
            r.valida = false;
        }
        return result;
    }

    despues() {
        if (this.exito && this.valida) this.exito();
        if (this.fracaso && !this.valida) this.fracaso();

        this.reglas.forEach(r => r.despues())
    }

    actualizarCampo(regla, campo, valor) {
        let r = this.buscarRegla(regla);
        if (r) {
            let c = r.buscarCampo(campo);
            if (c)
                c.valor = valor;
        }
    }
}

export default Validacion;