import "./header.css";
import Whatsapp from "./Whatsapp";

const Header = () => {
	return (
		<header className="sticky-top navbar-dark">
			<div className="bg-header-c">
				<a href="https://nueva.afpmodelo.cl/afiliados">
					<div className="logo"></div>
				</a>
				<div className="text-right p-4">
					<h6 className="mb-0"> </h6>
				</div>
			</div>
			<Whatsapp />
		</header>
	);
};

export default Header;