import configuration from "../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../util/Crypto";

const RegistrarIntentoLogin = async (metodo, resultado, setCallback) => {
	var data = Crypto.Decrypt("data");

	let raw = { token: data.solicitud, metodo: metodo, resultado: resultado };

	if (data.solicitud !== null) {
		await fetch(`${configuration.API_URL}/api/Autenticacion`, {
			method: "POST",
			headers: configuration.JSON_HEADER,
			body: JSON.stringify(raw),
		}).catch((error) => {
			console.error(error);
			Swal.fire({
				icon: "error",
				text: error.message,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});

			setCallback({
				error: "No se pudo registrar la autenticación",
				detalle: error.message,
			});
		});
	}
};
export default RegistrarIntentoLogin;
