import "./autenticacion.css";
import React, { useState, useEffect } from "react";
import { Card, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormClaveUnica from "./components/FormClaveUnica";
import FormClaveSeguridad from "./components/FormClaveSeguridad";
import RegistrarPaso from "../../util/RegistrarPaso";
import ClaveUnica from "./img/claveunica.svg";
import Crypto from "../../../components/util/Crypto";
import disclaimer from "../../util/helper/disclaimer";
import Configuration from "../../settings/Configurations";
import Swal from "sweetalert2";
import ValidarData from "../components/ValidarData";

export default function Autenticacion(props) {
	const [isOn, toggleIsOn] = useToggle(true);
	const [isOnB, toggleIsOnB] = useToggle(false);
	const [setLoading] = useState(false);
	const accordeon = { display: "flex", flexDirecction: "row" };
	const [pasoRegistrado, setPasoRegistrado] = useState(false);

	const [currentDate] = useState(new Date());
	const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

	const [enableCU, setEnableCU] = useState(true);

	useEffect(async () => {
		await fetch(`${Configuration.API_URL}/api/configuration`, {
			method: "GET",
			headers: Configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then(async (result) => {
				if (result.code === "0") {
					//setEnableCU(result.response.CuVisible);
				} else {
					Swal.fire({
						icon: "error",
						text: result.Descripcion,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});
	}, []);

	useEffect(() => {
		let data = Crypto.Decrypt("data");

		if (data?.run === null || data?.run === "") {
			window.location.replace("/");
		}

		if (!pasoRegistrado) {
			RegistrarPaso(2);
			setPasoRegistrado(true);
		}
	}, [pasoRegistrado]);

	function useToggle(initialValue = false) {
		const [value, setValue] = React.useState(initialValue);
		const toggle = React.useCallback(() => {
			setValue((v) => !v);
		}, []);
		return [value, toggle];
	}

	return (
		<div className="cont-traspaso auth">
			<ValidarData />
			<div className="row contenido">
				<div className="container_traspaso_sections">
					<div className="col-12">
						<h2 className="mt-3 text-black">¡Cámbiate a AFP Modelo!</h2>
					</div>
				</div>
			</div>
			<div className="row contenido">
				<div className="container_traspaso_sections">
					<div className="section">
						<div className="container_form">
							<div className="media-body text-sub-black">
								<span>
									{" "}Haz tu solicitud de{" "} <strong>cambio online fácil y rápido.</strong>
								</span>
							</div>
							<div className="media-body-m">
								<img
									src="./imgs/index/movil.svg"
									className="picture mobile"
									alt="movil"
								/>
							</div>
							<h5 className="mt-40 d-none">
								<span>Elige cómo quieres identificarte</span>
							</h5>
							<Accordion defaultActiveKey="0">
								<Card className="container_identificacion">
									<Accordion.Toggle
										className="collapse_identificacion pointer"
										onClick={toggleIsOn}
										as={Card.Header}
										eventKey="0"
									>
										<div style={accordeon}>
											<div className="container">
												<div className="row">
													<div className="col-2">
														<img
															src="./imgs/green-key.svg"
															alt="key"
															className="identificacion_icon"
														/>
													</div>
													<div className="col-8">
														<h5>Clave de seguridad</h5>
													</div>
													<div className="col-2">
														<img
															src={
																!isOnB
																	? "./imgs/chevron-down-solid.svg"
																	: "./imgs/chevron-up-solid.svg"
															}
															alt="nina modelo"
															width="20"
														/>
													</div>
												</div>
											</div>
										</div>
										<p className="texto_caja">
											Es la segunda Clave de AFP, te permite hacer: Cambio de
											fondos y <strong> cambio de AFP.</strong>
										</p>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body className="bg-white">
											<FormClaveSeguridad setLoading={setLoading} />
										</Card.Body>
									</Accordion.Collapse>
								</Card>

								{enableCU ? (
									<Card className="container_identificacion">
										<Accordion.Toggle
											className="collapse_identificacion pointer"
											onClick={toggleIsOnB}
											as={Card.Header}
											eventKey="1"
										>
											<div style={accordeon}>
												<div className="container">
													<div className="row">
														<div className="col-10">
															<img
																src={ClaveUnica}
																alt="ClaveÚnica"
																width={300}
																height={100}
															/>
														</div>
														<div className="col-2">
															<img
																src={
																	!isOn
																		? "./imgs/chevron-down-solid.svg"
																		: "./imgs/chevron-up-solid.svg"
																}
																alt="nina modelo"
																width="20"
															/>
														</div>
													</div>
												</div>
											</div>
											<p className="texto_caja">
												{!Configuration.ACTIVE_FAO ? (
													<p>Si sólo posees cuenta obligatoria podrás utilizar ClaveÚnica como método de autenticación.</p>
												) :
													<p>Te invitamos validad tu identidad con ClaveÚnica y responder correctamente las preguntas de seguridad.</p>
												}
											</p>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<Card.Body className="bg-white">
												<FormClaveUnica setLoading={setLoading} />
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								) : null}
							</Accordion>
							<div className="container desktop">
								<div className="row">
									<div className="col-12 pl-0">
										<p className="text_small_ desktop">
											Te sugerimos que tengas los datos de tu empleador y cédula
											de identidad a mano para hacer este cambio mucho más
											rápido.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="section">
						<div className="container_picture">
							<div className="d-block">
								<img
									src="./imgs/index/desktop.svg"
									className="img_desktop"
									style={{ display: "none" }}
									alt=""
								/>
								<Link to="/">
									<div className="link link-volver">Volver</div>
								</Link>
							</div>
						</div>
					</div>
					<div className="section">
						<div className="container_reasons">
							<h5 className="text-gray">
								¿Por qué cambiarme a AFP Modelo?
								<img src="./imgs/face.svg" className="ml-3 " alt="" />
							</h5>
							<div className="media">
								<img
									src="./imgs/aunmenta.svg"
									className="mr-3 desktop"
									alt=""
								/>
								<div className="media-body text-mut">
									<img
										src="./imgs/aunmenta.svg"
										className="mr-3 mobile"
										alt=""
									/>
									<span>
										<strong className="d-block">
											{disclaimer.one.title}
										</strong>
										{disclaimer.one.detail}
									</span>
								</div>
							</div>
							<div className="media">
								<img src="./imgs/estre.svg" className="mr-3 desktop" alt="" />
								<div className="media-body text-mut">
									<img src="./imgs/estre.svg" className="mr-3 mobile" alt="" />
									<span>
										<strong className="d-block">
											{disclaimer.two.title}
										</strong>
										{disclaimer.two.detail}
									</span>
								</div>
							</div>
							<div className="media">
								<img src="./imgs/cora.svg" className="mr-3 desktop" alt="" />
								<div className="media-body text-mut">
									<img src="./imgs/cora.svg" className="mr-3 mobile" alt="" />
									<span>
										<strong className="d-block">
											{disclaimer.three.title}
										</strong>{" "}
										{disclaimer.three.detail}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
