import { useContext, useState, useEffect } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { Redirect, useLocation } from "react-router-dom";
import ClaveSeguridadModal from "./ClaveSeguridadModal";
import Validacion from "../../../util/validacion/Validacion";
import Regla from "../../../util/validacion/Regla";
import Errors from "../components/Errors";
import RegistrarIntentoLogin from "../../../util/RegistrarIntentoLogin";
import "../autenticacion.css";
import Context from "../../../../store/context";
import configuration from "../../../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../../../util/Crypto";
import { isMobile, isBrowser } from 'react-device-detect';

export default function FormClaveSeguridad({ setActiveSacu, setActiveRut }) {
	const { state, actions } = useContext(Context);
	const [form, setForm] = useState([]);
	const [toCCO, setToCCO] = useState(false);
	const [errors, setErrors] = useState([]);
	const [setErrorHandles] = useState(null);
	const [dataJson, setDataJson] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [sinCuentas, setSinCuentas] = useState(false);
	const [causalRechazo, setCausalRechazo] = useState([]);
	const params = new URLSearchParams(useLocation().search);
	const value = params.get("token");

	const addOption = async (arr) => {
		const steps = [];

		for (var i = 1; i <= arr.length; i++) {
			steps.push(i.toString());
		}

		const swalQueueStep = Swal.mixin({
			confirmButtonText: "Siguiente",
			cancelButtonText: "Anterior",
			progressSteps: steps,
			reverseButtons: true,
		});

		const values = [];
		let currentStep;

		for (currentStep = 0; currentStep < steps.length;) {
			var text = `La ${arr[currentStep].nombreTipo} perteneciente a ${arr[currentStep].origen} presenta el siguiente problema: ${arr[currentStep].causalRechazo}`;

			if (arr[currentStep].causalRechazoCodigo === 15) {
				text = `La ${arr[currentStep].nombreTipo} que intentas traspasar desde AFP ${arr[currentStep].origen} se encuentra bloqueada, dado que presentas una medida cautelar vigente por concepto de la Ley de Pensión de Alimentos (ley 21.484)`;
			}

			const result = await swalQueueStep.fire({
				title: "No es posible concretar tu solicitud",
				showCancelButton: currentStep > 0,
				html: text,
				currentProgressStep: currentStep,
			});

			if (result.value) {
				values[currentStep] = result.value;
				currentStep++;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				currentStep--;
			} else {
				break;
			}
		}
	};

	useEffect(() => {
		if (value) {
			setToCCO(true);
		}
	}, [value]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		setCausalRechazo([]);
		actions({ type: "setState", payload: { ...state, loading: true } });

		let validacion = new Validacion(
			"Formulario inválido, por favor revisar Clave de Seguridad:",
			null,
			null,
			null
		);
		let clavevacia = new Regla(
			1,
			"clave_vacia",
			"La clave de seguridad no puede estar VACÍA",
			"vacio",
			null,
			null
		);
		clavevacia.agregarCampo(
			"claveseguridad",
			"Clave de Seguridad",
			form.claveseg
		);

		validacion.agregarRegla(clavevacia);
		validacion.validar();

		if (validacion.valida) {
			setErrors([]);
			var data = Crypto.Decrypt("data");
			data.autenticacion = "SACU";
			data.device = isMobile ? "Mobile" : isBrowser ? "Browser" : "Unknown";
			Crypto.Encrypt("data", data);

			var rut = data.run.replace("-", "");

			let _body = {
				Rut: rut,
				Clave: form.claveseg,
				Ip: "",
			};

			await fetch(`${configuration.API_URL}/api/Previsional/clave`, {
				method: "POST",
				headers: configuration.JSON_HEADER,
				body: JSON.stringify(_body),
			})
				.then((response) => response.json())
				.then((response) => {

					const claveOk = response.codigo === 0;
					RegistrarIntentoLogin(1, claveOk, setErrorHandles);

					if (claveOk) {
						let token = response.response.respuestansacu.token;

						var data = Crypto.Decrypt("data");
						data.token = token;

						Crypto.Encrypt("data", data);

						let raw = {
							token: token,
							codigo: "1034",
							rut: rut.substring(0, rut.length - 1),
							rutDv: rut.substring(rut.length - 1),
							nombres: "",
							apellidoPaterno: "",
							apellidoMaterno: "",
							cuentas: [
								{ codigo: "CCICO" },
								{ codigo: "CCIAV" },
								{ codigo: "CAI" },
								{ codigo: "CAV" },
								{ codigo: "CCICV" },
								{ codigo: "CCIDC" },
								{ codigo: "APVC" },
							],
							otros: "",
							autenticacion: data.autenticacion
						};

						sessionStorage.setItem("jsonFondo", JSON.stringify(raw));
						setCausalRechazo([]);

						fetch(`${configuration.API_URL}/api/Previred`, {
							method: "POST",
							headers: configuration.JSON_HEADER,
							body: JSON.stringify(raw),
						})
							.then((response) => response.json())
							.then((response) => {
								if (response.code === "0") {
									actions({
										type: "setState",
										payload: { ...state, loading: false },
									});

									if (response.response.cuentas.length === 0) {
										setSinCuentas(true);

										if (response.response.cuentasRechazo.length !== 0) {
											addOption(response.response.cuentasRechazo);
										}
									} else {
										response.response.token = raw.token;

										response.response.cuentas.forEach((item, index) => {
											item.fondos.forEach((fondo, i) => {
												fondo.origen = fondo.nombre;
											});
										});

										setDataJson(response);
										setToCCO(true);
									}
								} else {
									Swal.fire({
										icon: "error",
										text: response.message,
										confirmButtonText: "Ok",
										allowOutsideClick: false,
									});

									actions({
										type: "setState",
										payload: { ...state, loading: false },
									});
								}
							})
							.catch((error) => {
								actions({
									type: "setState",
									payload: { ...state, loading: false },
								});

								console.error(error);
								Swal.fire({
									icon: "error",
									text: error.message,
									confirmButtonText: "Ok",
									allowOutsideClick: false,
								});
							});
					} else {
						Swal.fire({
							icon: "error",
							text: response.mensaje,
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});

						console.error(response.mensaje);

						actions({
							type: "setState",
							payload: { ...state, loading: false },
						});
						setToCCO(false);
					}
				})
				.catch((error) => {
					actions({ type: "setState", payload: { ...state, loading: false } });

					console.error(error);
					Swal.fire({
						icon: "error",
						text: error.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});

					return false;
				});
		} else {
			actions({ type: "setState", payload: { ...state, loading: false } });
			let errores = [];

			validacion.reglas.forEach((r) => {
				if (!r.valida) errores.push(r.mensaje);
			});
			setErrors(errores);
		}
	};

	const [passwordShown, setPasswordShown] = useState(false);

	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	const getStrval = (val) => (val === undefined || val === null ? "" : val);

	const handleChange = (event) => {
		event.preventDefault();
		let arrayForm = form;
		arrayForm[[event.target.name]] = event.target.value;
		setForm(arrayForm);
		setDisabled(getStrval(event.target.value) === "");
	};

	const noAccounts = () => {
		causalRechazo.forEach(function (cuenta) {
			let texto = `La ${cuenta.nombreTipo} perteneciente a ${cuenta.origen} presenta el siguiente problema: ${cuenta.causalRechazo}`;
			Swal.fire({
				icon: "error",
				text: texto,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		});
	};

	return (
		<div>
			{toCCO ? (
				<Redirect
					to={{ pathname: "/cuentasyfondo", state: { json: dataJson } }}
				/>
			) : null}
			{sinCuentas ? noAccounts() : null}
			<Form onSubmit={handleSubmit} onChange={handleChange}>
				<Form.Group className="align-items-center">
					<ClaveSeguridadModal />
				</Form.Group>
				<InputGroup>
					<FormControl
						type={passwordShown ? "text" : "password"}
						className={errors.length > 0 ? "is-invalid" : ""}
						placeholder={passwordShown ? "ABC2020" : "*******"}
						name="claveseg"
						autoComplete="current-password"
					/>
					<span
						className="input-group-append bg-white border-left-0 pointer"
						onClick={togglePasswordVisiblity}
					>
						<span
							className="input-group-text bg-transparent"
							id={"togglePass"}
							data-see={false}
							onClick={null}
						>
							{!passwordShown ? "ver" : "ocultar"} &nbsp;&nbsp;{" "}
							<img
								src={
									!passwordShown
										? "./imgs/eye-regular.svg"
										: "./imgs/eye-slash-regular.svg"
								}
								width="20"
								alt="eye"
							/>
						</span>
					</span>
				</InputGroup>

				<Errors errors={errors} />
				<Form.Group className="mt-3">
					<div className="btn_card_a">
						<Button
							id="B-TW-0005"
							variant="primary"
							type="submit"
							block
							className="cambiarme"
							disabled={disabled}
						>
							Continuar
						</Button>
					</div>
				</Form.Group>
			</Form>
		</div>
	);
}
