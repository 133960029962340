import { useEffect } from "react";
import Crypto from "../../util/Crypto";

export default function ValidarData() {

    useEffect(async () => {
        var data = Crypto.Decrypt("data");
        if (data == null || data.run == undefined || data.run == null) {
            window.location.replace("/");
        }
    }, []);

    return (<div></div>);
}