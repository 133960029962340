import configuration from "../../settings/Configurations";
import Swal from "sweetalert2";

const headers = {
    accept: "application/json",
    "Content-Type": "application/json"
};

interface Props {
    url: string;
    method: string;
    body?: any;
}

export default async function sendRequest({ url, method, body }: Props) {
    const newUrl = `${configuration.API_URL}/api/${url}`;

    try {
        const response = await fetch(newUrl, {
            method: method,
            headers: headers,
            body: body
        });

        if (!response.ok) {
            Swal.fire({
                icon: "error",
                text: `Request failed with ${response.status} ${response.statusText}`,
                confirmButtonText: "Ok",
                allowOutsideClick: false,
            });
        }
        const json = await response.json();
        return json;
    } catch (error: any) {
        Swal.fire({
            icon: "error",
            text: error,
            confirmButtonText: "Ok",
            allowOutsideClick: false,
        });
    } finally {

    }



}
