import "../confirmarTraspaso.css";
import React, { Component } from "react";
import { Card, Accordion } from "react-bootstrap";

class AccordionConfirmar extends Component {
	constructor(props) {
		super(props);
		this.state = { accordions: this.props.data, selected: 0 };
	}

	componentDidMount() {}

	handleClick = (currentAccordion) => {
		const newAccordions = this.state.accordions;
		let selIndex = currentAccordion.index;
		newAccordions.forEach((accordion) => {
			const currentPanel = currentAccordion.index === accordion.index;
			const prevState = accordion.state;
			let newState = currentPanel ? "active" : "inactive";

			if (currentPanel && prevState === newState) {
				newState = newState === "active" ? "inactive" : "active";
				selIndex = -1;
			}

			accordion.state = newState;
			this.state.selected =
				currentPanel && accordion.state === "inactive" ? -1 : accordion.index;
		});
		this.setState({ accordions: newAccordions, selected: selIndex });
	};

	setPanelHeight(selector, close) {
		const panel = document.querySelector(`${selector}`);
		panel.style.maxHeight = close === true ? null : panel.scrollHeight + "px";
	}

	toggleIsOn() {
		const on = !this.state.isOn;
		this.setState({ isOn: on });
	}

	render() {
		const { accordions } = this.state;
		return (
			<React.Fragment>
				<div>
					<Accordion defaultActiveKey="0" className="confacc">
						{accordions.map((accordion, index) => {
							return (
								<Card className="collapse_iden" key={accordion.index}>
									<Accordion.Toggle
										className={`accordion ${accordion.state} d-flex justify-content-between confacc pointer`}
										style={{ backgroundColor: "#ffffff" }}
										onClick={() => this.handleClick(accordion)}
										as={Card.Header}
										eventKey={accordion.index.toString()}
									>
										<div
											className={`accordion ${accordion.state}`}
											style={{
												color: "#1A171C",
												fontWeight: "bold",
												fontSize: "18px",
											}}
										>
											{accordion.name}
										</div>
										<img
											src={
												this.state.selected !== accordion.index
													? "./imgs/chevron-down-solid.svg"
													: "./imgs/chevron-up-solid.svg"
											}
											alt="nina modelo"
											width="20"
										/>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey={accordion.index.toString()}>
										<Card.Body className={`panel panel-${accordion.index}`}>
											{accordion.content()}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							);
						})}
					</Accordion>
				</div>
			</React.Fragment>
		);
	}
}

export default AccordionConfirmar;
