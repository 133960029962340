import "./confirmarTraspaso.css";
import React, { useState, useEffect, useContext } from "react";
import AccordionConfirmar from "./components/AccordionConfirmar";
import AntecedentesLaborales from "./components/AntecedentesLaborales";
import Cuentas from "./components/Cuentas";
import DatosContacto from "./components/DatosContacto";
import DatosPersonales from "./components/DatosPersonales";
import CabeceraPasos from "../components/CabeceraPasos";
import Disclaimer from "./components/Disclaimer";
import { Link, Redirect } from "react-router-dom";
import { Button } from "react-bootstrap";
import RegistrarPaso from "../../util/RegistrarPaso";
import Context from "../../../store/context";
import configuration from "../../settings/Configurations";
import Swal from "sweetalert2";
import IdleTimerContainer from "../../util/IdleTimer/Container";
import Crypto from "../../util/Crypto";
import ValidarData from "../components/ValidarData";

export default function ConfirmarTraspaso(props) {
	const { state, actions } = useContext(Context);
	const [confirmData] = useState(props.location.state.confirmData);

	const [pasoRegistrado, setPasoRegistrado] = useState(false);


	const [welcomeRedirect, setWelcomeRedirect] = useState(false);
	const [dataTraspaso, setDataTraspaso] = useState(null);
	const [validarExisteEmpleador, setValidarExiteEmpleador] = useState(false);

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	useEffect(async () => {
		const datosPersonales = { ...confirmData.response.datosPersonales };

		OnLoading(true);

		await fetch(`${configuration.API_URL}/api/tipo/${datosPersonales.TipoEmpleado}`, {
			method: "GET",
			headers: configuration.JSON_HEADER,
		})
			.then((response) => response.json())
			.then((response) => {				
				setValidarExiteEmpleador(response.response.ExisteEmpleador);
				OnLoading(false);
			})
			.catch((error) => {
				OnLoading(false);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			});
	}, []);

	const confirmarTraspasoFinal = async () => {
		OnLoading(true);

		let jsonData = { ...confirmData };
		let cuentasAnterior = jsonData.response.cuentasAnterior;
		let cuentasEditadas = jsonData.response.cuentasEditadas;
		let datosPersonales = { ...jsonData.response.datosPersonales };
		let ArrayEmpleadores = datosPersonales.Empleadores;
		let empleadores = [];

		if (validarExisteEmpleador) {
			ArrayEmpleadores.forEach((empleador) => {
				let empleadoresTmp = {
					comuna: empleador.comunaempleador,
					rut: empleador.rutempleador,
					razonSocial: empleador.razonSocialempleador,
					email: empleador.emailNotifiacionempleador,
					telefono: empleador.telefonoempleador,
					direccion: empleador.direccionempleador,
				};
				empleadores.push(empleadoresTmp);
			});
		}

		var dataAuth = Crypto.Decrypt("data");
		let datosPersona = {
			rut: datosPersonales.Rut,
			nombre: datosPersonales.Nombre,
			apellidoPaterno: datosPersonales.apellidoPaterno,
			apellidoMaterno: datosPersonales.apellidoMaterno,
			fechaNacimiento: datosPersonales.FechaNacimiento,
			nacionalidad: datosPersonales.nacionalidad,
			email: datosPersonales.Email,
			telefono: datosPersonales.Telefono,
			direccion: datosPersonales.Direccion,
			comuna: datosPersonales.comuna,
			cartola: true,
			sexo: datosPersonales.Sexo === "M" ? 1 : 2,
			tipoEmpleado: parseInt(datosPersonales.TipoEmpleado),
			prospecto: datosPersonales.cuentaCorrelativoProspecto,
			emps: empleadores,
			region: parseInt(datosPersonales.region),
			autenticacion: dataAuth.autenticacion
		};

		let raw = {
			solicitud: jsonData.response.solicitud,
			ticketId: jsonData.response.ticketId,
			authToken: jsonData.response.token,
			cuentasAnterior: cuentasAnterior,
			cuentasEditadas: cuentasEditadas,
			datosPersona: datosPersona,
		};

		await fetch(`${configuration.API_URL}/api/Notificar`, {
			method: "POST",
			headers: configuration.JSON_HEADER,
			body: JSON.stringify(raw),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.code === "0") {
					OnLoading(false);

					if (!pasoRegistrado) {
						RegistrarPaso(5);
						setPasoRegistrado(true);
					}

					setDataTraspaso(response);
					setWelcomeRedirect(true);
				} else {
					Swal.fire({
						icon: "error",
						text: response.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});

					OnLoading(false);
				}
			})
			.catch((error) => {
				console.error(error);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});

				OnLoading(false);
			});
	};

	const data = [
		{
			index: 0,
			id: 1,
			name: "Cuentas",
			content: () => (
				<Cuentas cuentas={confirmData.response.cuentasEditadas} />
			),
			state: "active",
		},
		{
			index: 1,
			id: 2,
			name: "Datos personales",
			content: () => (
				<DatosPersonales datos={confirmData.response.datosPersonales} />
			),
			state: "inactive",
		},
		{
			index: 2,
			id: 3,
			name: "Datos de contacto",
			content: () => (
				<DatosContacto datos={confirmData.response.datosPersonales} />
			),
			state: "inactive",
		},
		{
			index: 3,
			id: 4,
			name: "Antecedentes Laborales",
			content: () => (
				<AntecedentesLaborales datos={confirmData.response.datosPersonales} />
			),
			state: "inactive",
		},
	];

	if (welcomeRedirect)
		return (
			<Redirect
				to={{ pathname: "/bienvenida", state: { data: dataTraspaso } }}
			/>
		);

	return (
		<>
			<ValidarData />
			<IdleTimerContainer isLoggedIn={true} />;
			<CabeceraPasos paso={3} />
			<div className="containerrr">
				<AccordionConfirmar data={data} />
			</div>
			<div className="containerrr">
				<Disclaimer />
			</div>
			<div className="containerrr" style={{ fontSize: "14px" }}>
				<div className="space"></div>
				<div style={{ textAlign: "center", width: "100%" }}>
					<Button
						id="B-TW-0009"
						className="btn-primary"
						onClick={confirmarTraspasoFinal}
					>
						Confirmar Traspaso
					</Button>
					<Link to={{ pathname: "/misdatos", state: { json: props.location.state.jsonBack } }}>
						<div className="linkkk">Volver</div>
					</Link>
					<div className="space"></div>
				</div>
			</div >
		</>
	);
}