import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import AsesorModal from "./AsesorModal";

export default function PreguntasForm(props) {
    const [text] = useState(props.location.state.data);
    const [claveSeg, setClaveSeg] = useState(false);
    const [asesorModal, setAsesorModal] = useState(false);

    if (claveSeg) return <Redirect to={{ pathname: '/autenticacion', }} />

    if (text !== {}) {
        return (
            <div>
                <div className="container" style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-12">
                            <strong>Preguntas de Seguridad</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="contenedor_img22">
                            <img className="nina_error" src="./imgs/incorrecta.svg" alt="nina modelo" />
                        </div>
                    </div>
                    <div className="media_grey3 align-middle">
                        <div className="media-body">
                            <Card className=" body_grey">
                                <Card.Body>
                                    <Card.Title>{text.title}</Card.Title>
                                    <div className="container mt-4">
                                        <div className="row">
                                            <div className="col-12 align-middle pl-0 pt-2">
                                                <Card.Text>
                                                    {text.cardText}
                                                </Card.Text>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="btn_card2">
                                                {(text.buttonTryAgain) ? <Link to="/"><Button variant="primary" block>Intentar otra vez</Button></Link> : null}
                                                {(text.buttonPassSec) ? <Button variant="primary" block onClick={() => { setClaveSeg(true); }}>Cambio con clave de seguridad</Button> : null}
                                            </div>

                                            {(text.buttonPassSec)
                                                ?
                                                <>
                                                    <div className="col-12 align-middle"><AsesorModal /></div>
                                                </>
                                                : null}

                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}