import React, { useState } from 'react';
import TarjetaCuenta from '../../../../util/fondos/TarjetaCuenta';
import Botones from './components/botones/Botones';
import Title from './components/Title';

export default function RedirectGenerico(props) {
    const [enTramite, setEnTramite] = useState(null);
    const [previred, setPrevired] = useState(null);
    const [title, setTitle] = useState(null);
    const [renderAfter, setRenderAfter] = useState(false);
    const [showO, setShowO] = useState(false);

    if (renderAfter) {
        return (
            <div>
                <div className="container text-center">
                    <Title title={title} />
                    <div className="row">
                        <div className="col-12">
                            <img src="./imgs/nina.png" alt="nina modelo" />
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-12">
                            {enTramite != null ? enTramite.map((c, i) => <TarjetaCuenta key={i} cuenta={c} />) : null}
                            {previred != null ? previred.map((p, i) => <TarjetaCuenta key={i} cuenta={p} />) : null}
                        </div>
                    </div>
                    <Botones showO={showO} />
                </div>
            </div>
        );
    } else {
        return null;
    }
}