import FloatingWhatsApp from "react-floating-whatsapp";
import configuration from "../../../settings/Configurations";
import avatar from "../../../util//img/chat.svg";

export default function Whatsapp() {
	return (
		<FloatingWhatsApp
			phoneNumber={configuration.WSP_NUM}
			accountName="AFP Modelo"
			height={300}
			avatar={avatar}
			statusMessage="En Línea"
			chatMessage="Hola!! ¿necesitas ayuda?"
			darkMode={true}
			allowClickAway={true}
			allowEsc={true}
			placeholder="Escribe tu mensaje"
			notification={false}
			notificationDelay={30000}
			notificationSound={false}
		/>
	);
}
