import '../cotizacion.css';
import { useState } from "react";
import { Button, Card, Accordion } from "react-bootstrap";

export default function SabiasQueFondo() {

    const [toggleState, setToggleState] = useState("off");

    function toggle() {
        setToggleState(toggleState === "off" ? "on" : "off");
    }

    return (
        <div className="sabiasQue">
            <Accordion  style={{marginTop:'20px', width:'100%', }}>
                <Card className="big_color">
                    <Card.Header className="bgbg">
                        <div className="caja_1">
                            <img width={50} src="./imgs/pregunta.svg" alt="" />
                        </div>
                        <div className="caja_2">
                            <div>
                                <div className="texto_sabias"><strong>¿Sabías qué?</strong></div>
                                <div className="texto_sabias">puedes cambiar tus fondos cuando cambias de AFP.</div>
                            </div>
                        </div>                                                                    
                        <Accordion.Toggle as={Button} onClick={toggle} variant="link" eventKey="0" className="btn_acor">
                            {toggleState === "off"  ? <p>Ver Más</p> : <p>Ver menos</p>}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="flexA">
                                <span className="text-img_">
                                    <img src="./imgs/fondo_a.svg" alt=""/>
                                </span>
                                <span className="text-img_">
                                    <img src="./imgs/fondo_b.svg" alt=""/>
                                </span>
                                <span className="text-img_">
                                    <img src="./imgs/fondo_c.svg" alt=""/>
                                </span>
                                <span className="text-img_">
                                    <img src="./imgs/fondo_d.svg" alt=""/>
                                </span>
                                <span className="text-img_">
                                    <img src="./imgs/fondo_e.svg" alt=""/>
                                </span>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>

    );
}