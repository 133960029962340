import Swal from "sweetalert2";
import { Redirect as BrowserRouter } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Configuration from "../../../settings/Configurations";
import Context from "../../../../store/context";
import Crypto from "../../../util/Crypto";
import ValidarData from "../../components/ValidarData";

export default function FormValidate() {
	const { state, actions } = useContext(Context);
	const [redirect, setRedirect] = useState(false);

	useEffect(async () => {
		const query = new URLSearchParams(window.location.search);
		const token = query.get("token");

		if (token === null || token === "") {
			window.location.replace("/");
		}

		await ValidateToken(Crypto.Decrypt("data"), token);
	}, []);

	const ErrorMessage = (text) => {
		Swal.fire({
			icon: "error",
			html: text,
			showDenyButton: false,
			showCancelButton: false,
			confirmButtonText: "Ok",
			denyButtonText: `Don't save`
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.replace("/");
			}
		});
	}

	const ValidateToken = async (data, token) => {
		OnLoading(true);

		await fetch(`${Configuration.API_URL}/api/ecert/status/cu`, {
			method: "POST",
			headers: Configuration.JSON_HEADER,
			body: JSON.stringify({
				Run: data.run,
				Token: data.token,
				Data: {
					Identificador: data.identificador,
					TokenRespuestaCU: token,
				},
			}),
		})
			.then((response) => response.json())
			.then(async (response) => {
				if (response.Exito) {
					data.ObjetoGenerico = response.ObjetoGenerico;

					Crypto.Encrypt("data", data);
					setRedirect(true);
				} else {
					OnLoading(false);
					ErrorMessage(response.Descripcion);
				}
			})
			.catch((error) => {
				OnLoading(false);
				ErrorMessage("Hemos detectado un problema. Por favor, intenta nuevamente más tarde. " + error);
			});
	};

	const OnLoading = async (load) => {
		actions({ type: "setState", payload: { ...state, loading: load } });
	};

	return (
		<div>
			<ValidarData />
			{redirect ? (
				<BrowserRouter to={{ pathname: "/tvi", state: { json: "result" } }} />
			) : null}
		</div>
	);
}
