//import up from "./chevron-up-solid.svg";
//import down from "./chevron-down-solid.svg";
//import cerdito_mobile from "./mobile.svg";
//import key from "./green-key.svg";
//import clave_unica from "./claveunica.svg";
//import cedula from "./cedula_st.png";
//import cerdito_desktop from "./desktop.svg";
//import face from "./face.svg";
//import aunmenta from "./aunmenta.svg";
//import star from "./star.svg";
//import heart from "./heart.svg";
//import eye from "./eye-regular.svg";
//import eye_slash from "./eye-slash-regular.svg";
//import question from "./pregunta.svg";
import girl from "./genericas/4851f34d0c1fe8ff8bc02622f8d89afd.png";

const images = {
    //up,
    //down,
    //cerdito_mobile,
    //key,
    //clave_unica,
    //cedula,
    //cerdito_desktop,
    //face,
    //aunmenta,
    //star,
    //heart,
    //eye,
    //eye_slash,
    //question,
    girl
};

export default images;