import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import PreguntasTooltip2 from '../autenticacionui/PreguntasTooltip2';

export default function ClaveSeguridadModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="container text-center">
            <Button variant="link" type="button" className="link" onClick={handleShow} >¿Cuál es mi clave de seguridad? </Button>
            <h6 style={{ textAlign: 'left' }} className="sub_title">Clave de seguridad <PreguntasTooltip2 /> </h6>
            <Modal show={show} onHide={handleClose} animation={false} className="text-center" backdrop="static">
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12" style={{ paddingBottom: '20px', paddingTop: '20px', lineHeight: '25px', }}>
                                <img src="../imgs/clave.svg" alt="key" style={{ paddingRight: '10px', }} /> <strong>¿Cúal es mi Clave de Seguridad?</strong>
                            </div>

                            <div className="col-12">
                                <p>La Clave de Seguridad es una segunda clave de AFP <strong>diferente</strong> a la que te permite ingresar al sitio web de tu AFP actual.</p>
                                <p>Es especial, te permite hacer:  cambio o distribución de fondos, giros desde cuentas de ahorro voluntario o <strong>cambio de AFP.</strong></p>
                                <p>Si no la tienes <strong>debes solicitarla en tu AFP actual</strong>. Sino, puedes hacer el cambio a AFP Modelo con tu cédula de identidad vigente.</p>
                            </div>
                            <div className="btn_card_a">
                                <Button id="B-MOD-0002" variant="primary" onClick={handleClose} block>Entiendo</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}