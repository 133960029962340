import "./footer.css";
import disclaimer from "../../../util/helper/disclaimer";
import { Markup } from 'interweave';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="vertical_container">
					<div className="txt">
						<Markup content={disclaimer.footer} />
					</div>
					<div className="txt copyright">
						<strong>@2024 AFP Modelo. Todos los Derechos Reservados</strong><br />
						<strong>1.7.0.9</strong>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;