export default function FormCotizacion(props) {

    const mostrar = !props.fondos.A || !props.fondos.B || !props.fondos.C || !props.fondos.D || !props.fondos.E;
    let fondosMostrar = [];

    if (props.fondos.A)
        fondosMostrar.push("A");

    if (props.fondos.B)
        fondosMostrar.push("B");

    if (props.fondos.C)
        fondosMostrar.push("C");

    if (props.fondos.D)
        fondosMostrar.push("D");

    if (props.fondos.E)
        fondosMostrar.push("E");

    if (mostrar) {
        let fondosdis = "";

        for (let i = 0; i < fondosMostrar.length; i++) {
            const sep = i === 0 ? "" : i < fondosMostrar.length - 1 ? "- " : " y ";
            fondosdis += sep + fondosMostrar[i];
        }

        return (
            <div className="container pt-2 pb-2 mb-2" style={{ backgroundColor: "#f5f5f5", fontSize: "12px" }}>
                <div className="row">
                    <div className="col-3 pt-3 pl-3 pr-3">
                        <img src="./imgs/exclamacion.svg" alt="" />
                    </div>
                    <div className="col-9 " style={{ fontSize: "12px" }}>

                        <p className="text-left" style={{ fontSize: "12px" }}>Según la normativa vigente, por tu seguridad no cumples con los requisitos de edad para seleccionar un fondo riesgoso. Elige entre los fondos {fondosdis}.</p>
                    </div>
                </div>
            </div>
        );
    }
    else
        return null;
}
