import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function BotonesSegundaParte(props) {
    const history = useHistory();

    if (props.showO) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">o</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Button variant="outline-primary" onClick={() => history.push("/autenticacion")} block>¿Quieres agregar una cuenta?</Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }

}