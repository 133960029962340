import React from 'react';

function obtenerEstadoUrl(estado) {
    let estadoFinal = "";
    switch (estado) {
        case 1: estadoFinal = "-color"; break;
        case 2: estadoFinal = ""; break;
        case 3: estadoFinal = "-gris"; break;
        default: break;
    }
    return estadoFinal;
}

export default function TarjetaFondos({ nombre, estado }) {
    return (<img src={`./imgs/${nombre}${obtenerEstadoUrl(estado)}.png`} alt={nombre} className="ml-1 float right" />);
}