import React from "react";
import FondosConfirmar from "./FondosConfirmar";

const Cuenta = ({ cuenta }) => {
	const formatDecimal = (val) =>
		val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

	return (
		<div className="green_lines2">
			<div className="cuentas_final">
				<p className="cuenta_fn">{cuenta.nombreTipo}</p>
				<p className="destino_fn">
					Destino:<strong> &nbsp;AFP Modelo</strong>
				</p>
				<p style={{ fontSize: "14px" }}>
					Origen: <strong>{cuenta.origen}</strong>
				</p>
				<div className="caja_confirmacion">
					<p className="saldo_fn">
						Saldo Total: $<strong>{formatDecimal(cuenta.saldo)}</strong>
					</p>
				</div>

				<div className="caja_confirmacion_2">
					<div className="fondo_letra d-flex flex-row-reverse">
						<FondosConfirmar fondos={cuenta.fondos} />
					</div>
				</div>

				{cuenta.porcentajeTraspaso > 0 ? (
					<p style={{ fontSize: "13px" }}>
						Traspaso {cuenta.tipoTraspaso}:{" "}
						<strong>{cuenta.porcentajeTraspaso}</strong>%
					</p>
				) : null}

				{cuenta.suscribeMandato ? (
					<>
						<p className="monto_fn">
							Monto de descuento mensual: ${formatDecimal(cuenta.saldoRegimen)}
							($)
						</p>
					</>
				) : null}
			</div>
		</div>
	);
};

export default Cuenta;
