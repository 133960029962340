import React, { useState, useEffect } from 'react';

export default function FichaConfirmaEmpleador({ e }) {
    const [empleador] = useState(e);

    const formatDecimal = (val) => val.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const formatRut = (rut) => {
        const rutParts = rut.split("-");
        return `${formatDecimal(rutParts[0])}-${rutParts[1]}`;
    };

    return (
        <>
            <div className="" style={{ position: 'relative', float: 'left', paddingLeft: '15px', marginTop: '0px', width: '100%', }}>
                <div className="line__"></div>
                <p className="pp_">Razón Social</p>
                <p className="pr_">{empleador.razonSocialempleador}</p>
                <p className="pp_">Rut</p>
                <p className="pr_">{formatRut(empleador.rutempleador)}</p>
                <p className="pp_">Comuna</p>
                <p className="pr_">{empleador.nombreComuna}</p>
                <p className="pp_">Región</p>
                <p className="pr_">{empleador.nombreRegion}</p>
                <p className="pp_">Dirección</p>
                <p className="pr_">{empleador.direccionempleador}</p>
                <p className="pp_">Email</p>
                <p className="pr_">{empleador.emailNotifiacionempleador}</p>
                <p className="pp_">Teléfono</p>
                <p className="pr_">{empleador.telefonoempleador}</p>
            </div>
        </>
    );
}
