
class ValidateFieldsUtil {
    static formarteaRut(value) {
        let inval = value.trim();
        let rutparts = inval.replace(/[^0-9\+kK]/gi, '').split('-');
        let r = rutparts[0].trim().toLowerCase().replace(/\k/g, '');
        let dv = '';
        let h = '';

        if (rutparts.length > 1) {
            h = '-';
            dv = rutparts[1];
            if (dv.length > 0) dv = dv[0];
        } else {
            if (inval.length > 2) {
                let uc = inval[inval.length - 1];
                if (uc === 'k') r += uc;
            }
        }
        r = r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `${r}${h}${dv}`;
    }

    static mascaraRut(val) {
        let inval = val.trim();
        let rutparts = inval.replace(/[^0-9\kK]/gi, '').split('-');
        let r = rutparts[0].trim().toLowerCase().replace(/\k/g, '');
        let dv = '';
        let h = '';

        if (rutparts.length > 1) {
            h = '-';

            if (r.length > 8) r = r.substring(0, 8);

            dv = rutparts[1];
            if (dv.length > 0) dv = dv[0];
        } else {
            if (inval.length > 2) {
                if (inval.length > 8) {
                    inval = inval.substring(0, 8);
                }

                let uc = inval[inval.length - 1];
                if (uc === 'k') r += uc;
            }
        }

        return `${r}${h}${dv}`;
    }

    static rutFocus(val) {
        let rutparts = val.split('-');
        let rut = rutparts[0].replace(/\./g, '');
        let h = '';
        let dv = '';

        if (rutparts.length > 1) {
            h = '-';
            dv = rutparts[1];
        } else {
            if (rut.length > 2) {
                h = '-';
                dv = rut[rut.length - 1];
                rut = rut.substring(0, rut.length - 1);
            }
        }

        if (rut.length > 8) rut = rut.substring(0, 8);

        rut = rut.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return rut + h + dv;
    }

    static onlyNumbers = (val) => {
        return val.trim().replace(/[^0-9]/gi, '');
    };

    static enmascararEmail(val) {
        let mailparts = val.trim().replace(/[^a-zA-Z0-9_/-@.]/g, '').split('@');

        let user = mailparts[0].replace('@', '');
        let domain = mailparts.length > 1 ? mailparts[1].replace('@', '') : '';
        let arr = mailparts.length > 1 ? '@' : '';

        return `${user}${arr}${domain}`;
    }

    static validarRut(r) {
        const caracteresRut = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'k'];
        r = r.buscarCampo("rut").valor.trim().toLowerCase().replace(/\./g, '');
        // rut vacío
        //no tiene guion
        if (r.length > 1 && !r.includes("-")) {
            let ini = r.substring(0, r.length - 1);
            let fin = r[r.length - 1];
            r = ini + "-" + fin;
        }
        let rutparts = r.split('-');

        if (rutparts.length !== 2) return false;
        else {
            // validación de formato
            let ru = rutparts[0];
            let dv = rutparts[1];
            let sum = 0;

            if (isNaN(ru)) return false;
            else ru = Number.parseInt(ru);

            if (!(caracteresRut).includes(dv)) return false;

            let i = 2;

            while (ru !== 0) {
                if (i > 7) i = 2;

                let rutdv = parseInt(ru / 10);
                let di = ru - rutdv * 10;
                sum += di * i;
                i++;
                ru = rutdv;
            }

            sum %= 11;
            let d = (sum === 1) ? 'k' : sum === 0 ? '0' : (11 - sum).toString();

            return dv === d;
        }
    }

    static validarFormato(r) {
        const valor = ValidateFieldsUtil.Upper(r.buscarCampo("numdoc").valor.trim()).replace(/\./g, "");
        const limit = valor[0] === "A" ? 10 : 9;
        return valor.length === limit;
    }

    static cedulafocus(val) {
        let inval = ValidateFieldsUtil.Upper(val).replace(/\./g, "").trim();
        const ll = inval.length;

        if (ll > 0) {
            const a = inval[0];
            const indx = a === "A" ? 1 : 0;
            let prc = a === "A" ? a : "";
            let lstc = inval.substring(indx, inval.length);
            if (ll > 1) {
                const l = lstc.length;
                const limit = l > 9 ? 9 : l;
                lstc = lstc.replace(/[^0-9]/gi, "").substring(0, limit).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                if (lstc.length > 0 && prc.length > 0) prc = `${prc}.`;
            }

            return prc + lstc;
        }
        return "";
    }

    static cedulaNumDoc(v) {
        v = ValidateFieldsUtil.Upper(v).replace(/\./g, "").trim();
        const ll = v.length;
        if (ll > 0) {
            const a = v[0];
            const indx = a === "A" ? 1 : 0;
            let prc = a === "A" ? a : "";
            let lstc = v.substring(indx, v.length);
            if (ll > 1) {
                const l = lstc.length;
                const limit = l > 9 ? 9 : l;
                lstc = lstc.replace(/[^0-9]/gi, '').substring(0, limit).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                if (lstc.length > 0 && prc.length > 0) prc = `${prc}.`;
            }

            return prc + lstc;
        }
        return "";
    }

    static eliminaPuntosYGuion(rut) {
        return rut.replace(/\.|-/g, "");
    }

    static nombreCorto(r) {
        let nombre = r.buscarCampo("nombres").valor;
        nombre = (nombre) ? nombre.trim() : "";
        return nombre.length > 2;
    }

    static nombreValido(r) {
        let nombre = r.buscarCampo("nombres").valor;
        nombre = (nombre) ? nombre.trim() : "";
        return nombre.match(/^[-'A-Za-zÀ-ÖØ-öø-ÿ\'\s\.\,]+$/);
    }

    static celularCorto(r) {
        let nombre = r.buscarCampo("celular").valor;
        nombre = (nombre) ? nombre.trim() : "";
        return nombre.length > 7;
    }

    static mascaraNombre(val) {
        let x = val.replace(/[0-9]+$/g, "");
        let y = x.replace(/[.*+\?^${}()|[\]\\!"·%?&¿ºª/=¡`´¨¨~,;:_<>°#]/g, "");
        return y;
    }

    static Upper = (cadena) => {
        if (cadena !== null && cadena !== undefined && cadena !== "") {
            return cadena.toUpperCase();
        }
        return cadena;
    }
}

export default ValidateFieldsUtil;