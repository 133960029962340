import { Container } from "react-bootstrap";

export default function CabeceraPasos(props) {
	const imgs = ["./imgs/pasos.svg", "./imgs/pasos2.svg", "./imgs/pasos3.svg"];
	const pasos = ["Paso 1", "Paso 2", "Paso 3"];
	const descPaso = [
		"Selecciona tus cuentas",
		"Datos Personales",
		"Confirmación",
	];

	const paso = parseInt(props.paso) - 1;

	return (
		<>
			<div className="container_fondos">
				<div>
					<h5 className="text-grayy">Cambiándote a AFP Modelo</h5>
					<p style={{ color: "#5B5B5B" }}>
						Sigue el paso a paso para ser parte de Modelo.
					</p>
				</div>
				<img src="./imgs/nina.png" className="nina_fondos" alt=""></img>
			</div>

			<div className="pasos">
				<img src={imgs[paso]} className="" alt={`paso${paso + 1}`} />
			</div>

			<div
				style={{
					background: "#C6E8F2",
					color: "#5B5B5B",
					padding: "20px",
					fontSize: "20px",
					textAlign: "center",
				}}
			>
				<Container>
					<strong>{pasos[paso]}</strong> {descPaso[paso]}
				</Container>
			</div>
		</>
	);
}
