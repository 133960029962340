import { Button, Form } from "react-bootstrap";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./css/style.css"
import Swal from "sweetalert2";
import Context from "../../../../store/context";
import Tooltip from "../autenticacionui/PreguntasTooltip";
import Crypto from "../../../util/Crypto";
import Configuration from "../../../settings/Configurations";
import images from "../../../util/img/images";
import { isMobile, isBrowser } from 'react-device-detect';

export default function FormClaveUnicaFAO() {
    const { state, actions } = useContext(Context);
    const [number, setNumber] = useState("");

    const onChange = (event) => {
        event.preventDefault();
        const re1try = /^\b[aA]$/;
        const re2try = /^\b[aA][0-9]{1,9}$/;
        const reOnlyNumbers = /^\b[0-9]{1,9}$/;
        if (re1try.test(event.target.value) || event.target.value === "") {
            setNumber(event.target.value);
        } else if (
            event.target.value.length > 1 &&
            re2try.test(event.target.value)
        ) {
            setNumber(event.target.value);
        } else if (reOnlyNumbers.test(event.target.value)) {
            setNumber(event.target.value);
        }
    };

    const AutenticateCI = async () => {
        await OnLoading(true);

        var data = Crypto.Decrypt("data");
        data.autenticacion = "CU";
        data.device = isMobile ? "Mobile" : isBrowser ? "Browser" : "Unknown";
        Crypto.Encrypt("data", data);

        await fetch(`${Configuration.API_URL}/api/ecert/Authenticate/CI/${data.run}`, {
            method: "POST",
            headers: Configuration.JSON_HEADER,
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.Exito) {
                    await ValidateCI(data, result.ObjetoGenerico);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: result.Descripcion,
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                    });
                    OnLoading(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
                OnLoading(false);
            });
    };

    const ValidateCI = async (data, token) => {

        await fetch(`${Configuration.API_URL}/api/ecert/validate/ci`, {
            method: "POST",
            body: JSON.stringify({
                Datos: {
                    Run: data.run,
                    NumeroSerie: number,
                },
                Token: token,
            }),
            headers: Configuration.JSON_HEADER,
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.Exito) {
                    await AuthenticateCU(data);
                } else {
                    var dataCliente = result.ObjetoGenerico.DatosCliente;

                    var valida = dataCliente.EstadoRespuesta !== "Consulta exitosa";
                    var bloqueada = dataCliente.IndicadorBloqueo !== "NOBLOQUEADO";
                    var vencida = dataCliente.Vigencia !== "S";

                    if (valida) {
                        Swal.fire({
                            title: "Número de serie incorrecto",
                            icon: "error",
                            text: "El número de serie que ingresaste no corresponde al de tu cédula de identidad. Inténtalo nuevamente.",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                        });
                    } else if (bloqueada) {
                        Swal.fire({
                            //title: "Cédula bloqueada",
                            icon: "error",
                            text: "Tu cédula está bloqueada.",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.fire({
                                    //title: "Clave de seguridad",
                                    icon: "info",
                                    html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
                                    confirmButtonText: "Ok",
                                    allowOutsideClick: false,
                                });
                            }
                        });
                    } else if (vencida) {
                        Swal.fire({
                            //title: "Cédula vencida",
                            icon: "error",
                            text: "Tu cédula está vencida.",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.fire({
                                    //title: "Clave de seguridad",
                                    icon: "info",
                                    html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
                                    confirmButtonText: "Ok",
                                    allowOutsideClick: false,
                                });
                            }
                        });
                    }

                    OnLoading(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
                OnLoading(false);
            });
    };

    const AuthenticateCU = async (data) => {
        await fetch(`${Configuration.API_URL}/api/ecert/authenticate/${data.run}`, {
            method: "POST",
            headers: Configuration.JSON_HEADER,
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.Exito) {
                    data.token = result.ObjetoGenerico.TokenUser.TokenUser;
                    Crypto.Encrypt("data", data);

                    await SolicitudCU(data);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: result.Descripcion,
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                    });
                    OnLoading(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
                OnLoading(false);
            });
    };

    const SolicitudCU = async (data) => {
        await fetch(`${Configuration.API_URL}/api/ecert/validate/cu`, {
            method: "POST",
            headers: Configuration.JSON_HEADER,
            body: JSON.stringify({
                Run: data.run,
                UrlCallback: `${window.location.origin}/validate`,
                Nombres: data.name,
                ApellidoPaterno: data.fatherName,
                ApellidoMaterno: data.motherName,
                Correo: data.email,
                Token: data.token,
                NumeroSerie: number
            }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.Exito) {
                    OnLoading(false);

                    var resultado = result.ObjetoGenerico.Resultado;

                    data.identificador = resultado.Identificador;
                    data.tokentvi = resultado.IdentificadorPrevired;

                    Crypto.Encrypt("data", data);

                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "ClaveÚnica",
                        html: '<ul>Se han envado los datos de manera exitosa.</ul><ul>Se redireccionará automáticamente al sitio. <b class="d-none"></b></ul>',
                        timer: 5000,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((timerResult) => {
                        if (timerResult.dismiss === Swal.DismissReason.timer) {
                            window.location.replace(resultado.UrlClaveUnica);
                        }
                    });
                } else {
                    const steps = [];
                    var arr = result.ObjetoGenerico.ErroresValidacion;
                    for (var i = 1; i <= arr.length; i++) {
                        steps.push(i.toString());
                    }

                    const swalQueueStep = Swal.mixin({
                        confirmButtonText: "Siguiente",
                        cancelButtonText: "Anterior",
                        progressSteps: steps,
                        reverseButtons: true,
                    });

                    const values = [];
                    let currentStep;

                    OnLoading(false);

                    for (currentStep = 0; currentStep < steps.length;) {
                        var text = `${arr[currentStep]}`;

                        const resultq = await swalQueueStep.fire({
                            title: result.Descripcion,
                            showCancelButton: currentStep > 0,
                            html: text,
                            currentProgressStep: currentStep,
                        });

                        if (resultq.value) {
                            values[currentStep] = resultq.value;
                            currentStep++;
                        } else if (resultq.dismiss === Swal.DismissReason.cancel) {
                            currentStep--;
                        } else {
                            break;
                        }
                    }
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: "Hemos detectado un problema. Por favor, intenta nuevamente más tarde.",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
                OnLoading(false);
            });
    };

    const OnLoading = async (load) => {
        actions({ type: "setState", payload: { ...state, loading: load } });
    };

    return (
        <div>
            <div className="main">
                <Form>
                    <div className="contenedor">
                        <div className="izquierda">
                            <div class="arriba">
                                <h1>¡Ya te estás cambiando</h1>
                                <h1> a AFP Modelo!</h1>
                            </div>
                            <div class="abajo">
                                <p>Sigue el paso a paso para ser parte de Modelo.</p>
                            </div>
                        </div>
                        <div class="derecha">
                            <img src={images.girl} style={{ height: 160 }} alt="girl" />
                        </div>
                    </div>
                    <div className="form-container ">
                        <Form.Group>
                            <div className="text-center">
                                <h6 className="sub_title">
                                    <p><span class="resaltar">* </span>Ingresa el N° de serie de tu Cédula de Identidad <Tooltip /></p>
                                </h6>
                                <Form.Control
                                    value={number}
                                    type="text"
                                    className=""
                                    placeholder="Ingresa el N° de serie de tu Cédula de Identidad"
                                    name="numdoc"
                                    onChange={onChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="align-items-center">
                            <div className="btn_card_a">
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={AutenticateCI}
                                    disabled={number == ""}
                                >
                                    Siguiente
                                </Button>
                                <Link to="/autenticacion">
                                    <div className="link">Volver</div>
                                </Link>
                            </div>
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </div>
    );
}