import "./cuestionario.css";
import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import PreguntasForm from "./PreguntasForm";
import Timer from "./Timer";
import { Redirect } from "react-router-dom";
import RegistrarIntentoLogin from "../../util/RegistrarIntentoLogin";
import Context from "../../../store/context";
import configuration from "../../settings/Configurations";
import Swal from "sweetalert2";
import Crypto from "../../util/Crypto";

const Cuestionario = (props) => {
	const { state, actions } = useContext(Context);
	const [arrayAsk, setarrayAsk] = useState(props.location.state.data);
	const [next, setnext] = useState(1);
	const [form, setform] = useState({});
	const [redirectFondos, setredirectFondos] = useState(false);
	const [redirectUno, setredirectUno] = useState(false);
	const [redirectBloqueo, setredirectBloqueo] = useState(false);
	const [disabled, setdisabled] = useState(true);
	const [dataJson, setdataJson] = useState({});
	const [sinCuentas, setsinCuentas] = useState(false);
	const [errors, seterrors] = useState(null);
	const [timerObj, settimerObj] = useState(null);
	const [paused, setpaused] = useState(false);

	useEffect(async () => {
		var data = Crypto.Decrypt("data");

		actions({ type: "setState", payload: { ...state, loading: true } });

		if (timerObj === null) {
			await fetch(`${configuration.API_URL}/api/Timer/${data.solicitud}`, {
				method: "GET",
				headers: configuration.JSON_HEADER,
			})
				.then((response) => response.json())
				.then((response) => {
					settimerObj(response);
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: error.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});

					this.setErrorCallBack({
						error: "Error al cargar el timer",
						detalle: error.message,
					});
				});
		}

		if (data.solicitud !== null) {
			await fetch(`${configuration.API_URL}/api/Traspaso/${data.solicitud}`, {
				method: "GET",
				headers: configuration.JSON_HEADER,
			})
				.then((response) => response.json())
				.then((response) => {
					var data = Crypto.Decrypt("data");

					if (response !== null) {
						if (response.Code === 0) {
							data.run = response.Response.Rut;

							Crypto.Encrypt("data", data);
						} else if (response.Code === 2) {
							setredirectBloqueo(true);
						} else {
							data.solicitud = "";
							Crypto.Encrypt("data", data);
						}
					} else {
						data.solicitud = "";
						Crypto.Encrypt("data", data);
					}
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: error.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				});
		}
		actions({ type: "setState", payload: { ...state, loading: false } });
	}, []);

	const setErrorCallBack = (objetoError) => {
		seterrors({ error: objetoError.error, detalle: objetoError.detalle });
	};

	const handlerNextButton = async (event) => {
		event.preventDefault();

		if (next === 5) {
			actions({ type: "setState", payload: { ...state, loading: true } });
			setpaused(true);

			let respTemp = [];
			arrayAsk.response.detalle.desafio.forEach((element, i) => {
				let resp;

				if (i === 0) {
					resp = form.radio_0;
				}
				if (i === 1) {
					resp = form.radio_1;
				}
				if (i === 2) {
					resp = form.radio_2;
				}
				if (i === 3) {
					resp = form.radio_3;
				}
				if (i === 4) {
					resp = form.radio_4;
				}

				respTemp.push({
					codigO_PREGUNTA: element.codigO_PREGUNTA.trim(),
					codigO_RESPUESTA: resp,
				});
			});

			var data = Crypto.Decrypt("data");
			let rut = data.run.replace("-", "");

			let respuesta = {
				consulta: {
					usuario: rut,
					rutCliente: rut,
					idChallenge: arrayAsk.response.idChallenge,
					desafio: { respuestas: respTemp },
				},
			};
			let raw = {
				Id: timerObj.Response.Id,
				Token: data.solicitud,
				Operacion: 1,
			};
			let response = await fetch(`${configuration.API_URL}/api/Timer`, {
				method: "PUT",
				headers: configuration.JSON_HEADER,
				body: JSON.stringify(raw),
			}).catch((error) => {
				console.error(error);
			});

			response = await response.json();

			let preguntasUrl = await fetch(
				`${configuration.API_URL}/api/Previsional/preguntas/`,
				{
					method: "POST",
					headers: configuration.JSON_HEADER,
					body: JSON.stringify(respuesta),
				}
			).catch((error) => {
				console.error(error);
				Swal.fire({
					icon: "error",
					text: error.message,
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});

				actions({ type: "setState", payload: { ...state, loading: false } });
				seterrors({ error: "No se pudo cargar ", detalle: error.message });
			});

			RegistrarIntentoLogin(
				2,
				preguntasUrl.data.codigo === 0,
				setErrorCallBack
			);

			if (preguntasUrl.codigo === 0) {
				let raw = {
					token: preguntasUrl.response.token,
					codigo: "1034",
					rut: rut.substring(0, rut.length - 1),
					rutDv: rut.substring(rut.length - 1),
					nombres: "",
					apellidoPaterno: "",
					apellidoMaterno: "",
					cuentas: [
						{ codigo: "CCICO" },
						{ codigo: "CCIAV" },
						{ codigo: "CAI" },
						{ codigo: "CAV" },
						{ codigo: "CCICV" },
						{ codigo: "CCIDC" },
						{ codigo: "APVC" },
						{ codigo: "APV" },
						{ codigo: "CTA2" },
					],
					otros: "",
					autenticacion: data.autenticacion
				};
				let cuentasprevired = await fetch(
					`${configuration.API_URL}/api/Previred`,
					{
						method: "POST",
						headers: configuration.JSON_HEADER,
						body: JSON.stringify(raw),
					}
				).catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: error.message,
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});

					seterrors({
						error: "Error al obtener atributos cualitativos",
						detalle: error.message,
					});
				});

				actions({ type: "setState", payload: { ...state, loading: false } });

				if (cuentasprevired.code === "0") {
					if (cuentasprevired.response.cuentas.length === 0) {
						setsinCuentas(true);
					} else {
						cuentasprevired.response.token = raw.token;
						setdataJson(cuentasprevired);
						setredirectFondos(true);
					}
				} else if (cuentasprevired.code === "1") {
					setsinCuentas(true);
				} else {
					seterrors({
						error: "No se pudieron cargar las cuentas",
						detalle: "Lo sentimos No pudimos cargar tus cuentas",
					});
				}
			} else {
				if (preguntasUrl.responseIntento.intentos === 1) {
					setredirectUno(true);
				} else {
					setredirectBloqueo(true);
				}
			}

			actions({ type: "setState", payload: { ...state, loading: false } });
		} else {
			setnext(next + 1);
			setdisabled(true);
		}
	};

	const changeForm = (event) => {
		const arrayForm = Object.assign({}, form);
		arrayForm[[event.target.name]] = event.target.value;
		setform(arrayForm);
		setdisabled(false);
	};

	const updateIntentos = (intentos) => { };

	let bloqueoCurso = false;
	let errorLocal = errors;

	if (timerObj === null || typeof timerObj === "Promise") {
		return null;
	}

	if (timerObj.Response === null) {
		errorLocal = {
			error: { error: "Error al cargar el timer", detalle: timerObj.Message },
		};
		this.setState(errorLocal);
	}

	bloqueoCurso = timerObj.Response.Code === 2;

	if (errorLocal !== null) {
		const renderError = (
			<Redirect to={{ pathname: "/error", state: { data: errorLocal } }} />
		);
		return renderError;
	} else {
		if (bloqueoCurso) {
			let data = {
				title: "¡Ups!, Este método de atenticación no está disponible",
				cardText:
					"Por tu de seguridad este método se encuentra bloqueado por 72 horas, intenta utilizar la clave segura o inténtalo de nuevo después del bloqueo.",
				buttonTryAgain: true,
				buttonPassSec: false,
			};

			return (
				<Redirect
					to={{ pathname: "/seguridadCuestionario", state: { data: data } }}
				/>
			);
		}

		let intento = 2 - (timerObj.Response.Intento - 1);

		if (sinCuentas) {
			return <Redirect to={{ pathname: "/nohaycuentas" }} />;
		}

		if (redirectFondos) {
			return (
				<Redirect
					to={{ pathname: "/cuentasyfondo", state: { json: dataJson } }}
				/>
			);
		}

		if (redirectBloqueo) {
			let data = {
				title: "¡Ups!, Respuesta incorrecta",
				cardText:
					"Hay dos intentos fallidos. Por tu seguridad, este método de validación se bloqueó por 72 horas. Podrás intentar nuevamente después del plazo señalado o utilizar la opción de clave de seguridad.",
				buttonTryAgain: false,
				buttonPassSec: true,
			};

			return (
				<Redirect
					to={{ pathname: "/seguridadCuestionario", state: { data: data } }}
				/>
			);
		}

		if (redirectUno) {
			let data = {
				title: "¡Ups!, Respuesta incorrecta",
				cardText:
					"Queda un intento de validación por preguntas de seguridad. Si nuevamente no es aprobado, por seguridad, este método de validación se bloqueará por 72 horas.",
				buttonTryAgain: true,
				buttonPassSec: false,
			};

			return (
				<Redirect
					to={{ pathname: "/seguridadCuestionario", state: { data: data } }}
				/>
			);
		}

		return (
			<div className="container_pregunta">
				<div className="container_sub_">
					<h5 className="black">
						<strong>¡Ya te estás cambiando a AFP Modelo!</strong>
					</h5>
					<h5 className="mt-3 text-gray">Preguntas de seguridad</h5>
					<p>
						Responde correctamente para continuar.{" "}
						<strong>Tienes solo {intento} intentos.</strong> Si fallas ambas
						veces, puedes <strong>reintentar en 72 horas.</strong>
					</p>
				</div>
				<img src="./imgs/nina.png" className="identificacion_" alt="nina" />
				<div className="media_grey2">
					<div className="media-body ">
						<div className="body_grey">
							<div className="row">
								<div className="timer ">
									<Timer
										updateIntentos={updateIntentos}
										timerId={-1}
										countdown={timerObj}
										paused={paused}
									/>
								</div>
							</div>
							<Form onSubmit={handlerNextButton} onChange={changeForm}>
								<PreguntasForm nextAsk={next} arrayAsk={arrayAsk.response} />
								<div className="row justify-content-center pt-2">
									<button
										disabled={disabled}
										type="submit"
										style={{ width: "290px" }}
										className="btn btn-primary"
									>
										Siguiente
									</button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		);
	}
};
export default Cuestionario;
