var information = {
    one: {
        title: "La mejor rentabilidad",
        detail: "En fondos A, B, C y D en los últimos 12 meses."
    },
    two: {
        title: "La mejor experiencia de servicio",
        detail: "Según Ranking PXI 2024, sector AFP."
    },
    three: {
        title: "La AFP con más afiliados",
        detail: "Más personas confían en nosotros."
    },    
    footer: "Infórmate sobre la calidad de servicio de las AFP en la herramienta <strong><a href='https://www.spensiones.cl/infoydec'>Infórmate y Decídete</a></strong>, disponible en la página web de la Superintendencia de Pensiones. Entrega tu opinión a la Superintendencia de Pensiones sobre la calidad del servicio de tu AFP, <strong><a href='https://es.research.net/r/spensiones?utm_source=afpmodelo&utm_medium=organic&utm_campaign'>comienza aquí.</a></strong><br><br>\"La rentabilidad es variable, por lo que nada garantiza que las rentabilidades pasadas se repitan en el futuro. Infórmese sobre la rentabilidad de su Fondo de Pensiones, las comisiones y la calidad de servicio de las AFP en el sitio web de la Superintendencia de Pensiones: <strong><a href='https://www.spensiones.cl/'>www.spensiones.cl</a></strong>\". Fuente: Inversiones y Rentabilidad de los Fondos de Pensiones correspondiente a julio 2024. Periodo correspondiente a agosto 2023 — julio 2024. Rentabilidad de la cuota expresada en forma real: Fondo A: 11,53% (primer lugar), Fondo B: 9,70% (primer lugar), Fondo C: 5,43% (primer lugar), Fondo D:  1,30% (primer lugar), Fondo E: 0,09% (cuarto lugar). Estructura de comisiones por depósitos de cotizaciones vigentes a agosto de 2024 - Capital: 1,44%, Cuprum: 1,44%, Habitat: 1,27%, Modelo: 0,58%, Planvital: 1,16%, Provida: 1,45%, Uno: 0,49% - Para afiliados dependientes, independientes y voluntarios. Número de afiliados por AFP vigente según Informe Estadístico Mensual de Afiliados y Cotizantes de la Superintendencia de Pensiones (2.795.441 al 31 de mayo de 2024). Primer lugar en experiencia de servicio según ranking PXI – Praxis Xperience Index 2024 para el Sector AFP."
}

export default information;



